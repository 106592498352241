import React from "react";
import { useHistory } from "react-router-dom";

function Intro(props) {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const recruitRegiGo = () => {
    if (!token) {
      alert("로그인이 필요합니다");
      history.push({
        pathname: "/login",
      });
    } else {
      history.push({
        pathname: "/recruit/register",
      });
    }
  };
  return (
    <div className="contents_wrap intro_contents">
      <div className="company cp1">
        <div className="main_title">
          <div className="main_t">Why Not Link</div>
          <div className="main_deco"></div>
        </div>
        <div className="back_img"></div>
      </div>
      <div className="company cp2">
        <div className="main_title">
          인쇄, 톰슨, 재단, 코팅, 포장 수작업 등<br />
          인쇄물 제작을 위한 모든 공정을 한번에 <br />
          와이낫링크로 해결할 수 있습니다.
        </div>
        <div className="main_img">
          <img src="/img/company2.jpg" alt="company2.jpg" />
        </div>
      </div>
      <div className="company cp3">
        <div className="main_title">
          공정별 인쇄업체의 참여를 통해 단가는 낮추고,
          <br />
          알아서 해주는 대행사의 역할은 유지하여 전문성은 높이고,
          <br />
          와이낫링크는 모든 프로젝트를 직접 대행하여 믿을 수 있습니다.
        </div>
        <div className="mission_items">
          <div className="mission_item">
            <div className="ms_num">Mission1</div>
            <div className="ms_title">효율적인 서비스</div>
            <div className="ms_desc">
              인쇄 제작물이 필요한 사업자 및 기업 담당자는 공정별 업체를 찾는
              번거로움 없이, 전문지식이 없어도 만족스러운 결과물을 얻을 수
              있습니다. 평균 경력 10년 이상의 와이낫링크 매니저가 전담합니다.
            </div>
          </div>
          <div className="mission_item">
            <div className="ms_num">Mission2</div>
            <div className="ms_title">신뢰할 수 있는 서비스</div>
            <div className="ms_desc">
              와이낫링크에서 직접 사업장을 방문하여 보유기계 등 전문성을
              확인하여 등록된 1천여 인쇄업체가 공정별 수행업체로 함께 합니다.
              프로젝트 수행에 적합한 업체만을 구성하여 업무를 진행하기에 믿을 수
              있습니다.
            </div>
          </div>
          <div className="mission_item">
            <div className="ms_num">Mission3</div>
            <div className="ms_title">쉽고 편한 서비스</div>
            <div className="ms_desc">
              필수정보만 입력하면 프로젝트 의뢰가 완료됩니다. 카카오톡, 전화,
              간편문의를 통해서도 문의가 가능하며 무료로 견적을 받아볼 수
              있습니다. 프로젝트 진행 중 추가 협의 및 모든 이슈사항은 와이낫링크
              담당 매니저를 통해 해결할 수 있습니다.
            </div>
          </div>
        </div>
      </div>
      <div className="company cp4">
        <div className="main_title">
          와이낫링크는 업체 중개가 아닌, <br />
          공정별 업체를 구성하여 직접 프로젝트를 진행합니다.
        </div>
        <div className="service_process like_mission">
          <div className="sv_item">
            <div className="sv_num">01</div>
            <div className="sv_title">프로젝트 의뢰</div>
            <div className="sv_desc">
              인쇄부터 톰슨, 재단, 코딩 등 모든 후가공에 이르기까지 필요한
              업무범위+예산+일정 입력으로 다양한 프로젝트를 손쉽게 의뢰할 수
              있습니다. 작성이 어려울 경우 원하는 결과물을 작성만하면 적정 예산
              및 일정, 기타 이슈 사항 등은 와이낫링크 매니저가 직접 검토하여
              수정의견을 드립니다.
            </div>
          </div>
          <div className="sv_item">
            <div className="sv_num">02</div>
            <div className="sv_title">프로젝트 수행사 선정</div>
            <div className="sv_desc">
              와이낫링크에서 필요 공정 분할, 공정별 일정을 세팅하여 수행
              전문가를 모집합니다. 와이낫링크는 등록된 DB를 활용하여 공정별 수행
              업체들을 구성하여 제안합니다. 종합 인쇄업체 및 대행사 등 등록된
              전문가 분들 또한 통합 수행자로서 지원할 수 있습니다. 와이낫링크 및
              기타 지원자의 제안서를 비교하여 프로젝트 수행사를 선정해주세요.
            </div>
          </div>
          <div className="sv_item">
            <div className="sv_num">03</div>
            <div className="sv_title">프로젝트 진행</div>
            <div className="sv_desc">
              와이낫링크의 제안, 또는 기타 지원자의 프로젝트를 선정하더라도
              와이낫링크 매니저가 추가 비용 없이 직접 대행하여 프로젝트를
              진행합니다. 대행구조로 제작 되기에 저희 와이낫링크와
              커뮤니케이션을 진행, 와이낫링크에서 인쇄사고 및 제작 품질에 대한
              보증을 합니다.
            </div>
          </div>
        </div>
      </div>

      <div className="project_add_section">
        <div className="main_title">
          모든 홍보물, 판촉물, 인쇄제작!
          <br />
          지금 바로 와이낫링크를 통해 해결하세요.
        </div>
        <div className="pr_add_go_bt" onClick={recruitRegiGo}>
          프로젝트 등록하러가기
        </div>
        <div className="pr_add_go_desc">
          와이낫링크는 프로젝트 진행을 위한 용도 외 어떠한 다른 목적으로
          등록하신 내용을 활용하지 않습니다.
        </div>
      </div>
    </div>
  );
}
export default Intro;
