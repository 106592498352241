import React, { useState } from "react"; //, { useState, useEffect }
//import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux"; //connect,, useSelector
import { Link } from "react-router-dom"; //useHistory,
import * as baseActions from "../../store/base";
import axios from "axios";
import { useForm } from "react-hook-form";

function Login(props) {
  const proxy = require("../../../package.json").proxy;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const pushid = localStorage.getItem("pushid");
  const deviceid = localStorage.getItem("deviceid");
  const os_version = localStorage.getItem("os_version");
  const [maintainLogin, setMaintainLogin] = useState(false);
  // const history = useHistory();
  // const [loginInfo, setLoginInfo] = useState({
  //   email: null,
  //   password: null,
  // });

  const maintainLoginBt = (event) => {
    setMaintainLogin(event.target.checked);
  };

  const loginSubmit = async (data) => {
    const response = await axios.post("/rest-api/auth/local", {
      email: data.email,
      password: data.password,
      maintainLogin,
    });

    if (response.data.error) {
      alert(response.data.error);
    } else if (!response.data.error) {
      dispatch(baseActions.login(response.data.token));
      // console.log("data: ", response.data);
      // console.log("pushid: ", pushid);
      // console.log("deviceId: ", deviceid);
      // console.log("os_version: ", os_version);
      if (pushid) {
        const user = response.data.user;
        const res = await axios.post(
          "/rest-api/updateAppInfo",
          {
            pushid,
            user_name: user.name,
            user_email: user.email,
            user_id: user.id,
            deviceid: deviceid,
            os_version: os_version,
            token: response.data.token,
          },
          {
            headers: {
              Authorization: "Bearer " + response.data.token,
            },
          }
        );
        if (res.data.code === 200) {
          console.log("app 로그인 정보 갱신 완료");
        } else {
          console.log(res.data.error);
        }
      }
      window.location.href = "/";
    }
    // axios
    //   .post("/rest-api/auth/local", {
    //     email: data.email,
    //     password: data.password,
    //   })
    //   .then(function (response) {
    //     if (response.data.error) {
    //       alert(response.data.error);
    //     } else if (!response.data.error) {
    //       dispatch(baseActions.login(response.data.token));
    //       // console.log("data: ", response.data);
    //       // console.log("pushid: ", pushid);
    //       // console.log("deviceId: ", deviceid);
    //       // console.log("os_version: ", os_version);
    //       if (pushid) {
    //         const user = response.data.user;
    //         axios
    //           .post(
    //             "/rest-api/updateAppInfo",
    //             {
    //               pushid,
    //               user_name: user.name,
    //               user_email: user.email,
    //               user_id: user.id,
    //               deviceid: deviceid,
    //               os_version: os_version,
    //               token: response.data.token,
    //             },
    //             {
    //               headers: {
    //                 Authorization: "Bearer " + response.data.token,
    //               },
    //             }
    //           )
    //           .then(function (res) {
    //             if (res.data.code === 200) {
    //               console.log("app 로그인 정보 갱신 완료");
    //             } else {
    //               console.log(res.data.error);
    //             }
    //           });
    //       }
    //       window.location.href = "/";
    //     }
    //   });
  };

  return (
    <div className="login_page">
      <div className="login_section">
        <div className="logo_section">
          <a href={"/"}>
            <img src="/img/logo.svg" alt={"whnotlink_logo"} />
          </a>
        </div>
        <div className="lg_title">
          와이낫링크가 처음이신가요? <Link to={"/join"}>회원가입하기</Link>
        </div>
        <div className="lg_input_section">
          <form onSubmit={handleSubmit(loginSubmit)}>
            <div className="input_item">
              <input
                type="text"
                placeholder="이메일"
                name={"email"}
                autoComplete="on"
                {...register("email", {
                  required: "이메일을 입력해주세요.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              {errors?.email && (
                <div className={"error_msg"}>{errors?.email?.message}</div>
              )}
            </div>

            <div className="input_item">
              <input
                type="password"
                placeholder="비밀번호"
                name={"password"}
                autoComplete="off"
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                })}
              />
              {errors?.password && (
                <div className={"error_msg"}>{errors?.password?.message}</div>
              )}
            </div>
            {pushid && (
              <div className="input_item maintainLogin">
                <label>
                  로그인 상태 유지{"  "}
                  <input
                    type="checkbox"
                    name={"maintainLogin"}
                    onChange={maintainLoginBt}
                  />
                </label>
              </div>
            )}
            <div className="input_item">
              <button className="default_bt login_bt" type={"submit"}>
                로그인
              </button>
            </div>
          </form>
        </div>
        <div className="lg_bottom_section">
          <ul>
            <li>
              <Link to={"/idSearch"}>아이디찾기</Link>
            </li>
            <li>
              <Link to={"/SendPWEmail"}>비밀번호찾기</Link>
            </li>
            <li className="right_item">
              <Link to={"/privacy"}>개인정보취급방침</Link>
            </li>
          </ul>
        </div>
        <div className="sns_bottom_section">
          <ul>
            <li>
              <a
                href={
                  proxy + `/rest-api/auth/kakao${"?state=" + maintainLogin}`
                }
              >
                <img
                  src={"/img/kakao_login_large_narrow.png"}
                  alt={"kakao-logo"}
                  title={"카카오 로그인"}
                />
              </a>
            </li>
            <li>
              <a
                href={
                  proxy + `/rest-api/auth/naver${"?state=" + maintainLogin}`
                }
              >
                <img
                  src={"/img/naver_logo_ko_narrow.png"}
                  alt={"naver-logo"}
                  title={"네이버 로그인"}
                />
              </a>
            </li>
            {/*<li>
              <a href={proxy + "/rest-api/auth/google"}>
                <img
                  src={"/img/google_logo_ko_narrow.png"}
                  alt={"google-logo"}
                  title={"구글 로그인"}
                />
              </a>
            </li>
            <li>
              <a href={proxy + "/rest-api/auth/facebook"}>
                <img
                  src={"/img/facebook_logo_ko_narrow.png"}
                  alt={"facebook-logo"}
                  title={"페이스북 로그인"}
                />
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Login;
