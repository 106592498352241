import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { useLocation } from "react-router";
import axios from "axios";
import { moneyFormat } from "../../utils/commonApi";
import DatePicker from "react-datepicker";
import FileInput from "../../component/fileInput";

function ProApplyModify(props) {
  // const location = useLocation();
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [project, setProject] = useState({ region: "지역정보" });
  const [projectType, setProjectType] = useState("main");
  const [apply, setApply] = useState({});
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [dueDate, setDueDate] = useState(new Date());
  const [expertInfo, setExpertInfo] = useState({});
  const [applyFiles, setApplyFiles] = useState([]);

  useEffect(() => {
    const getApply = async () => {
      try {
        const response = await axios.get("/rest-api/getApply", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            id: Number(id),
          },
        });
        console.log("res: ", response.data);
        if (response.status === 200) {
          const pType = response.data.apply.projectType;
          setProjectType(pType);
          setProject(response.data.project);
          setExpertInfo(response.data.expertInfo);
          setApply(response.data.apply);
          setApplyFiles([...response.data.apply.file]);
          setValue("cost", response.data.apply.cost);
          setValue("desc", response.data.apply.content);
        } else {
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifySubmit = (data) => {
    data.file = files;
    data.dueDate = dueDate;
    data.id = id;
    axios
      .post("/rest-api/modifyApply", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          history.push("/mypage/pro/apply");
        } else alert(res.data.error);
      });
  };

  const costSet = (prop) => {
    const input = watch("cost") * 10000;
    let costInfo = {
      fee: parseInt(input) * 0.1,
      cost: 0,
      total: 0,
      tax: 0,
    };

    if (input < 10000) costInfo.fee = 0;
    else if (costInfo.fee < 10000) costInfo.fee = 10000;
    else if (costInfo.fee > 1000000) costInfo.fee = 1000000;

    costInfo.cost = input - costInfo.fee;
    costInfo.tax = costInfo.cost * 0.1;
    costInfo.total = costInfo.cost + costInfo.tax;

    return costInfo[prop];
  };
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };
  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <div className="page_title">프로젝트 지원내역</div>
          <div className="info_item_two">
            <div className="info_item">
              <div className="info_title">프로젝트제목</div>
              <div className="info_contents">
                {projectType === "main"
                  ? project.title
                  : project.project !== undefined
                  ? `${project.project.title}(${project.project.status})`
                  : "프로젝트 타이틀"}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">
                {projectType === "main" ? "프로젝트 No" : "공정내용"}
              </div>
              <div className="info_contents">
                {projectType === "main" ? project.projectId : project.title}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">프로젝트 총 예산</div>
              <div className="info_contents">
                총 {moneyFormat(project.cost)}만원
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">희망납기일</div>
              <div className="info_contents">
                {projectType === "main" ? project.dueDate : project.nextDueDate}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">납품지역</div>
              <div className="info_contents">
                {projectType === "main"
                  ? String(project.region)
                  : String(project.region.region)}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">업무분야</div>
              <div className="info_contents">{project.mainCategory}</div>
            </div>
          </div>
          <div className="offer_section">
            <form onSubmit={handleSubmit(modifySubmit)}>
              <div className="input_item">
                <div className="input_title">제안금액</div>
                <div className="input_contents">
                  <input
                    type="number"
                    name={"cost"}
                    placeholder="희망예산"
                    {...register("cost", {
                      required: "제안금액을 적어주세요",
                    })}
                  />
                  만원
                  {errors?.cost && (
                    <div className={"error_msg"}>{errors?.cost?.message}</div>
                  )}
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">수수료</div>
                <div className="info_contents">
                  {moneyFormat(costSet("fee"))}원
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">입금예정액</div>
                <div className="info_contents">
                  {moneyFormat(costSet("total"))}원
                  <div className="info_desc">
                    (세금계산서 발행 = 공급가액 : {moneyFormat(costSet("cost"))}
                    원, 부가세 :{moneyFormat(costSet("tax"))}원)
                    <br />
                    원활한 서비스 제공을 위해 수수료는 제안 금액의 10%로
                    책정합니다.(최소 1만원 / 최대 100만원)
                    <br />
                    예시1: 제안금액 100만원의 경우 수수료 10만원을 제하고
                    90만원+부가세10%(9만원)=99만원 입금
                    <br />
                    예시2: 제안금액 2,000만원의 경우 수수료 100만원을 제하고
                    1,900만원+부가세10%(190만원)=2,090만원 입금
                  </div>
                </div>
              </div>
              <div className="input_item date_item">
                <div className="input_title">제안 납기일</div>
                <div className="input_contents">
                  <DatePicker
                    locale="ko"
                    dateFormat="yyyy/MM/dd(eee)"
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                    name={"dueDate"}
                  />
                </div>
              </div>
              <div className="input_item full_item">
                <div className="input_title">세부내용</div>
                <div className="input_contents">
                  <textarea
                    name={"desc"}
                    {...register("desc", {
                      required: "세부내용을 적어주세요",
                    })}
                    placeholder="요청 내용을 자유롭게 작성해주세요. 목적, 의뢰범위, 사양, 수량 등 자세히 적을수록 매칭될 확률이 높습니다. 작성이 어려울 경우 '상담요청'이라고 기입해주세요. 저희 담당매니저가 전화로 자세히 안내해드리겠습니다."
                  ></textarea>
                  {errors?.desc && (
                    <div className={"error_msg"}>{errors?.desc?.message}</div>
                  )}
                </div>
              </div>
              <div className="input_item file_item">
                <div className="input_title">자료첨부</div>
                <div className="input_contents">
                  <FileInput
                    titleYN={true}
                    files={files}
                    fileChange={fileChange}
                    fileX={fileX}
                  />
                </div>
              </div>
              <div className="bottom_bt_section multi_bt">
                <button
                  type={"button"}
                  className="default_bt prev_bt"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  수정취소
                </button>
                <button type={"submit"} className="default_bt submit_bt">
                  수정완료
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProApplyModify;
