import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as baseActions from "../../store/base";
import queryString from "query-string";
import axios from "axios";

function SocialLogin(props) {
  const { token } = queryString.parse(props.location.search);
  const dispatch = useDispatch();
  const pushid = localStorage.getItem("pushid");
  const deviceid = localStorage.getItem("deviceid");
  const os_version = localStorage.getItem("os_version");

  useEffect(() => {
    if (token) {
      dispatch(baseActions.login(token));
      axios
        .get("/rest-api/getUser", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const user = response.data.user;
            dispatch(baseActions.setUserInfo(user));
            if (pushid) {
              // console.log(user);
              // window.alert("ckeck");
              axios
                .post(
                  "/rest-api/updateAppInfo",
                  {
                    pushid,
                    user_name: user.name,
                    user_email: user.email,
                    user_id: user.id,
                    deviceid,
                    os_version,
                    token,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                  }
                )
                .then(function (res) {
                  if (res.data.code === 200) {
                    console.log("app 로그인 정보 갱신 완료");
                  } else {
                    console.log(res.data.error);
                  }
                });
            }
          } else {
            console.log(response.data.error);
          }
        });
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login_page">
      <svg className="loader" viewBox="0 0 24 24">
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
      </svg>
    </div>
  );
}
export default SocialLogin;
