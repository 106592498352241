import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as baseActions from "../../store/base";
import { useForm } from "react-hook-form";
import ProCate from "../../component/proCate";
import axios from "axios";
import FileInput from "../../component/fileInput";
import { useHistory } from "react-router-dom";

function ProRegister(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm();
  const [proCateItems, setProCateItems] = useState([]);
  const [proCateItems2, setProCateItems2] = useState([]);
  const [files, setFiles] = useState([]);
  const token = localStorage.getItem("token");
  const [regiPage, setRegiPage] = useState(1);
  const [fileCount, setFileCount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요한 메뉴입니다.");
      history.push("/login");
    } else if (userInfo.userLevel === "expert") {
      alert("전문가 정보가 이미 존재합니다. 정보 관리 페이지를 이용해주세요.");
      history.push("/mypage/pro");
    }
    axios
      .get("/rest-api/getExpertCategory", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setProCateItems2(response.data.businessStatus);
        setProCateItems(response.data.category);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };

  const nextPro2 = () => {
    window.scrollTo(0, 0);
    let cateCount = 0;
    clearErrors("company");
    clearErrors("desc");
    clearErrors("type");
    clearErrors("type2");
    clearErrors("cate");
    proCateItems.map((cat, index) => {
      if (cat.value) cateCount++;
      return 0;
    });

    if (watch("type") === "none") {
      setError("type", {
        type: "required",
        message: "기업형태를 선택해 주세요.",
      });
      return 0;
    }
    if (watch("type2") === "none") {
      setError("type2", {
        type: "required",
        message: "업무형태를 선택해 주세요.",
      });
      return 0;
    }
    if (
      (watch("company") === "" || watch("company") === null) &&
      !watch("companySecret")
    ) {
      setError("company", {
        type: "required",
        message: "회사이름을 적어주세요",
      });
      return 0;
    }
    if (watch("desc") === "" || watch("desc") === null) {
      setError("desc", {
        type: "required",
        message: "회사소개를 적어주세요",
      });
      return 0;
    }
    if (cateCount === 0) {
      setError("cate", {
        type: "required",
        message: "1개 이상의 분야를 선택해주세요.",
      });
      return 0;
    }
    setRegiPage(2);
  };
  const proCateChange = (item) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        return cate.idx === item.idx ? { ...item, value: !item.value } : cate;
      })
    );
  };
  const regiSubmit = (data) => {
    const selectCate = proCateItems.reduce((acc, item) => {
      if (item.value === true) acc.push(item.idx);
      return acc;
    }, []);
    axios
      .post(
        "/rest-api/postExpertEnroll",
        {
          Ctype: data.type,
          businessStatus: data.type2 === "기타" ? data.proCateEtc : data.type2,
          companyName: data.company,
          ispublicName: data.companySecret,
          companyInfoDetail: data.desc,
          category: selectCate,
          businessRegUrl: files[0],
          bankName: data.accountCompany,
          acountNumber: data.accountNum,
          acountOwner: data.accountName,
          file: files,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        if (response.data.code === 200) {
          alert("전문가 요청이 성공적으로 등록되었습니다.");
          axios
            .get("/rest-api/getUser", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((response) => {
              dispatch(baseActions.setUserInfo(response.data.user));
            });
        }
      });
  };
  return (
    <div className="contents_wrap">
      {userInfo.userLevel === "expert(pre)" && (
        <div className={"div_page"}>
          <div className="page_title">전문가 지원완료</div>
          <div className={"page_contents"}>
            <div className={"regi_ing"}>
              전문가 신청이 완료되었습니다.
              <div className={"info_desc"}>
                전문가 신청 심사가 영업일기준 3일정도 소요됩니다.
                <br />
                이후에도 이 메시지가 보이면 고객센터로 문의해주세요.
              </div>
            </div>
          </div>
        </div>
      )}
      {userInfo.userLevel !== "expert(pre)" && (
        <form onSubmit={handleSubmit(regiSubmit)}>
          <div
            className="div_page"
            style={{ display: regiPage === 1 ? "block" : "none" }}
          >
            <div className="page_title">전문가 지원(1/2)</div>
            <div className="page_contents pro_apply">
              <div className={"info_desc page_desc"}>
                회사소개, 전문분야 등 기본 정보만 입력하면 완료됩니다.
              </div>
              <div className="info_item">
                <div className="info_title">이름</div>
                <div className="info_contents">{userInfo.name}</div>
              </div>
              <div className="info_item">
                <div className="info_title">이메일</div>
                <div className="info_contents">{userInfo.email}</div>
              </div>
              <div className="info_item">
                <div className="info_title">연락처</div>
                <div className="info_contents">{userInfo.phone}</div>
              </div>
              <div className="offer_section">
                <div className="input_item">
                  <div className="input_title">기업형태</div>
                  <div className="input_contents">
                    <select
                      name={"type"}
                      defaultValue={"none"}
                      {...register("type")}
                    >
                      <option value={"none"} disabled hidden>
                        - 선택 -
                      </option>
                      <option value={"법인사업자"}>법인사업자</option>
                      <option value={"개인사업자"}>개인사업자</option>
                      <option value={"기타사업자"}>기타사업자</option>
                      <option value={"기타(개인, 프리랜서 등)"}>
                        기타(개인, 프래린서 등)
                      </option>
                    </select>
                    <div className={"error_msg"}>
                      {errors?.type && errors?.type?.message}
                    </div>
                  </div>
                </div>
                <div className="input_item">
                  <div className="input_title">업무형태</div>
                  <div className="input_contents">
                    <select
                      name={"type2"}
                      defaultValue={"none"}
                      {...register("type2")}
                    >
                      <option value={"none"} disabled hidden>
                        - 선택 -
                      </option>
                      {proCateItems2.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {watch("type2") === "기타" && (
                      <input
                        type={"text"}
                        placeholder={"직접입력"}
                        name={"proCateEtc"}
                        {...register("proCateEtc")}
                      />
                    )}
                    <div className={"error_msg"}>
                      {errors?.type2 && errors?.type2?.message}
                    </div>
                  </div>
                </div>
                <div className="input_item">
                  <div className="input_title">회사이름</div>
                  <div className="input_contents">
                    <span className="on_off_bt">
                      <label>
                        <input
                          type="checkbox"
                          className="check_toggle"
                          name="companySecret"
                          {...register("companySecret")}
                        />
                        <span className="toggle_bt"></span>
                      </label>
                    </span>
                    <span className="on_off_value">비공개</span>
                    <div>
                      {!watch("companySecret") && (
                        <input
                          type="text"
                          placeholder="회사명을 입력해주세요"
                          name="company"
                          {...register("company")}
                          onChange={() => {
                            if (
                              watch("company") !== "" ||
                              watch("companySecret")
                            )
                              clearErrors("company");
                          }}
                        />
                      )}
                      <div className={"error_msg"}>
                        {errors?.company && errors?.company?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input_item full_item">
                  <div className="input_title">회사소개</div>
                  <div className="input_contents">
                    <textarea
                      name="desc"
                      {...register("desc", {
                        required: "회사명을 입력해주세요",
                      })}
                      placeholder="기타 세부 회사(개인) 소개를 입력해주세요.원활한 서비스 제공을 위해 전화번호, 이메일주소, SNS계정 등 개인정보 기입을 금지합니다.
                                (입력했을 시 임의삭제되며, 승인이 취소될 수 있습니다)"
                    />
                  </div>
                  <div className={"error_msg"}>
                    {errors?.desc && errors?.desc?.message}
                  </div>
                </div>
              </div>
              <div className="job_select_section">
                <div className="info_title">전문분야(중복가능)</div>
                <div className={"error_msg"}>
                  {errors?.cate && errors?.cate?.message}
                </div>
                <ProCate
                  proItems={proCateItems}
                  proChange={proCateChange}
                ></ProCate>
              </div>
            </div>
            <div className="page_bottom">
              <input
                type={"button"}
                onClick={() => nextPro2()}
                className="default_bt next_bt"
                value={"다음으로"}
              />
            </div>
          </div>
          {regiPage === 2 && (
            <div className="div_page">
              <div className="page_title">전문가 지원(2/2)</div>
              <div className="page_contents pro_apply">
                <div className="input_item mobile_full_item">
                  <div className="input_title">제출서류</div>
                  <div className="input_contents">
                    <div className="info_item need_item">
                      <div className="info_title">사업자</div>
                      <div className="info_contents">사업자 등록증 사본</div>
                    </div>
                    <div className="info_item need_item">
                      <div className="info_title">개인, 프리랜서</div>
                      <div className="info_contents">
                        주민등록증, 운전면허증 등 신분증 사본
                      </div>
                    </div>
                    <div className={"error_msg"}>
                      {errors?.files && errors?.files?.message}
                    </div>
                    <FileInput
                      titleYN={false}
                      files={files}
                      fileChange={fileChange}
                      fileX={fileX}
                    />
                    <div className="input_desc">
                      *주민등록번호 뒷자리는 가리고 올려주세요.
                    </div>
                    <div className="input_desc">
                      *안전한 거래를 위해 사업자등록증(신분증)을 통한 신원
                      확인을 하고 있으며 제출한 서류는 개인 정보 취급방침에 의해
                      관리됩니다.
                    </div>
                  </div>
                </div>
                <div className="account_section">
                  <div className="account_desc">
                    프로젝트 수행 대금의 안전하고 신속한 입금을 위한 계좌 정보를
                    등록해주세요.
                  </div>
                  <div className="info_desc">
                    *회사의 경우 사업자 및 법인명의, 개인의 경우 본인명의 계좌를
                    등록해주세요.
                  </div>
                  <div className="input_item">
                    <div className="input_title">은행명</div>
                    <div className="input_contents">
                      <input
                        type="text"
                        placeholder="은행명"
                        name={"accountCompany"}
                        {...register("accountCompany", {
                          required: "은행명을 적어주세요",
                        })}
                      />
                      <div className={"error_msg"}>
                        {errors?.accountCompany &&
                          errors?.accountCompany?.message}
                      </div>
                    </div>
                  </div>
                  <div className="input_item">
                    <div className="input_title">계좌번호</div>
                    <div className="input_contents">
                      <input
                        type="text"
                        placeholder="계좌번호"
                        name={"accountNum"}
                        {...register("accountNum", {
                          required: "계좌번호를 적어주세요",
                        })}
                      />
                      <div className={"error_msg"}>
                        {errors?.accountNum && errors?.accountNum?.message}
                      </div>
                    </div>
                  </div>
                  <div className="input_item">
                    <div className="input_title">예금주</div>
                    <div className="input_contents">
                      <input
                        type="text"
                        placeholder="예금주"
                        name={"accountName"}
                        {...register("accountName", {
                          required: "예금주를 적어주세요",
                        })}
                      />
                      <div className={"error_msg"}>
                        {errors?.accountName && errors?.accountName?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page_bottom pro_apply_bottom">
                <div className="submit_desc">
                  * 프로젝트 지원은 작성 후 업무일 기준 2~3일의 심사가
                  승인되어야 등록이 가능합니다.
                </div>
                <div
                  className="default_bt prev_bt"
                  onClick={() => {
                    setRegiPage(1);
                  }}
                >
                  이전으로
                </div>
                <button
                  type={"submit"}
                  className="default_bt submit_bt"
                  onClick={(e) => {
                    //console.log("submit click!");
                    //
                    // console.log(files);
                    if (files.length === 0) {
                      setError("files", {
                        message: "📌 첨부 파일을 선택해 주세요.",
                      });
                      e.preventDefault();
                      return 0;
                    } else {
                      clearErrors("files");
                      handleSubmit(regiSubmit);
                    }
                  }}
                >
                  등록하기
                </button>
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
}
export default ProRegister;
