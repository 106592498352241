import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import WhyMark from "../../component/WhyMark";
import axios from "axios";
import { moneyFormat } from "../../utils/commonApi";
import Moment from "react-moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

function ProApplyHistory(props) {
  const [applies, setApplies] = useState([]);
  const token = localStorage.getItem("token");
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [amount, setAmount] = useState(15);
  const history = useHistory();
  const location = useLocation();
  const dueHours = 21;
  useEffect(() => {
    axios
      .get("/rest-api/getMyApplies", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount: amount,
          index: pageNum,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.totalApplyCount <= amount * pageNum)
            setLastPage(true);
          setApplies([...response.data.applies]);
          setPageNum(response.data.nextIndex);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const pageMore = () => {
    axios
      .get("/rest-api/getMyApplies", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount,
          index: pageNum,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setApplies([...applies, ...response.data.applies]);
          if (response.data.totalApplyCount <= amount * pageNum)
            setLastPage(true);
          setPageNum(response.data.nextIndex);
        } else if (response.status === 202) {
          console.log("last");
          setLastPage(true);
        }
      });
  };

  const apModify = (apply) => {
    if (!apply) return 0;
    else {
      if (apply.status === "지원중") {
        history.push({
          pathname: `/mypage/pro/apply/modify/${apply.id}`,
        });
      } else {
        alert(
          `"${apply.status}" 상태에서는 수정이 불가합니다. 관리자에게 문의해주세요.`
        );
      }
    }
  };

  const apDelete = (id, index) => {
    if (!id) return 0;
    else {
      if (window.confirm(`해당 지원글을 삭제 하시겠습니까?`))
        axios
          .get("/rest-api/deleteApply", {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              applyId: id,
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              let tempApplies = applies;
              tempApplies.splice(index, 1);
              setApplies([...tempApplies]);
              alert("지원글을 삭제하였습니다.");
            } else alert(`실행 오류: ${response.data.error}`);
          });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents project_list_page">
          <div className="page_title">프로젝트 지원내역</div>
          <div className="project_items">
            {applies.length === 0 && (
              <div className={"no_recruit_contents"}>
                전문가로 지원한 프로젝트가 없습니다.
              </div>
            )}
            {applies.length > 0 &&
              applies.map((apply, index) => {
                const now = moment().tz("Asia/Seoul");
                const dueDate = moment(apply.dueDate)
                  .tz("Asia/Seoul")
                  .add(dueHours, "hours");
                let className = "pr_item";
                switch (apply.status) {
                  case "업무진행":
                    className = "pr_item";
                    break;
                  case "업무종료":
                  case "미선정":
                    className = "pr_item not_active";
                    break;
                  default:
                    className = "pr_item active";
                }
                return (
                  <div className={className} key={index}>
                    <div className="pr_info">
                      <div className="pr_state">
                        {apply.status}
                        {apply.isManager === true && <WhyMark />}
                      </div>
                      <div className="pr_contents">
                        <div className="pr_cate">
                          {apply.mainCategory.map((cat, index) => {
                            return (
                              <span id={index} key={index}>
                                {index !== 0 && "/"}
                                {cat}
                              </span>
                            );
                          })}
                        </div>
                        <div className="pr_title">{apply.title}</div>
                        <div className="pr_options">
                          <ul>
                            <li>{apply.companyName}/</li>
                            <li>총예산 {moneyFormat(apply.cost)}만원/</li>
                            <li className="pr_option">
                              납기일{" "}
                              {<Moment format="MM/DD">{apply.dueDate}</Moment>}
                              {dueDate.diff(now, "minutes") <= 0 && (
                                <span>(기간만료)</span>
                              )}
                              {dueDate.diff(now, "minutes") > 0 && (
                                <span>
                                  (
                                  <Moment
                                    diff={new Date()}
                                    unit={"days"}
                                    add={{ hours: 24 }}
                                  >
                                    {apply.dueDate}
                                  </Moment>
                                  일 남음)
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pr_bt_section">
                        <button className="default_bt">
                          <Link to={`/mypage/pro/apply/detail/${apply.id}`}>
                            상세보기
                          </Link>
                        </button>
                        <button
                          className="default_bt"
                          onClick={(event) => {
                            event.preventDefault();
                            apModify(apply);
                          }}
                        >
                          수정
                        </button>
                        <button
                          className="default_bt"
                          onClick={(event) => {
                            event.preventDefault();
                            if (
                              apply.status === "지원중" ||
                              apply.status === "미선정"
                            )
                              apDelete(apply.id, index);
                            else
                              alert(
                                `"${apply.status}" 상태에서는 삭제가 불가합니다. 관리자에게 문의해주세요.`
                              );
                          }}
                        >
                          삭제
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {!lastPage && applies.length !== 0 && (
            <div className={"more_page_bt_section"}>
              <button className={"default_bt more_page_bt"} onClick={pageMore}>
                더보기
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProApplyHistory;
