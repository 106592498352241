import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import FileInput from "../../component/fileInput";

function RequestRegister(props) {
  const [files, setFiles] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const token = localStorage.getItem("token");
  const [isRegist, setIsRegist] = useState(false);

  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/guestUploadFile", formData)
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };

  const requestSubmit = (data) => {
    axios
      .post(
        "/rest-api/postRequest",
        {
          userName: data.name,
          userPhone: data.phone,
          userEmail: data.email,
          title: data.title,
          content: data.contents,
          file: files,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        if (response.data.code === 200) {
          alert("프로젝트 간편 문의가 성공하였습니다.");
          setIsRegist(true);
        }
      });
  };

  return (
    <div className="contents_wrap">
      {isRegist === true && (
        <div className={"div_page"}>
          <div className="page_title">간편 문의 완료</div>
          <div className={"page_contents"}>
            <div className={"regi_ing"}>
              프로젝트 간편 문의가 완료되었습니다.
              <div className={"info_desc"}>
                프로젝트 업무 검토는 영업일기준 1일 정도 소요됩니다.
                <br />
                간편 문의는 매니저 확인 후 별도 연락드리고 등록이 진행됩니다.
              </div>
            </div>
          </div>
        </div>
      )}
      {isRegist === false && (
        <div className="div_page">
          <form onSubmit={handleSubmit(requestSubmit)}>
            <div className="page_title">프로젝트 등록</div>
            <div className="page_contents project_register">
              <div className="input_item">
                <div className="input_title">문의자</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="문의자 이름"
                    name={"name"}
                    {...register("name", {
                      required: "문의자 이름을 입력해주세요.",
                    })}
                  />
                  {errors?.name && (
                    <div className={"error_msg"}>{errors?.name?.message}</div>
                  )}
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">연락처</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="연락처입력"
                    name={"phone"}
                    {...register("phone", {
                      required: "연락받으실 번호를 입력해주세요.",
                    })}
                  />
                  {errors?.phone && (
                    <div className={"error_msg"}>{errors?.phone?.message}</div>
                  )}
                  <span className="input_desc">
                    (차후 프로젝트 관리를 위하여 핸드폰 번호 입력을 권장합니다.)
                  </span>
                </div>
              </div>
              <div className="input_item wide_item">
                <div className="input_title">이메일</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="이메일입력"
                    name={"email"}
                    {...register("email", {
                      required: "이메일을 입력해주세요",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "이메일형식으로 입력해주세요",
                      },
                    })}
                  />
                  {errors?.email && (
                    <div className={"error_msg"}>{errors?.email?.message}</div>
                  )}
                </div>
              </div>
              <div className="input_item wide_item">
                <div className="input_title">요청제목</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="요청 내용을 간략히 입력해주세요."
                    name={"title"}
                    {...register("title", {
                      required: "프로젝트 제목을 입력해주세요",
                    })}
                  />
                  {errors?.title && (
                    <div className={"error_msg"}>{errors?.title?.message}</div>
                  )}
                </div>
              </div>
              <div className="input_item full_item">
                <div className="input_title">세부내용</div>
                <div className="input_contents">
                  <textarea
                    name={"contents"}
                    {...register("contents", {
                      required: "세부내용을 작성해주세요",
                    })}
                    placeholder="요청 내용을 자유롭게 작성해주세요. 작성이 어려울 경우 '상담요청'이라고 기입해주세요."
                  ></textarea>
                  {errors?.contents && (
                    <div className={"error_msg"}>
                      {errors?.contents?.message}
                    </div>
                  )}
                </div>
              </div>

              <FileInput
                titleYN={true}
                files={files}
                fileChange={fileChange}
                fileX={fileX}
              />
            </div>
            <div className="page_bottom">
              <div className="submit_desc">
                * 매니저 확인 후 개별 연락드립니다.
              </div>
              <button className="default_bt submit_bt" type={"submit"}>
                문의하기
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default RequestRegister;
