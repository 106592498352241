import React, { useState, useEffect, useRef } from "react";
import FileInput from "../../component/fileInput";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function ReviewWrite(props) {
  const { id } = useParams();
  const [project, setProject] = useState({
    title: "",
    category: [],
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [reviewStars, setReviewvStarts] = useState(5);
  const [files, setFiles] = useState([]);
  const token = localStorage.getItem("token");
  const [selectedApply, setSelectedApply] = useState({});
  const history = useHistory();

  useEffect(() => {
    const getProject = async () => {
      try {
        const res = await axios.get("/rest-api/getProjectWithApply", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            id: Number(id),
          },
        });
        console.log(res.data);
        if (res.status === 200) {
          let tempEx = res.data.project.applies.filter(
            (ap) => ap.status === "선정"
          );
          // console.log("expert: ", tempEx);
          setSelectedApply(tempEx[0]);
          setProject(res.data.project);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getProject();
    // eslint-disable-next-line
  }, []);

  const startRendering = () => {
    const result = [];
    for (let i = 0; i < 5; i++) {
      if (i < reviewStars) {
        result.push(
          <span
            className="star_item"
            key={"s" + i}
            onClick={() => {
              starClick(i + 1);
            }}
          >
            <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
          </span>
        );
      } else {
        result.push(
          <span
            className="star_item"
            key={"s" + i}
            onClick={() => {
              starClick(i + 1);
            }}
          >
            <img src="/img/ic_star.svg" alt="ic_star.svg" />
          </span>
        );
      }
    }

    return result;
  };
  const starClick = (num) => {
    console.log(num);
    setReviewvStarts(num);
  };
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };

  const reviewSubmit = async (data) => {
    try {
      if (window.confirm("이대로 리뷰를 작성할까요?")) {
        // console.log({
        //   title: project.title,
        //   contents: data.contents,
        //   rank: reviewStars,
        //   expertInfoId:
        //     selectedApply.userId === null
        //       ? 0
        //       : selectedApply.user.expertInfo.id,
        //   coExInfoId:
        //     selectedApply.userId === null ? selectedApply.expertInfoId : 0,
        //   projectId: project.id,
        //   subProjectId: 0,
        //   allowSNS: false,
        //   files,
        // });
        // return 0;
        const res = await axios.post(
          "/rest-api/postReview",
          {
            title: project.title,
            contents: data.contents,
            rank: reviewStars,
            expertInfoId:
              selectedApply.userId === null
                ? 0
                : selectedApply.user.expertInfo.id,
            coExInfoId: selectedApply.expertInfoId,
            projectId: project.id,
            subProjectId: 0,
            allowSNS: false,
            files,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.status === 200) {
          alert("리뷰 작성이 완료되었습니다!");
          history.push(`/recruit/detail/${project.id}`);
        } else {
          console.log(res.data);
          alert("리뷰 작성에 에러가 발생하여 완료하지 못하였습니다.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">리뷰 작성</div>
        <form onSubmit={handleSubmit(reviewSubmit)}>
          <div className="page_contents">
            <div className="info_item">
              <div className="info_title">프로젝트명</div>
              <div className="info_contents">{project.title}</div>
            </div>
            <div className="info_item">
              <div className="info_title">수행업무</div>
              <div className="info_contents">
                {project.category.map((cate, index) =>
                  index ? ", " + cate.name : cate.name
                )}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">평점</div>
              <div className="info_contents">
                <div className="review_star_items">
                  {startRendering()}{" "}
                  <span className="rv_point">{reviewStars}/5</span>
                </div>
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">물품리뷰</div>
              <div className="input_contents">
                <textarea
                  name="contents"
                  {...register("contents", {
                    required: "리뷰 내용을 작성해주세요.",
                  })}
                  placeholder="제품 및 제작에 관하여 리뷰를 남겨주세요."
                ></textarea>
              </div>
            </div>
            <FileInput
              titleYN={true}
              files={files}
              fileChange={fileChange}
              fileX={fileX}
            />
            <div className="page_bottom">
              <button type={"submit"} className="default_bt submit_bt">
                작성완료
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default ReviewWrite;
