import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const useQuery = () => {
  //location
  const { search } = useLocation();
  const query = queryString.parse(search);
  return query;
};

export function moneyFormat(input) {
  let inputNumber = !input ? 0 : Number(input).toFixed(1);
  if (String(inputNumber).split(".")[1] === "0")
    inputNumber = String(inputNumber).split(".")[0];
  return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

// import XLSX, { writeFile, output } from "xlsx";

// export const readExcel = (input, callback) => {
//   let reader = new FileReader();

//   reader.onload = (function (file) {
//     let data = reader.result;
//     let workBook = XLSX.read(data, { type: "binary" });

//     const wb = workBook.SheetNames.map((sheetName) => {
//       //console.log("SheetName: " + sheetName);
//       let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
//       return rows;
//     });
//     return callback(wb);
//   })(file);
//   reader.readAsBinaryString(input.files[0]);
//   // console.log("worksheet: ", worksheet);
//   // return worksheet;
// };

// export const writeExcel = async (data, fileName) => {
//   try {
//     /* convert AOA back to worksheet */
//     const worksheet = XLSX.utils.aoa_to_sheet(data);
//     /* build new workbook */
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
//     // write file
//     const workbook_out = XLSX.write(workbook, {
//       type: "binary",
//       bookType: "xlsx",
//     });
//     const result = await writeFile(
//       fileName + ".xlsx",
//       output(workbook_out),
//       "ascii"
//     );
//     console.log(result);
//   } catch (error) {
//     console.log(error);
//   }
// };
