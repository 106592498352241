import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import XLSX from "xlsx";

function FAQEditAll(props) {
  const token = localStorage.getItem("token");
  const userInfo = useSelector((state) => state.userInfo);
  const [faqList, setFaqList] = useState([]);
  const [faqOpen, setFaqOpen] = useState(-1);
  const [cateList, setCateList] = useState([]);
  const [currentCate, setCurrentCate] = useState("");
  const history = useHistory();

  useEffect(() => {
    getOldFAQToServer();
  }, []);

  const getOldFAQToServer = async () => {
    try {
      const res = await axios.get("/rest-api/getFAQ", {
        params: {
          amount: 200,
        },
      });
      if (res.status === 200) {
        console.log(res.data);
      } else throw Error(res.data.error);
    } catch (error) {
      console.log(error);
    }
  };

  const setNewFAQsToFile = (rows) => {
    let newFAQs = [];
    if (!rows || !rows.length) return 0;
    else {
      newFAQs = rows.map((row, index) => {
        row.content = row.content.replace("\r", "\n");
        row.categoryJSON = JSON.parse(row.categoryJSON);
        return row;
      });
      setFaqList(newFAQs);
      setCateList(
        newFAQs.reduce((acc, fa) => {
          fa.categoryJSON.map((cate) => {
            if (acc.indexOf(cate) < 0) acc.push(cate);
            return 0;
          });
          return acc;
        }, [])
      );
    }
  };

  const fileRead = (event) => {
    let input = event.target;
    let reader = new FileReader();

    reader.onload = () => {
      let data = reader.result;
      let workBook = XLSX.read(data, { type: "binary" });

      workBook.SheetNames.forEach(function (sheetName) {
        //console.log("SheetName: " + sheetName);
        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
        setNewFAQsToFile(rows);
        //console.log(JSON.stringify(rows));
      });
    };
    reader.readAsBinaryString(input.files[0]);
  };

  const cateSelect = (e) => {
    setCurrentCate(e.target.value);
  };

  const bdOpen = (index) => {
    if (faqOpen === index) setFaqOpen(-1);
    else setFaqOpen(index);
  };

  const handleUpdate = async () => {
    try {
      const res = await axios.post(
        "/rest-api/editFaqAll",
        {
          faqList,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.status === 200)
        window.alert(`FAQ ${res.data.count}개가 적용되었습니다.`);
      else window.alert("FAQ 파일 수정 적용이 실패하였습니다.");
    } catch (error) {
      window.alert("FAQ 파일 수정 적용이 실패하였습니다.");
      console.log(error);
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div
          className="input_item file_item"
          style={{ paddingTop: "20px", paddingLeft: "20px" }}
        >
          <div className={"input_title"}>파일첨부</div>
          <div className="input_contents">
            <div className="file_input">
              <label>
                <input type="file" onChange={fileRead} id={"fileInputFAQ"} />
                <div className="file_text">
                  jpg/jpeg/png/ppt/doc/docx/hwp/pdf/zip
                </div>
                <div className="file_bt">파일찾기</div>
              </label>
            </div>
          </div>
        </div>
        <div className="page_title">
          FAQ
          <div className={"cate_select"}>
            <select
              onChange={(e) => {
                cateSelect(e);
              }}
            >
              <option value={""}>전체</option>
              {cateList.map((cate, index) => (
                <option key={index} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={"board_items"}>
          {faqList.map((bdItem, index) => (
            <div key={index} className={"bd_item"}>
              {(currentCate === "" ||
                bdItem.category.indexOf(currentCate) >= 0) && (
                <div>
                  <div
                    className={"bd_header"}
                    onClick={() => {
                      bdOpen(index);
                    }}
                  >
                    <div className={"bd_num"}>Q.{index + 1}</div>
                    <div className={"bd_title"}>{bdItem.title}</div>
                    <div className={"bd_cate"}>
                      {bdItem.categoryJSON &&
                        bdItem.categoryJSON.map((cate, i) => (
                          <span key={i}>{cate} </span>
                        ))}
                    </div>
                    <div
                      className={
                        "bd_arrow" + (faqOpen === index ? " active" : "")
                      }
                    >
                      <img src={"/img/ic_tri.svg"} alt="ic_tri.svg" />
                    </div>
                  </div>
                  {index === faqOpen && (
                    <div className={"bd_contents"}>
                      <div className={"bd_answer_deco"}>
                        <div className="bd_manage_bt_section text">A</div>
                      </div>
                      {bdItem.content.split("\n").map((line, i) => {
                        return (
                          <span key={i}>
                            {line}
                            <br />
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        {userInfo.userLevel === "manager" && (
          <button className="noticeAdd_bt" onClick={handleUpdate}>
            업데이트
          </button>
        )}
      </div>
    </div>
  );
}
export default FAQEditAll;
