import React, { useState, useEffect } from "react";

function ProCate(props) {
  const [cateItems, setCateItems] = useState([]);
  //const [initIndex, setInintIndex] = useState([]);
  useEffect(() => {
    // setInintIndex(
    //   props.initCate === undefined
    //     ? []
    //     : props.initCate.map((cat) => {
    //         return cat.idx;
    //       })
    // );
    setCateItems(
      props.proItems.reduce((acc, item, i) => {
        if (acc.indexOf(item.cate) === -1) acc.push(item.cate);
        return acc;
      }, [])
    );
  }, [props.proItems]); //props.initCate
  return (
    <div className="job_select_section">
      {cateItems.map((cate, index) => {
        return (
          <div key={index}>
            <div className="job_title">{cate}</div>
            <div className="job_cate_items">
              {props.proItems
                .filter((item) => item.cate === cate)
                .map((item, index2) => {
                  //if (initIndex.indexOf(item.idx) !== -1) item.value = true;
                  return (
                    <div
                      className={"job_item" + (item.value ? " active" : "")}
                      key={index2}
                      onClick={() => {
                        props.proChange(item);
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default ProCate;
