import React, { useState, useEffect } from "react"; //, useRef
import axios from "axios";
import { Link } from "react-router-dom"; //, useLocation
import "moment-timezone";
import AdminSideMenu from "../../component/adminSideMenu";

function RequestList(props) {
  const token = localStorage.getItem("token");
  const [requests, setRequests] = useState([]);
  const pageAmount = 10;
  //const location = useLocation();
  const [status, setStatus] = useState("all");
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState(false);

  useEffect(() => {
    pageMore("all");
    // axios
    //   .get("/rest-api/getRequest", {
    //     params: {
    //       status,
    //       page: pageNum,
    //       amount: pageAmount,
    //     },
    //   })
    //   .then(function (res) {
    //     if (res.status === 200) {
    //       setRequests([...res.data.requests]);
    //       if (res.data.totalRequestCount <= pageAmount * pageNum)
    //         setLastPage(true);
    //       setPageNum(res.data.nextIndex);
    //     }
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //, [location]

  const pageMore = (filter) => {
    const page = filter === "more" ? pageNum : 1;
    axios
      .get("/rest-api/getRequests", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          status: filter || "all",
          amount: pageAmount,
          page,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          //console.log(response.data);
          setRequests([...response.data.requests]);
          if (response.data.totalRequestCount <= pageAmount * pageNum)
            setLastPage(true);
          setPageNum(response.data.nextIndex);
        } else if (response.status === 202) {
          //console.log(response.data.error);
          console.log("lastPage");
          setLastPage(true);
        }
      });
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section admin_requestList">
        <AdminSideMenu />
        <div className="recruit_items">
          <div className="order_section">
            <ul>
              <li
                className={status === "all" ? "active" : ""}
                onClick={() => {
                  setPageNum(1);
                  setStatus("all");
                  pageMore("all");
                }}
              >
                모두
              </li>
              <li
                className={status === "new" ? "active" : ""}
                onClick={() => {
                  setPageNum(1);
                  setLastPage(false);
                  setStatus("new");
                  pageMore("new");
                }}
              >
                신규문의
              </li>
              <li
                className={status === "end" ? "active" : ""}
                onClick={() => {
                  setPageNum(1);
                  setLastPage(false);
                  setStatus("end");
                  pageMore("end");
                }}
              >
                처리완료
              </li>
            </ul>
          </div>
          {requests.map((rc, index) => {
            return (
              <div
                className={
                  "recruit_item" +
                  (rc.status === "확인완료" ? " not_active" : "")
                }
                key={index}
              >
                <Link to={"/admin/request/check/" + rc.id}>
                  <div className="rc_title">
                    {rc.title}
                    <span
                      className={
                        rc.status === "문의등록" ? "rc_time active" : "rc_time"
                      }
                    >
                      {rc.status}
                    </span>{" "}
                  </div>
                  <div className="rc_contents">
                    {rc.content.length > 134
                      ? rc.content.substr(0, 135) + "..."
                      : rc.content}
                  </div>
                  <div className="rc_option">
                    <ul>
                      <li>{rc.userName}</li>
                      <li>{rc.userEmail}</li>
                      <li>{rc.userPhone}</li>
                    </ul>
                  </div>
                </Link>
              </div>
            );
          })}
          {!lastPage && requests.length !== 0 && (
            <div className={"more_page_bt_section"}>
              <button
                className={"default_bt more_page_bt"}
                onClick={() => {
                  pageMore("more");
                }}
              >
                더보기
              </button>
            </div>
          )}
          {requests.length === 0 && (
            <div className={"no_recruit_contents"}>
              해당 조건에 일치하는 문의가 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default RequestList;
