import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

function Login(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
  } = useForm();
  const [phoneCheckYn, setPhoneCheckYn] = useState(false);
  const [phoneCheckToken, setPhoneCheckToken] = useState(null);
  const [phoneCheckError, setPhoneCheckError] = useState(null);
  const [isEmail, setIsEmail] = useState({
    isThere: false,
    result: false,
    email: "",
    error: "",
  });
  const [isCom, setIsCom] = useState(false);
  const [pageError, setPageError] = useState(true);
  const phoneCheck = () => {
    if (!watch("phone")) {
      setError("phone", {
        type: "required",
        message: "휴대폰번호를 입력해주세요",
      });
      return 0;
    }
    axios
      .post("/rest-api/auth/CPauthRequest", {
        phone: watch("phone"),
      })
      .then((response) => {
        // console.log(response);
        if (!response.data.error) {
          setPhoneCheckToken(response.data.hashToken);
          setError("phone", {
            type: "required",
            message: null,
          });
        } else {
          console.log(response.data.error);
        }
      });
  };
  const phoneNumberCheck = () => {
    // console.log(watch("phone_check"));
    axios
      .post("/rest-api/auth/CPauthVerify", {
        code: watch("phone_check"),
        hashToken: phoneCheckToken,
      })
      .then(function (response) {
        // console.log(response);
        if (response.data.result === "일치") {
          alert("인증되었습니다");
          setPhoneCheckToken(null);
          setPhoneCheckYn(true);
          setPhoneCheckError(null);
          setPageError(false);
        } else {
          setPhoneCheckError(response.data.error);
        }
      });
  };
  const idSearchSubmit = async (data) => {
    // console.log(data);
    if (!pageError) {
      axios
        .post("/rest-api/findId", {
          phone: watch("phone"),
        })
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            setIsEmail({
              isThere: true,
              result: true,
              email: res.data.userEmail,
              error: "",
            });
            setIsCom(true);
          } else
            setIsEmail({
              isThere: true,
              result: false,
              email: "",
              error: res.data.error,
            });
        });
    } else alert("휴대폰 인증을 완료해주세요.");
  };
  return (
    <div className="login_page">
      <div className="login_section">
        <div className="logo_section">
          <a href={"/"}>
            <img src="/img/logo.svg" alt="logo.svg" />
          </a>
        </div>
        <div className="lg_title">아이디찾기</div>
        <div className="lg_input_section">
          <form onSubmit={handleSubmit(idSearchSubmit)}>
            <div className="input_item phone_item">
              <div>
                <input
                  type="tel"
                  name={"phone"}
                  placeholder="휴대폰번호"
                  {...register("phone", {
                    required: "휴대폰번호를 입력해주세요",
                    pattern: {
                      value: /^[0-9]{11}$/,
                      message: "숫자 11자리로 입력해주세요",
                    },
                  })}
                />
                <input
                  type={"button"}
                  className={
                    "default_bt phone_check_bt" +
                    (phoneCheckYn ? " disable" : "")
                  }
                  onClick={phoneCheck}
                  value={
                    phoneCheckYn
                      ? "인증완료"
                      : phoneCheckToken
                      ? "다시요청"
                      : "인증요청"
                  }
                />
              </div>
              {errors?.phone && (
                <div className={"error_msg"}>{errors?.phone?.message}</div>
              )}
              {phoneCheckToken && (
                <div>
                  <input
                    type="tel"
                    name={"phone_check"}
                    placeholder="인증번호"
                    {...register("phone_check")}
                  />
                  <input
                    type={"button"}
                    className="default_bt phone_check_bt"
                    onClick={(e) => {
                      phoneNumberCheck();
                      e.stopPropagation();
                    }}
                    value={"인증확인"}
                  />
                </div>
              )}
              {phoneCheckError && (
                <div className={"error_msg"}>{phoneCheckError}</div>
              )}
            </div>
            {isEmail.isThere && (
              <div className={"id_search_result"}>
                <span className={"strong_t"}>
                  {isEmail.result
                    ? `인증된 번호로 가입한 아이디는 ${isEmail.email}입니다.`
                    : isEmail.error}
                </span>
              </div>
            )}
            <div className="input_item" style={{ marginTop: 20 + "px" }}>
              {!isCom && (
                <button className="default_bt join_bt" type={"submit"}>
                  아이디 찾기
                </button>
              )}
              {isCom && <Link to={"/login"}>로그인 하기</Link>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;
