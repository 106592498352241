import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import axios from "axios";
import ProCate from "../../component/proCate";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import FileInput from "../../component/fileInput";

function ProModify(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm(); //setError,
  const { id } = useParams();
  const [exp, setExp] = useState({});
  const [proCateItems, setProCateItems] = useState([]);
  //const [proCate, setProCate] = useState([]);
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const [proCateItems2, setProCateItems2] = useState([]);
  const [toggleBox, setToggleBox] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getExpertCategory", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        console.log(response);
        setCateData(response);
        axios
          .get("/rest-api/getExpertInfo", {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              userId: id,
            },
          })
          .then(function (response2) {
            console.log(response2);
            const info = response2.data.modifyRequest.modify
              ? response2.data.modifyRequest
              : response2.data;
            setPageData(response, info);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCateData = (response) => {
    setProCateItems2(response.data.businessStatus);
    setProCateItems(response.data.category);
  };

  const setPageData = (response, info) => {
    setValue("type", info.Ctype);
    setValue("type2", info.businessStatus);
    setValue("cName", info.companyName);
    setValue("desc", info.companyInfoDetail);
    setValue("accountCompany", info.bankName);
    setValue("accountNum", info.acountNumber);
    setValue("accountName", info.acountOwner);
    setValue("channelTypeCode", info.ispublicName);
    setToggleBox(info.ispublicName ? true : false);
    setFiles([...info.file]);
    setExp(info);
    setProCateItems(
      response.data.category.map((cate) => {
        if (
          info.category.filter((cateinfo) => {
            return cateinfo.idx === cate.idx;
          }).length > 0
        )
          return { ...cate, value: true };
        else return cate;
      })
    );
  };

  const proCateChange = (item) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        return cate.name === item.name ? { ...item, value: !item.value } : cate;
      })
    );
  };
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };
  const modifySubmit = () => {
    let sendData = {};
    const selectCate = proCateItems.reduce((acc, item) => {
      if (item.value === true) acc.push(item.idx);
      return acc;
    }, []);

    sendData = {
      id: exp.id,
      Ctype: watch("type"),
      category: selectCate,
      businessStatus: watch("type2"),
      companyName: watch("cName"),
      ispublicName: toggleBox,
      companyInfoDetail: watch("desc"),
      businessRegUrl: files,
      bankName: watch("accountCompany"),
      acountNumber: watch("accountNum"),
      acountOwner: watch("accountName"),
      file: files,
    };
    axios
      .post("/rest-api/postExpertModify", sendData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          history.push("/mypage/pro");
        } else alert(res.data.error);
      });
  };
  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <form onSubmit={handleSubmit(modifySubmit)}>
            <div className="page_title">전문가 정보 수정</div>
            <div className="input_item">
              <div className="input_title">기업형태</div>
              <div className="input_contents">
                <select name={"type"} {...register("type")}>
                  <option value={"법인사업자"}>법인사업자</option>
                  <option value={"개인사업자"}>개인사업자</option>
                </select>
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">업무형태</div>
              <div className="input_contents">
                <select name={"type2"} {...register("type2")}>
                  {proCateItems2.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                {watch("type2") === "기타" && (
                  <input
                    type={"text"}
                    placeholder={"직접입력"}
                    name={"proCateEtc"}
                    {...register("proCateEtc")}
                  />
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">회사이름</div>
              <div className="input_contents">
                <span className="on_off_bt">
                  <label>
                    <input
                      type="checkbox"
                      className="check_toggle"
                      name="channelTypeCode"
                      onClick={() => {
                        setToggleBox(!toggleBox);
                      }}
                    />
                    <span className="toggle_bt"></span>
                  </label>
                </span>
                <span className="on_off_value">
                  {toggleBox ? "공개" : "비공개"}
                </span>
                <div>
                  <input
                    type="text"
                    placeholder="회사명을 입력해주세요"
                    name={"cName"}
                    {...register("cName", {
                      required: "회사명을 입력해주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.cName && errors?.cName?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">회사소개</div>
              <div className="input_contents">
                <textarea
                  name={"desc"}
                  {...register("desc", {
                    required: "회사소개를 입력해주세요",
                  })}
                  placeholder="기타 세부 회사(개인) 소개를 입력해주세요.
원활한 서비스 제공을 위해 전화번호, 이메일주소, SNS계정 등 개인정보 기입을 금지합니다.
(입력했을 시 임의삭제되며, 승인이 취소될 수 있습니다)"
                ></textarea>
                <div className={"error_msg"}>
                  {errors?.desc && errors?.desc?.message}
                </div>
              </div>
            </div>
            <div className="job_select_section">
              <div className="info_title">전문분야(중복가능)</div>
              <ProCate
                proItems={proCateItems}
                proChange={proCateChange}
              ></ProCate>
            </div>
            <div className="account_section">
              <div className="input_item mobile_full_item">
                <div className="input_title">제출서류</div>
                <div className="input_contents">
                  <div className="info_item need_item">
                    <div className="info_title">개인 및 법인 사업자</div>
                    <div className="info_contents">사업자 등록증 사본</div>
                  </div>
                  <div className="info_item need_item">
                    <div className="info_title">개인, 프리랜서</div>
                    <div className="info_contents">
                      주민등록증, 운전면허증 등 신분증 사본
                    </div>
                  </div>
                  <FileInput
                    titleYN={files.length >= 0 ? true : false}
                    files={files}
                    fileChange={fileChange}
                    fileX={fileX}
                  />
                  <div className="input_desc">
                    *주민등록번호 뒷자리는 가리고 올려주세요.
                  </div>
                  <div className="input_desc">
                    *안전한 거래를 위해 사업자등록증(신분증)을 통한 신원 확인을
                    하고 있으며 제출한 서류는 개인 정보 취급방침에 의해
                    관리됩니다.
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">은행명</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="은행명"
                    name={"accountCompany"}
                    {...register("accountCompany", {
                      required: "은행명을 적어주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.accountCompany && errors?.accountCompany?.message}
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">계좌번호</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="계좌번호"
                    name={"accountNum"}
                    {...register("accountNum", {
                      required: "계좌번호를 적어주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.accountNum && errors?.accountNum?.message}
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">예금주</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="예금주"
                    name={"accountName"}
                    {...register("accountName", {
                      required: "예금주를 적어주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.accountName && errors?.accountName?.message}
                  </div>
                </div>
              </div>
              <div className="info_desc">
                *회사의 경우 사업자 및 법인명의, 개인의 경우 본인명의 계좌를
                등록해주세요.
              </div>
            </div>
            <div className="bottom_bt_section">
              <button type={"submit"} className="default_bt submit_bt">
                수정요청
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ProModify;
