import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from "axios";

function Account(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
  } = useForm();
  const token = localStorage.getItem("token");
  const [phoneCheckYn, setPhoneCheckYn] = useState(false);
  const [phoneCheckToken, setPhoneCheckToken] = useState(null);
  const [phoneCheckError, setPhoneCheckError] = useState(null);
  const [phoneCheckView, setPhoneCheckView] = useState(false);
  const [userInfo, setUserInfo] = useState({ id: null });
  const [pageError, setPageError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getUser", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) setUserInfo(res.data.user);
      });
  }, [token]);

  const phoneCheck = () => {
    if (!watch("phone")) {
      setError("phone", {
        type: "required",
        message: "휴대폰번호를 입력해주세요",
      });
      return 0;
    }
    axios
      .post("/rest-api/auth/CPauthRequest", {
        phone: watch("phone"),
      })
      .then((response) => {
        if (!response.data.error) {
          setPhoneCheckToken(response.data.hashToken);
          setError("phone", {
            type: "required",
            message: null,
          });
        } else {
          console.log(response.data.error);
        }
      });
  };
  const phoneNumberCheck = () => {
    axios
      .post("/rest-api/auth/CPauthVerify", {
        code: watch("phone_check"),
        hashToken: phoneCheckToken,
      })
      .then(function (response) {
        if (response.data.result === "일치") {
          alert(
            "인증되었습니다. 유저 정보에 반영하시려면 '수정완료'를 눌러 완료해주세요."
          );
          userInfo.phone = watch("phone");
          setUserInfo(userInfo);
          setPhoneCheckToken(null);
          setPhoneCheckYn(true);
          setPhoneCheckError(null);
          setPhoneCheckView(false);
          setPageError(false);
        } else {
          setPageError(true);
          setPhoneCheckError(response.data.error);
        }
      });
  };
  const modifySubmit = (data) => {
    if (!pageError)
      axios
        .post(
          "/rest-api/modifyUser",
          {
            name: data.name,
            phone: userInfo.phone,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            alert(response.data.message);
            history.push("/mypage/account");
          } else {
            alert(response.data.error);
          }
        });
    else alert("수정 완료 후 다시 시도하세요.");
  };

  const moveDeleteAccount = () => {
    if (
      window.confirm(
        "정말로 탈퇴하시겠습니까?(회원정보를 포함한 모든 계정과 연결된 정보는 삭제됩니다)"
      )
    )
      history.push("/mypage/deleteAccount");
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents my_account_page">
          <form onSubmit={handleSubmit(modifySubmit)}>
            <div className="page_title">회원정보수정</div>
            <div className="input_item">
              <div className="input_title">이름</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="이름"
                  defaultValue={userInfo.name}
                  name={"name"}
                  maxLength={20}
                  {...register("name", {
                    required: "이름을 입력해주세요",
                  })}
                />
                <div className={"error_msg"}>
                  {errors?.name && errors?.name?.message}
                </div>
              </div>
            </div>
            <div className="input_item phone_item">
              <div className="input_title">휴대번호</div>
              <div className="input_contents">
                {!phoneCheckView && (
                  <div>
                    <span className={"original_phone"}>{userInfo.phone}</span>
                    <input
                      type={"button"}
                      className="default_bt modify_bt"
                      onClick={() => {
                        setPhoneCheckView(true);
                        setPageError(true);
                      }}
                      value={"휴대번호변경"}
                    />
                  </div>
                )}
                {phoneCheckView && (
                  <div>
                    <div className={"phone_modify_item"}>
                      <input
                        type="tel"
                        name={"phone"}
                        placeholder="휴대폰번호"
                        {...register("phone", {
                          required: "휴대폰번호를 입력해주세요",
                          pattern: {
                            value: /^[0-9]{11}$/,
                            message: "숫자 11자리로 입력해주세요",
                          },
                        })}
                      />
                      <input
                        type={"button"}
                        className={
                          "default_bt phone_check_small_bt" +
                          (phoneCheckYn ? " disable" : "")
                        }
                        onClick={phoneCheck}
                        value={
                          phoneCheckYn
                            ? "인증완료"
                            : phoneCheckToken
                            ? "다시요청"
                            : "인증요청"
                        }
                      />
                    </div>
                    {errors?.phone && (
                      <div className={"error_msg"}>
                        {errors?.phone?.message}
                      </div>
                    )}
                    {phoneCheckToken && (
                      <div>
                        <input
                          type="tel"
                          name={"phone_check"}
                          placeholder="인증번호"
                          {...register("phone_check")}
                        />
                        <input
                          type={"button"}
                          className="default_bt phone_check_small_bt"
                          onClick={(e) => {
                            phoneNumberCheck();
                            e.stopPropagation();
                          }}
                          value={"인증확인"}
                        />
                      </div>
                    )}
                    {phoneCheckError && (
                      <div className={"error_msg"}>{phoneCheckError}</div>
                    )}
                    <div className={"modify_bt_section"}>
                      {phoneCheckView && (
                        <input
                          type={"button"}
                          className="default_bt modify_bt"
                          onClick={() => {
                            setPhoneCheckView(false);
                            setPageError(false);
                          }}
                          value={"변경취소"}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">이메일</div>
              <div className="input_contents">
                <div style={{ marginTop: 7 + "px", color: "grey" }}>
                  <span className={"original_phone"}>{userInfo.email}</span>
                  <span className={"original_phone"}>(변경불가)</span>
                </div>
              </div>
            </div>
            <div className="bottom_bt_section">
              <button type={"submit"} className="default_bt submit_bt">
                수정완료
              </button>
              <button
                type={"button"}
                className="default_bt cancel_bt"
                style={{ marginLeft: "20px" }}
                onClick={moveDeleteAccount}
              >
                회원탈퇴
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Account;
