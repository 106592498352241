import { createAction, handleActions } from "redux-actions";
//import { useState } from "react";
//import { Map } from "immutable";
import { useTitle } from "../App";
import axios from "axios";
const LOGIN = "base/LOGIN";
const LOGOUT = "base/LOGOUT";
const SET_USER_INFO = "base/SET_USER_INFO";
const SET_TITLE = "base/SET_TITLE";
//const GET_USER_INFO = "base/GET_USER_INFO";

export const login = createAction(LOGIN);
export const logout = createAction(LOGOUT);
export const setUserInfo = createAction(SET_USER_INFO);
// export const setTitle = createAction(SET_TITLE);

export const getUserInfo = async (token, dispatch) => {
  const response = await axios.get("/rest-api/getUser", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  dispatch(setUserInfo(response.data));
};
// export const setTitle = (title)=>useTitle(title);

const initialState = {
  token: "",
  userInfo: {
    id: null,
  },
  pageTitle: "와이낫링크: 홍보물 인쇄 대행 플랫폼",
};

export default handleActions(
  {
    [LOGIN]: (state, action) => {
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    },
    [LOGOUT]: (state, action) => {
      localStorage.removeItem("token");
      window.location.href = "/";
      return {
        token: "",
        userInfo: {},
      };
    },
    [SET_USER_INFO]: (state, action) => {
      return {
        ...state,
        userInfo: action.payload,
      };
    },
    // [SET_TITLE]:(state,action)=>{
    //   return{
    //     ...state,
    //     pageTitle: action.payload,
    //   }
    // }
  },
  initialState
);
