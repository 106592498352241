import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../component/adminSideMenu";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import monent from "moment-timezone";

function AdminMain(props) {
  const token = localStorage.getItem("token");
  const [newUser, setNewUser] = useState([]);
  const [adInfos, setAdInfos] = useState([]);
  // const [exList, setExList] = useState([]);
  // const [prList, setPrList] = useState([]);
  const [status_ur, setStatus_ur] = useState(0);
  const [status_ex, setStatus_ex] = useState(0);
  const [status_pr, setStatus_pr] = useState(0);
  const [status_rq, setStatus_rq] = useState(0);
  // const [status_ap, setStatus_ap] = useState(0);
  const [hour, setHour] = useState(48);
  const history = useHistory();
  const { register, watch } = useForm();
  const [changeBt, setChangeBt] = useState(true);

  useEffect(() => {
    getNewUser();
    adminStatus();
    getAdinfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdinfo = async (h) => {
    try {
      const res = await axios.get("/rest-api/getAdInfo", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        //console.log("adInfos: ", res);
        if (res.data.adInfos.length > 0) setAdInfos([...res.data.adInfos]);
      } else throw Error(res.data.error);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const getNewUser = async (h) => {
    const hh = h || hour;
    setHour(hh);
    try {
      const res = await axios.get("/rest-api/getNewUser", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          hour: hh,
        },
      });
      if (res.status === 200) {
        //console.log("newUser: ", res.data);
        setNewUser([...res.data.users]);
        setStatus_ur(res.data.newUserCount);
      } else if (res.data.error.indexOf("jwt") >= 0) {
        alert("관리자 권한이 있는 유저만 접근 가능합니다.");
        history.push("/");
      } else throw Error(res.data.error);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const adminStatus = async () => {
    try {
      const res = await axios.get("/rest-api/adminStatus", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        //console.log(res.data);
        setStatus_ex(res.data.status.ex);
        setStatus_pr(res.data.status.pr);
        setStatus_rq(res.data.status.rq);
      } else if (res.data.error.indexOf("jwt") >= 0) {
        alert("관리자 권한이 있는 유저만 접근 가능합니다.");
        history.push("/");
      } else throw Error(res.data.error);
    } catch (err) {
      console.log(err);
    }
  };

  /*
  const getPRList = async () => {
    try {
      const res = await axios.get("/rest-api/getProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount: 10,
          status: ["request", "modify"],
        },
      });
      if (res.status === 200) {
        //console.log("projects: ", res.data);
        setPrList([...res.data.projects]);
        setStatus_pr(res.data.totalProjectCount);
      } else
        console.log(
          "정보 로드 에러: 업무 요청 리스트 정보 로딩에 실패하였습니다."
        );
    } catch (err) {
      console.log(
        "정보 로드 에러: 업무 요청 리스트 정보 로딩에 실패하였습니다."
      );
      console.log("Error: ", err);
    }
  };

  const getEXList = async () => {
    try {
      const res = await axios.get("/rest-api/getExpertEnrollList", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        //console.log("exEnrolls: ", res.data);
        setExList([...res.data.expertInfos]);
        setStatus_ex(res.data.expertInfos.length);
      } else
        console.log(
          "정보 로드 에러: 업무 요청 리스트 정보 로딩에 실패하였습니다."
        );
    } catch (err) {
      console.log(
        "정보 로드 에러: 업무 요청 리스트 정보 로딩에 실패하였습니다."
      );
      console.log("Error: ", err);
    }
  }; */

  const changeHour = async () => {
    let h = 48;
    if (watch("hours") === "직접입력") h = watch("hour_other") || 48;
    else h = watch("hours");
    try {
      getNewUser(h);
      setChangeBt(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section admin_mainPage">
        <AdminSideMenu />
        <div className="mypage_contents admin_mainContents">
          <div className="admin_contents_container">
            <div className="page_title">종합 현황</div>
            <div className="statusBox">
              <div className="statusBox_title">신규 가입자(48시간 기준)</div>
              <div className="statusBox_info">{`${status_ur}명`}</div>
              <div className="statusBox_manageBt">하단리스트참조</div>
            </div>
            <div className="statusBox">
              <div className="statusBox_title">간편문의(미처리 갯수)</div>
              <div className="statusBox_info">{`${status_rq}건`}</div>
              <div className="statusBox_manageBt">
                <Link to="/admin/requestList">간편문의관리🔎</Link>
              </div>
            </div>
            <div className="statusBox">
              <div className="statusBox_title">업무요청(미처리 갯수)</div>
              <div className="statusBox_info">{`${status_pr}건`}</div>
              <div className="statusBox_manageBt">
                <Link to="/admin/recruitList">프로젝트관리🔎</Link>
              </div>
            </div>
            <div className="statusBox">
              <div className="statusBox_title">전문가요청(미처리 갯수)</div>
              <div className="statusBox_info">{`${status_ex}건`}</div>
              <div className="statusBox_manageBt">
                <Link to="/admin/expertEnrollList">전문가관리🔎</Link>
              </div>
            </div>
          </div>
          <div className="admin_contents_container newUser_section">
            <div className="page_title">
              <div className="title">{`신규가입현황(${hour}시간 기준)`}</div>
              <div className="input_item">
                {changeBt && (
                  <div
                    className="inner_bt"
                    onClick={() => {
                      setChangeBt(!changeBt);
                    }}
                  >
                    시간변경
                  </div>
                )}
                {!changeBt && (
                  <div className="input_item">
                    <div className="input_contents">
                      <select name={"hours"} {...register("hours")}>
                        {[9, 12, 24, 48, 72, "직접입력"].map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {watch("hours") === "직접입력" && (
                        <input
                          type={"text"}
                          placeholder={"직접입력"}
                          name={"hour_other"}
                          {...register("hour_other")}
                        />
                      )}
                      <div className="reload_bt" onClick={changeHour}>
                        {"🔄"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="newUser_title">
              <div className="newUser_title">
                이름/ 이메일/ 전화번호/ 가입경로/ 신규문의건/ 전문가신청여부/
                가입일자
              </div>
            </div>
            {newUser.map((user, index) => {
              const time = monent(user.createdAt).add(9, "h");
              let [date, hhmm] = time.toISOString().split("T");
              date = date.split("-")[1] + "-" + date.split("-")[2];
              hhmm = hhmm.split(":")[0] + ":" + hhmm.split(":")[1];
              return (
                <div className="newUserBox" key={index}>
                  <div className="newUser_contents">{user.name}</div>
                  <div className="newUser_contents">{"/ " + user.email}</div>
                  <div className="newUser_contents">{"/ " + user.phone}</div>
                  <div className="newUser_contents">
                    {"/ " + user.wayToLogin}
                  </div>
                  <div className="newUser_contents">
                    {"/ 문의:" + user.projects.length + "건"}
                  </div>
                  <div className="newUser_contents">
                    {user.expertInfo && "/ 전문가 신청"}
                  </div>
                  <div className="newUser_contents">{`/ ${date}(${hhmm})`}</div>
                </div>
              );
            })}
            {newUser.length === 0 && (
              <div className={"no_recruit_contents"}>
                {`😥 ${hour}시간 내 신규 가입자가 없습니다.`}
              </div>
            )}
          </div>
          <div className="admin_contents_container">
            <div className="page_title">광고 현황</div>
            <div className="statusBox">
              <div className="statusBox_title">로고세상</div>
              <div className="statusBox_info">배너</div>
              <div className="statusBox_manageBt">
                {`${
                  adInfos.filter(
                    (ad) => ad.place === "logosesang" && ad.type === "banner"
                  ).length
                }건`}
              </div>
            </div>
            <div className="statusBox">
              <div className="statusBox_title">로고세상</div>
              <div className="statusBox_info">공지글</div>
              <div className="statusBox_manageBt">
                {`${
                  adInfos.filter(
                    (ad) => ad.place === "logosesang" && ad.type === "notice"
                  ).length
                }건`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminMain;
