import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as baseActions from "../../store/base";
import MySideMenu from "../../component/mySideMenu";
import axios from "axios";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";

function Alarms(props) {
  const token = localStorage.getItem("token");
  const [alarmList, setAlarmList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [amount, setAmount] = useState(5);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getAlerts", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          status: "all",
          amount,
          index: pageNum,
        },
      })
      .then((response) => {
        setAlarmList(response.data.alerts);
        setPageNum(response.data.nextIndex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const alarmClick = (alarm) => {
    if (alarm === "all")
      axios
        .get("/rest-api/setReadAlert", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            isAll: true,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alarmList.map((item) => {
              if (item.id === alarm.id) item.isRead = true;
              return 0;
            });
            setAlarmList([...alarmList]);
          }
        });
    else
      axios
        .get("/rest-api/setReadAlert", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            alertIds: [alarm.id],
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //if (alarm.guideLink !== null) history.push(alarm.guideLink);
            //else {
            alarmList.forEach((item) => {
              if (item.id === alarm.id) item.isRead = true;
            });
            setAlarmList([...alarmList]);
            //}
          }
        });
  };
  const pageMore = () => {
    axios
      .get("/rest-api/getAlerts", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          status: "all",
          amount,
          index: pageNum,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setAlarmList([...alarmList, ...response.data.alerts]);
          if (response.data.totalAlertCount <= amount * pageNum)
            setLastPage(true);
          setPageNum(response.data.nextIndex);
        } else if (response.status === 202) {
          console.log("last");
          setLastPage(true);
        }
      });
  };
  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">
          알림내역
          <div
            className={"all_read_bt"}
            onClick={(event) => {
              event.preventDefault();
              alarmClick("all");
            }}
          >
            모두 읽은상태로표시
          </div>
        </div>
        <div className={"alarm_items"}>
          {alarmList.map((alarm, index) => (
            <div
              className={"alarm_item" + (alarm.isRead ? " readed" : "")}
              key={index}
              onClick={() => {
                alarmClick(alarm);
              }}
            >
              {alarm.content}
              <span className={"alarm_time"}>
                <Moment fromNow local="ko">
                  {alarm.createdAt}
                </Moment>
              </span>
              {alarm.guideLink !== null && (
                <span
                  className={"alarm_time"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(alarm.guideLink);
                  }}
                >
                  📌관련링크 바로가기
                </span>
              )}
            </div>
          ))}
        </div>
        {!lastPage && alarmList.length !== 0 && (
          <div className={"more_page_bt_section"}>
            <button className={"default_bt more_page_bt"} onClick={pageMore}>
              더보기
            </button>
          </div>
        )}
        {alarmList.length === 0 && (
          <div className={"no_recruit_contents"}>알림 정보가 없습니다.</div>
        )}
      </div>
    </div>
  );
}
export default Alarms;
