import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import ProCate from "../../component/proCate";
import geoInfo from "../../geoInfo/sigungu";
import FileInput from "../../component/fileInput";

function ProjectModify(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [recruitEndDate, setRecruitEndDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [geoSelected, setGeoSelected] = useState(geoInfo[0]);
  const [proCateItems, setProCateItems] = useState([]);
  const [geo, setGeo] = useState({ geo1: "미정", geo2: "미정" });
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const [cancelPage, setCancelPage] = useState(false);

  useEffect(() => {
    axios
      .get("/rest-api/getProject", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      })
      .then(function (result) {
        if (result.status === 200) {
          const info = result.data.project;
          //console.log(info);
          setValue("title", info.title);
          setValue("company", info.companyName);
          setValue("cost", info.cost);
          setValue("contents", info.content);
          setFiles(info.file);
          setRecruitEndDate(new Date(info.applyDueDate));
          setDueDate(new Date(info.dueDate));
          let geoTemp = info.region;
          geoTemp = String(geoTemp).split(" ");
          setGeo({ geo1: geoTemp[0], geo2: geoTemp[1] });
          setValue("geo1", geoTemp[0]);
          geoInfo.map((item, index) => {
            if (item.name === geoTemp[0]) setGeoSelected(geoInfo[index]);
            return 0;
          });
          setValue("geo2", geoTemp[1]);

          axios
            .get("/rest-api/getClientCategory", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then(function (res) {
              let tempCate = res.data.category;
              if (info.category[0] === "매니징") setToggle(true);
              else {
                info.category.map((item) => {
                  tempCate.map((cat) => {
                    if (item.idx === cat.idx) cat.value = true;
                    return 0;
                  });
                  return 0;
                });
              }
              setProCateItems([...tempCate]);
            });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };

  const geoChange = (e) => {
    const selectGeo = geoInfo.filter((item) => item.name === e.target.value);
    setGeoSelected(selectGeo[0]);
  };
  const proCateChange = (item) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        return cate.name === item.name ? { ...item, value: !item.value } : cate;
      })
    );
  };
  const modifySubmit = (data) => {
    const selectCate = proCateItems.reduce((acc, item) => {
      if (item.value === true) acc.push(item.idx);
      return acc;
    }, []);

    if (cancelPage) history.goBack();
    else if (
      window.confirm("변경한 내용으로 프로젝트 수정을 요청하시겠습니까?")
    )
      axios
        .post(
          "/rest-api/modifyProject",
          {
            id,
            title: data.title,
            companyName: data.company,
            cost: data.cost,
            region: data.geo1 + " " + data.geo2,
            applyDueDate: recruitEndDate,
            dueDate: dueDate,
            category: data.whnManage ? "매니징" : [...selectCate],
            content: data.contents,
            file: files,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          if (response.data.code === 200) {
            alert(response.data.message);
            history.push("/mypage/project");
          } else alert(response.data.error);
        });
  };
  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <form onSubmit={handleSubmit(modifySubmit)}>
            <div className="page_title">프로젝트 수정</div>
            <div className="input_item wide_item">
              <div className="input_title">요청제목</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="요청 제목을 간략히 입력해주세요."
                  name={"title"}
                  {...register("title", {
                    required: "요청제목을 입력해주세요",
                  })}
                />
                {errors?.title && (
                  <div className={"error_msg"}>{errors?.title?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">회사이름</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="회사명을 입력해주세요"
                  name={"company"}
                  {...register("company", {
                    required: "회사명을 입력해주세요",
                  })}
                />
                {errors?.company && (
                  <div className={"error_msg"}>{errors?.company?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">희망예산</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="희망예산"
                  name={"cost"}
                  {...register("cost", {
                    required: "희망예산 입력해주세요",
                  })}
                />
                만원
                {errors?.money && (
                  <div className={"error_msg"}>{errors?.money?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">납품지역</div>
              <div className="input_contents">
                <select
                  name="geo1"
                  //value={geo.geo1}
                  {...register("geo1")}
                  onChange={geoChange}
                >
                  {geoInfo.map((item, index) => (
                    <option key={index}>{item.name}</option>
                  ))}
                </select>
                <select
                  name="geo2"
                  //defaultValue={geo.geo2}
                  {...register("geo2")}
                >
                  {geoSelected.gugun.map((item, index2) => (
                    <option key={index2}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">모집마감일</div>
              <div className="input_contents">
                <DatePicker
                  locale="ko"
                  dateFormat="yyyy/MM/dd(eee)"
                  selected={recruitEndDate}
                  onChange={(date) => {
                    setRecruitEndDate(date);
                  }}
                />
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">최종납기일</div>
              <div className="input_contents">
                <DatePicker
                  locale="ko"
                  dateFormat="yyyy/MM/dd(eee)"
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                />
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">업무분야</div>
              <div className="input_contents">
                <span className="on_off_bt">
                  <label>
                    <input
                      type="checkbox"
                      className="check_toggle"
                      name="whnManage"
                      {...register("whnManage")}
                      defaultValue={toggle}
                    />
                    <span className="toggle_bt"></span>
                  </label>
                </span>
                <span className="on_off_value">와이낫링크 매니징</span>
                <span className="input_desc">
                  와이낫링크에서 알아서 필요한 부분을 업무세부분야를 정해줍니다.{" "}
                </span>
                {!watch("whnManage") && (
                  <ProCate
                    proItems={proCateItems}
                    proChange={proCateChange}
                  ></ProCate>
                )}
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">세부내용</div>
              <div className="input_contents">
                <textarea
                  name={"contents"}
                  {...register("contents", {
                    required: "세부내용을 작성해주세요",
                  })}
                  placeholder="요청 내용을 자유롭게 작성해주세요. 목적, 의뢰범위, 사양, 수량 등 자세히 적을수록 매칭될 확률이 높습니다. 작성이 어려울 경우 '상담요청'이라고 기입해주세요. 저희 담당매니저가 전화로 자세히 안내해드리겠습니다."
                ></textarea>
                {errors?.contents && (
                  <div className={"error_msg"}>{errors?.contents?.message}</div>
                )}{" "}
              </div>
            </div>
            <FileInput
              titleYN={false}
              files={files}
              fileChange={fileChange}
              fileX={fileX}
            />
            <div className="bottom_bt_section ">
              <button className="default_bt submit_bt">수정완료</button>
              <button
                className={"default_bt prev_bt"}
                onClick={() => {
                  setCancelPage(true);
                  //history.goBack();
                }}
                style={{ marginLeft: "10px" }}
              >
                뒤로가기
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ProjectModify;
