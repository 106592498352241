import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";
import { moneyFormat } from "../../utils/commonApi";
import FileInput from "../../component/fileInput";
import XLSX from "xlsx";
import Spreadsheet from "react-spreadsheet";

function AdminRecruitDetail(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [projectInfo, setProjectInfo] = useState({});
  const [mainPjFiles, setMainPjFiles] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [creator, setCreator] = useState({ id: 0, name: "유저정보없음" });
  const [button, setButton] = useState({ isThere: false });
  const [iss, setIss] = useState({});
  const history = useHistory();
  const [workEndConfirm_bt, setWorkEndConfirm_bt] = useState(false);
  const [files, setFiles] = useState([]);
  const [alertFiles, setAlertFiles] = useState([]);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [projectType, setProjectType] = useState("subProject");
  const subProjectStatus = ["모집중", "선정중", "선정완료", "진행중", "완료"];

  const status = [
    "업무검토",
    "모집중",
    "선정중",
    "결제대기",
    "결제완료",
    "업무진행",
    "업무종료",
    "보류",
  ];

  useEffect(() => {
    axios
      .get("/rest-api/getProjectWithApply", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      })
      .then(function (result) {
        if (result.status === 200) {
          //console.log(result.data);
          let tempData = [];
          setProjectInfo(result.data.project);
          setMainPjFiles([...result.data.project.file]);
          setSubProjects([...result.data.subProjects]);
          setCreator(result.data.project.user);
          for (let i = 0; i < result.data.subProjects.length; i++)
            tempData.push([]);
          setData(tempData);
          setViewData(tempData);
          axios
            .get("/rest-api/getWhetherIs", {
              headers: {
                Authorization: "Bearer " + token,
              },
              params: {
                projectId: Number(id),
              },
            })
            .then((response) => {
              if (response.status !== 200) setButton({ isThere: false });
              else {
                const iss = response.data;
                setIss(iss);
                if (iss.isOwn) {
                  setButton({
                    isThere: true,
                    linkTo: "/mypage/project/apply",
                    className: "default_bt apply_state_view_bt",
                    text: "지원업체보기",
                    onClick: null,
                  });
                  if (result.data.project.status === "종료요청")
                    setWorkEndConfirm_bt(true);
                } else if (iss.isExpert) {
                  if (result.data.project.status === "모집중") {
                    if (iss.isApply) {
                      setButton({
                        isThere: true,
                        linkTo: `/mypage/pro/apply/detail/${iss.mainApplyId}`,
                        className: "default_bt apply_state_view_bt",
                        text: "지원내용보기",
                        onClick: null,
                      });
                    } else {
                      setButton({
                        isThere: true,
                        linkTo: "/recruit/apply/" + id,
                        className: "default_bt apply_bt",
                        text: "지원하기",
                        onClick: null,
                      });
                    }
                  } else setButton({ isThere: false });
                } else {
                  if (result.data.project.status === "모집중") {
                    setButton({
                      isThere: true,
                      linkTo: "#",
                      className: "default_bt apply_bt",
                      text: "지원하기",
                      onClick: () => {
                        if (
                          window.confirm(
                            "전문가 등급에서 지원 가능합니다. 전문가 신청 페이지로 이동하시겠습니까?"
                          )
                        )
                          history.push("/mypage/pro/Register");
                      },
                    });
                  } else setButton({ isThere: false });
                }
              }
            });
        } else if (result.data.result === "일치하는 토큰 정보 없음") {
          // alert("로그인이 필요한 서비스입니다.");
          // history.push("/login");
          setIss({
            isManager: false,
            isExpert: false,
            isOwn: false,
            isApply: false,
            applyType: "none",
            mainApplyId: 0,
            applySubIndex: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, id, token]);

  const getRecentDate = (time) => {
    const date = new Date(time).toISOString().split("T");
    return "`" + date[0].slice(2, date[0].length) + " " + date[1].split(".")[0];
  };

  const convertDataFromView = (view_data) => {
    let returnList = [];
    const keys = view_data[0].map((v) => v.value);
    const values = view_data.slice(1, view_data.length);
    for (let i = 0; i < values.length; i++) {
      let obj = {};
      values[i].map((v, idx) => {
        const key = keys[idx];
        obj[key] = v.value;
        return 0;
      });
      returnList.push(obj);
    }
    return returnList;
  };

  const changeData = (e, index) => {
    setViewData(viewData.map((vd, idx) => (idx === index ? e : vd)));
    setData(data.map((d, idx) => (index === idx ? convertDataFromView(e) : d)));
  };

  const changeProcess = (status) => {
    if (window.confirm(`프로젝트 상태를 "${status}"으로 변경하시겠습니까?`))
      axios
        .post(
          "/rest-api/changeProcess",
          {
            projectId: Number(id),
            status,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            alert(response.data.message);
            projectInfo.status = status;
            setProjectInfo(projectInfo);
          } else alert(response.data.error);
        });
  };

  const viewDatas = (index, view_data) => {
    let tempData = [];

    tempData.push(
      Object.keys(view_data[0]).map((ex) => {
        return { value: ex };
      })
    );
    for (const da of view_data) {
      tempData.push(
        Object.values(da).map((value) => {
          return { value };
        })
      );
    }
    setViewData(viewData.map((vd, idx) => (index === idx ? tempData : vd)));
  };

  const fileRead = (event, index) => {
    let input = event.target;
    let reader = new FileReader();

    reader.onload = () => {
      let fileData = reader.result;
      let workBook = XLSX.read(fileData, { type: "binary" });

      workBook.SheetNames.forEach(function (sheetName) {
        //console.log("SheetName: " + sheetName);
        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
        // console.log("rows: ", rows);
        setAlertFiles(
          alertFiles.map((al, idx) => (index === idx ? input.files[0] : al))
        );
        // setData([
        //   ...data.map((da, idx) => {
        //     const re = idx === index ? rows : da;
        //     console.log(idx, re);
        //     return re;
        //   }),
        // ]);
        const tempData = data.map((da, idx) => (idx === index ? rows : da));
        setData(tempData);
        viewDatas(index, tempData[index]);
        //console.log(JSON.stringify(rows));
      });
    };
    reader.readAsBinaryString(input.files[0]);
  };

  const applyFinal = async () => {
    // 여기서부터 서브 프로젝트 별 선정, 통합지원 기능 추가하기
    //   let checkSelect = subProjects.filter((sub,index)=>sub.status==="선정완료").length===subProjects.length;

    // if(!checkSelect)
    // if (window.confirm("분할 공정을 취합하여 지원하시겠습니까?")) {
    //   const res = await axios("/rest-api/postAdminApply", {
    //     headers: {
    //       Authorization: "Bearer " + token,
    //     },
    //     params: {
    //       projectId: Number(id),
    //     },
    //   });
    //   if (res.status === 200) {
    //     console.log(res.data);
    //   } else alert(res.data.error);
    // }

    history.push(`/recruit/apply/${id}`);
  };

  const sendAlerts = async (index) => {
    try {
      if (
        window.confirm(
          `공정#${index + 1}. ${subProjects[index].category} 건으로 ${
            data[index].length
          }명에게 알림을 발송합니다.`
        )
      ) {
        const res = await axios.post(
          "/rest-api/sendApplyMessage",
          {
            sendList: data[index],
            targetModel: projectType,
            targetId: projectType === "project" ? id : subProjects[index].id,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const changeSubProject = async (spr) => {
    try {
      if (spr.changeStatus === undefined)
        throw Error("변경하려는 상태 값을 선택해주세요.");
      else if (spr.status === spr.changeStatus)
        throw Error("변경하려는 상태가 현재 값과 동일합니다.");
      else if (
        window.confirm(
          `서브프로젝트 [${spr.process}]의 상태를 "${spr.status}"에서 "${spr.changeStatus}"로 변경하시겠습니까?"`
        )
      ) {
        const res = await axios.post(
          "/rest-api/setSubProjectStatus",
          {
            subProjectId: spr.id,
            changeStatus: spr.changeStatus,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log("changestatus res: ", res);
        if (res.status !== 200) throw Error(res.data.error);
        else {
          const resSP = res.data.subProject;
          window.alert(res.data.message);
          setSubProjects(
            subProjects.map((sp, index) => (sp.id === resSP.id ? resSP : sp))
          );
        }
      }
    } catch (error) {
      window.alert(`처리 에러:\n ${error.message}`);
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">모집상세내역</div>
        <div className="recruit_header">
          <div className="rc_title">
            {projectInfo.title}{" "}
            <span className="rc_time">
              <Moment fromNow>{projectInfo.createdAt}</Moment>
            </span>
          </div>
          <div className="rc_option_items">
            <div className="info_item">
              <div className="info_title">작성자</div>
              <div className="info_contents">{creator.name}</div>
            </div>
            <div className="info_item">
              <div className="info_title">업체명</div>
              <div className="info_contents">{projectInfo.companyName}</div>
            </div>
            <div className="info_item">
              <div className="info_title">프로젝트 총 예산</div>
              <div className="info_contents">
                총 {moneyFormat(projectInfo.cost)}만원
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납기일</div>
              <div className="info_contents">{projectInfo.dueDate}</div>
            </div>
            <div className="info_item">
              <div className="info_title">모집마감일</div>
              <div className="info_contents">
                {projectInfo.applyDueDate}({projectInfo.status})
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">모집업무</div>
              <div className="info_contents">
                {projectInfo.mainCategory &&
                  projectInfo.mainCategory.map(
                    (item, index) =>
                      item +
                      (index === projectInfo.mainCategory.length - 1
                        ? ""
                        : ", ")
                  )}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납품지역</div>
              <div className="info_contents">{projectInfo.region}</div>
            </div>
          </div>
          <div className="rc_option_items attachFiles">
            <div className="info_item">
              <div className="info_title">첨부파일</div>
              <div className="info_contents">
                {mainPjFiles.length === 0 && <span>첨부파일이 없습니다.</span>}
                {mainPjFiles.length > 0 &&
                  mainPjFiles.map((file, index) => {
                    return (
                      <span key={index} className="file_item">
                        {index !== 0 && <br />}
                        <a href={file.url} download={file.originalFileName}>
                          {file.originalFileName}
                        </a>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="recruit_summary">
          <div className="info_title">업무개요</div>
          <div className="rcs_contents">
            {projectInfo.content &&
              projectInfo.content.split("\n").map((item, idx) => {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </div>
        </div>
        <div className="recruit_department">
          <div className="info_title">업무분야 상세</div>
          {subProjects.length > 0 &&
            subProjects.map((spr, index) => (
              <div className="rcd_item" key={index}>
                <div className="rcd_title">{spr.category}</div>
                <div className="rcd_contents">
                  <div className="rcd_detail">{spr.process}</div>
                  <div className="rcd_option">
                    금액: {moneyFormat(spr.cost)}만원/ 납기일: {spr.nextDueDate}
                    / 납품지역:{" "}
                    {(spr.isJobEndPoint
                      ? projectInfo.region
                      : spr.nextRegion.region
                    ).replace("서울특별시", "서울")}
                  </div>
                  <div className="rcd_state">
                    {spr.status}(지원자 {spr.applicant}명)
                  </div>
                  {iss.isManager === true && (
                    <div className="rcd_admin">
                      <button
                        className={
                          "default_bt status_bt " +
                          (spr.detailPop ? "active" : "")
                        }
                        onClick={() => {
                          setSubProjects(
                            subProjects.map((sp, sIndex) =>
                              sIndex !== index
                                ? sp
                                : {
                                    ...sp,
                                    detailPop: !sp.detailPop,
                                  }
                            )
                          );
                        }}
                      >
                        {spr.detailPop ? "닫기" : "공정관리"}
                      </button>
                    </div>
                  )}
                </div>
                {spr.detailPop && (
                  // (() => {
                  //   const expertInfo =
                  //     spr.userId === null ? spr.coExInfo : spr.user.expertInfo;
                  //   console.log(expertInfo);
                  //   return
                  <div className="rcd_subcontents">
                    <div className="rcd_section">
                      <div className="info_title admin_case">
                        <div className="title">지원업체목록</div>
                        <div className="alert_bt">
                          <button
                            className={
                              "default_bt status_bt alert_bt " +
                              (spr.alertPop ? "active" : "")
                            }
                            onClick={() => {
                              setSubProjects(
                                subProjects.map((sp, sIndex) =>
                                  sIndex !== index
                                    ? sp
                                    : {
                                        ...sp,
                                        alertPop: !sp.alertPop,
                                      }
                                )
                              );
                            }}
                          >
                            {spr.alertPop ? "알림닫기" : "알림관리"}
                          </button>
                          <button
                            className={
                              "default_bt status_bt " +
                              (spr.statusPop ? "active" : "")
                            }
                            onClick={() => {
                              setSubProjects(
                                subProjects.map((sp, sIndex) =>
                                  sIndex !== index
                                    ? sp
                                    : {
                                        ...sp,
                                        statusPop: !sp.statusPop,
                                      }
                                )
                              );
                            }}
                          >
                            {spr.statusPop ? "변경닫기" : "상태변경"}
                          </button>
                        </div>
                      </div>
                      {spr.statusPop && (
                        <div className="status_change">
                          <div className="current_status">
                            {`공정#${index + 1}(${spr.process})의 현 상태를 "${
                              spr.status
                            }"에서 => `}
                          </div>
                          <div className="change_status">
                            <select
                              onChange={(e) => {
                                setSubProjects(
                                  subProjects.map((sp, sIndex) =>
                                    sIndex !== index
                                      ? sp
                                      : {
                                          ...sp,
                                          changeStatus: e.target.value,
                                        }
                                  )
                                );
                              }}
                            >
                              <option value="상태선택">{">상태선택<"}</option>
                              {subProjectStatus.map((status, status_index) => (
                                <option key={status_index} value={status}>
                                  {status}
                                </option>
                              ))}
                            </select>
                            {" 으로 변경합니다."}
                          </div>
                          <button
                            className="default_bt change_bt"
                            onClick={() => {
                              changeSubProject(spr);
                            }}
                          >
                            상태적용
                          </button>
                        </div>
                      )}
                      {spr.alertPop && (
                        <div className="alert_manage">
                          <div className="am_item">
                            <div className="am_title">알림관리</div>
                            <div className="input_item">
                              <div className="input_title">
                                알림 발송 횟수:{" "}
                              </div>
                              <div className="input_contents">
                                {spr.alertIndex + "회"}
                              </div>
                            </div>
                            <div className="input_item">
                              <div className="input_title">최근 발송: </div>
                              <div className="input_contents">
                                {spr.alerts.length > 0
                                  ? getRecentDate(spr.alerts[0].createdAt)
                                  : "발송이력없음"}
                              </div>
                            </div>
                            <div className="input_item">
                              <div className="input_title">
                                총 발송 메세지:{" "}
                              </div>
                              <div className="input_contents">
                                {"합 " + spr.alerts.length + "건"}
                              </div>
                            </div>
                            <div className="input_item file_item">
                              <div className={"input_title"}>알림발송</div>
                              <div className="input_contents">
                                <div className="file_input">
                                  <label>
                                    <input
                                      type="file"
                                      onChange={(e) => {
                                        fileRead(e, index);
                                      }}
                                      id={`alertFile${index}`}
                                    />
                                    <div className="file_text">파일선택</div>
                                  </label>
                                  <button
                                    className="file_bt"
                                    onClick={() => {
                                      sendAlerts(index);
                                    }}
                                  >
                                    보내기
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="am_item sheet_part">
                            <div className="am_title">시트확인</div>
                            <div className="input_item spread_sheet">
                              <Spreadsheet
                                data={viewData[index]}
                                onChange={(e) => changeData(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="apply_items">
                        {spr.applies.length === 0 && (
                          <div className="info_desc">
                            😓 분할 공정 지원자가 없습니다.
                          </div>
                        )}
                        {spr.applies.length > 0 &&
                          spr.applies.map((ap, ap_index) => {
                            const expertInfo =
                              ap.userId === null
                                ? ap.coExInfo
                                : ap.user.expertInfo;
                            let region = "";
                            if (ap.userId === null)
                              expertInfo.address
                                .split(" ")
                                .slice(0, 2)
                                .map(
                                  (ad, index) =>
                                    (region =
                                      index === 0 ? ad : region + " " + ad)
                                );
                            else region = "지역확인요망";
                            return (
                              <div className="apply_item" key={ap_index}>
                                <div className="ap_contents">
                                  {/* {ap.userId === null
                                    ? `${expertInfo.companyName}/ ${expertInfo.equipment}/ ${expertInfo.category}/ ${region}`
                                    : `${expertInfo.companyName}/ ${expertInfo.category}/ ${region}`}
                                  <br /> */}
                                  <div className="ap_short">
                                    {`${expertInfo.companyName}/ ${region}/ `}
                                    {`금액 : ${ap.offerCost}만원(${
                                      ap.offerCost <= spr.offerCost
                                        ? "예산이내"
                                        : "예산초과"
                                    })/ 출고일 :
                                    ${ap.dueDate}(${
                                      new Date(ap.dueDate) -
                                        new Date(spr.nextDueDate) <=
                                      0
                                        ? "일정이내"
                                        : "일정초과"
                                    })`}
                                  </div>
                                  {ap.alertPop && (
                                    <div className="ap_detail">
                                      * 사업자형태:{" "}
                                      {ap.userId === null
                                        ? expertInfo.businessType
                                        : expertInfo.Ctype}
                                      {"/ "}* 연락처:{" "}
                                      {ap.userId !== null
                                        ? ap.user.phone + `(${ap.user.name})`
                                        : expertInfo.alertNumber}
                                      <br />* 주소정보: {expertInfo.address}
                                      <br />* 수행업무: {expertInfo.category}
                                      <br />*{" "}
                                      {ap.userId === null
                                        ? "보유장비"
                                        : "업체구분"}
                                      :{" "}
                                      {ap.userId === null
                                        ? expertInfo.equipment
                                        : expertInfo.businessStatus}
                                      <br />* 참조정보:{" "}
                                      {ap.userId === null
                                        ? expertInfo.companyInfo
                                        : expertInfo.companyInfoDetail}
                                    </div>
                                  )}
                                </div>
                                <div className="ap_bt_section">
                                  <button
                                    className={
                                      "default_bt view_bt " +
                                      (ap.alertPop ? "active" : "")
                                    }
                                    onClick={() => {
                                      setSubProjects(
                                        subProjects.map((sp, sIndex) => {
                                          if (sIndex !== index) return sp;
                                          else {
                                            sp.applies = sp.applies.map(
                                              (spap, spap_index) =>
                                                spap_index !== ap_index
                                                  ? spap
                                                  : {
                                                      ...spap,
                                                      alertPop: !spap.alertPop,
                                                    }
                                            );
                                            return sp;
                                          }
                                        })
                                      );
                                    }}
                                  >
                                    {ap.alertPop ? "닫기" : "정보보기"}
                                  </button>
                                  <button className="default_bt select_bt">
                                    선정
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          <div className="apply_section">
            <div className="info_desc">
              {subProjects.length === 0
                ? "* 해당 프로젝트는 하위 업무 분할 요청이 없어 단일 업무로 처리됩니다."
                : "* 업무별 각각 개별 지원도 가능합니다.(지원신청페이지 안에서 선택가능)"}
            </div>

            <button className={"default_bt apply_bt"} onClick={applyFinal}>
              통합지원
            </button>

            <button
              className={"default_bt prev_bt"}
              onClick={() => {
                history.goBack();
              }}
            >
              뒤로가기
            </button>
            {iss.isManager === true && (
              <div className="recruit_admin_section">
                <div className="info_title">상태변경</div>
                <div className="rcs_contents manager_status">
                  <ul>
                    {status.map((item, index) => {
                      return (
                        <li key={index}>
                          <button
                            className="default_bt status_bt"
                            onClick={() => {
                              changeProcess(item);
                            }}
                          >
                            {item}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminRecruitDetail;
