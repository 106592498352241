import React from "react";
import MySideMenu from "../../component/mySideMenu";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from "axios";

function ChangePassword(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const token = localStorage.getItem("token");
  const history = useHistory();

  const changePasswordSubmit = (data) => {
    axios
      .post(
        "/rest-api/auth/changePassword",
        {
          password: watch("oldPassword"),
          changePassword: watch("passwordCheck"),
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          alert("비밀번호 변경이 완료되었습니다.");
          history.push("/mypage/changePassword");
        } else {
          alert(response.data.error);
        }
      });
  };

  const cancelChangePassword = (e) => {
    console.log("Event: ", e);
    if (window.confirm("비밀번호 변경을 취소하시겠습니까?"))
      history.push("/mypage/account");
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents my_account_page">
          <form onSubmit={handleSubmit(changePasswordSubmit)}>
            <div className="page_title">비밀번호 변경</div>
            <div className="input_item">
              <div className="input_title">비밀번호</div>
              <div className="input_contents">
                {
                  <div className={"change_password_section"}>
                    <input
                      type={"password"}
                      name={"oldPassword"}
                      placeholder={"현재 비밀번호"}
                      {...register("oldPassword", {
                        required: "비밀번호를 입력해주세요",
                        pattern: {
                          value: /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{0,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/,
                          message:
                            "비밀번호는 8자이상(숫자,문자포함) 입력하셔야합니다",
                        },
                      })}
                    />
                    {errors?.oldPassword && (
                      <div className={"error_msg"}>
                        {errors?.oldPassword?.message}
                      </div>
                    )}
                    <input
                      type={"password"}
                      name={"password"}
                      placeholder={"변경될 비밀번호"}
                      {...register("password", {
                        required: "비밀번호를 입력해주세요",
                        pattern: {
                          value: /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{0,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/,
                          message:
                            "비밀번호는 8자이상(숫자,문자포함) 입력하셔야합니다",
                        },
                      })}
                    />
                    {errors?.password && (
                      <div className={"error_msg"}>
                        {errors?.password?.message}
                      </div>
                    )}
                    <input
                      type={"password"}
                      name={"passwordCheck"}
                      placeholder={"변경될 비밀번호 확인"}
                      {...register("passwordCheck", {
                        validate: (value) =>
                          value === watch("password") ||
                          "비밀번호가 일치하지 않습니다",
                      })}
                    />
                    {errors?.passwordCheck && (
                      <div className={"error_msg"}>
                        {errors?.passwordCheck?.message}
                      </div>
                    )}
                    <div className={"modify_bt_section"}>
                      <button type={"submit"} className="default_bt submit_bt">
                        변경하기
                      </button>
                      <button
                        type={"button"}
                        className="default_bt modify_bt"
                        onClick={cancelChangePassword}
                      >
                        변경취소
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ChangePassword;
