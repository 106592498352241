import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

function PasswordChange(props) {
  const { url } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  //const dispatch = useDispatch();
  const history = useHistory();

  const pwSubmit = async (data) => {
    console.log(url);
    axios
      .post("/rest-api/setNewPassword", {
        url,
        password: watch("passwordCheck"),
      })
      .then(function (res) {
        if (res.status === 200) {
          alert(res.data.message);
          history.push("/login");
        } else alert(res.data.error);
      });
  };
  return (
    <div className="login_page">
      <div className="login_section">
        <div className="logo_section">
          <a href={"/"}>
            <img src="/img/logo.svg" alt="logo.svg" />
          </a>
        </div>
        <div className="lg_title">비밀번호 재설정</div>
        <div className="lg_input_section">
          <form onSubmit={handleSubmit(pwSubmit)}>
            <div className="input_item">
              <input
                type="password"
                name={"password"}
                placeholder="신규 비밀번호"
                {...register("password", {
                  required: "비밀번호를 입력해주세요",
                  pattern: {
                    value: /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{0,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/,
                    message:
                      "비밀번호는 8자이상(숫자,문자포함) 입력하셔야합니다",
                  },
                })}
              />
              {errors?.password && (
                <div className={"error_msg"}>{errors?.password?.message}</div>
              )}
            </div>
            <div className="input_item">
              <input
                type="password"
                placeholder="신규 비밀번호 확인"
                name={"passwordCheck"}
                {...register("passwordCheck", {
                  validate: (value) =>
                    value === watch("password") ||
                    "비밀번호가 일치하지 않습니다",
                })}
              />
              {errors?.passwordCheck && (
                <div className={"error_msg"}>
                  {errors?.passwordCheck?.message}
                </div>
              )}
            </div>
            <div className="input_item">
              <button className="default_bt login_bt" type={"submit"}>
                변경하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default PasswordChange;
