import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import FileInput from "../../component/fileInput";

function PortfolioAdd(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles([result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };
  const pfAddSubmit = (data) => {
    data.files = files;
    axios
      .post("/rest-api/addPortFolio", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          history.push("/mypage/pro");
        } else alert(res.data.error);
      });
  };
  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <div className="page_title">포트폴리오 추가</div>
          <form onSubmit={handleSubmit(pfAddSubmit)}>
            <div className="input_item">
              <div className="input_title">제목</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="포트폴리오 제목"
                  name={"title"}
                  {...register("title", {
                    required: "제목을 입력해 주세요",
                  })}
                />
                <div className={"error_msg"}>
                  {errors?.title && errors?.title?.message}
                </div>
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">수행일자</div>
              <div className="input_contents">
                <input
                  className="date_select"
                  type="text"
                  placeholder="수행일자"
                  name={"date"}
                  {...register("date", {
                    required: "수행 일자를 입력해주세요",
                  })}
                  defaultValue={new Date()
                    .toLocaleString("ko-KR", {
                      timeZone: "Asia/Seoul",
                    })
                    .split(". 오")[0]
                    .replaceAll(". ", "-")}
                />
                <div className={"error_msg"}>
                  {errors?.date && errors?.date?.message}
                </div>
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">수행분야</div>
              <div className="input_contents">
                <select name={"category"} {...register("category")}>
                  <option value={"디자인"}>디자인</option>
                  <option value={"인쇄제작"}>인쇄제작</option>
                  <option value={"실사제작"}>실사제작</option>
                  <option value={"기타제작"}>기타제작</option>
                  <option value={"기타"}>기타</option>
                </select>
              </div>
            </div>
            <div className="input_item wide_item">
              <div className="input_title">한줄설명</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="간략하게 프로젝트에 대해 소개해주세요"
                  name={"content"}
                  {...register("content")}
                />
              </div>
            </div>
            <div className="input_item">
              <div className="input_contents">
                <FileInput
                  titleYN={true}
                  files={files}
                  fileChange={fileChange}
                  fileX={fileX}
                  singleMode={true}
                />
              </div>
            </div>
            {/* <div className="portfolio_images">
              <div className="pp_item">
                <div className="x_bt">
                  <img src="/img/ic_x_bt.svg" />
                </div>
                <div className="pp_img">
                  <img src="/img/temp_thum2.jpg" />
                </div>
              </div>
              <div className="pp_item">
                <div className="x_bt">
                  <img src="/img/ic_x_bt.svg" />
                </div>
                <div className="pp_img">
                  <img src="/img/ic_file.svg" className="default_file_img" />
                </div>
              </div>
              <div className="pp_item">
                <div className="x_bt">
                  <img src="/img/ic_x_bt.svg" />
                </div>
                <div className="pp_img">
                  <img src="/img/ic_file.svg" className="default_file_img" />
                </div>
              </div>
              <div className="pp_item">
                <div className="x_bt">
                  <img src="/img/ic_x_bt.svg" />
                </div>
                <div className="pp_img">
                  <img src="/img/ic_file.svg" className="default_file_img" />
                </div>
              </div>
              <div className="pp_item">
                <div className="x_bt">
                  <img src="/img/ic_x_bt.svg" />
                </div>
                <div className="pp_img">
                  <img src="/img/ic_file.svg" className="default_file_img" />
                </div>
              </div>
            </div> */}

            <div className="bottom_bt_section multi_bt">
              <button className="default_bt submit_bt">작성완료</button>
              <Link to={"/mypage/pro/"}>
                <button className="default_bt cancel_bt">작성취소</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default PortfolioAdd;
