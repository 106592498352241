import React, { useEffect } from "react";
import queryString from "query-string";

function ErrorPage(props) {
  const { type, message, redirectUrl } = queryString.parse(
    props.location.search
  );
  useEffect(() => {
    if (message) alert(message);
  });

  return (
    <div className="error_page">
      <div class="error_section">
        <div class="er_header">
          <img src="./img/logo_w.svg" alt="logo_w.svg" />
        </div>
        <div class="er_contents er_join">
          <div class="er_title">페이지 에러</div>
          <div class="er_bt_section">
            - 가이트 링크:{" "}
            <a href={redirectUrl}>여기를 클릭해 다시 시스템을 이용해주세요.</a>
          </div>
          <div class="er_subtitle">- 오류 메세지: {message}</div>
          <div class="er_div_line"></div>

          <div class="er_sub_desc">
            {type}, 위 링크를 통해 다시 접근해주세요.
          </div>
        </div>
      </div>
    </div>
  );
}
export default ErrorPage;
