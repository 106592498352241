import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Notice(props) {
  const token = localStorage.getItem("token");
  const [noticeList, setNoticeList] = useState([]);
  const [noticeOpen, setNoticeOpen] = useState(0);
  const [pageStatus, setPageStatus] = useState("success");
  const userInfo = useSelector((state) => state.userInfo);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getNotice", {
        params: {
          amount: 200,
          index: 1,
        },
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) setNoticeList(response.data.notices);
        else {
          const totalNotices = parseInt(
            response.data.error.split("totalNotices:")[1].split(")")[0]
          );
          if (totalNotices === 0) setPageStatus("noData");
          else setPageStatus("seachError");
        }
      });
  }, []);

  const bdOpen = (index) => {
    if (noticeOpen === index) setNoticeOpen(-1);
    else setNoticeOpen(index);
  };

  const editNotice = (editType, oldNotice) => {
    switch (editType) {
      case "new":
        if (window.confirm("신규 공지사항을 작성하시겠습니까?"))
          history.push(`/notice/edit/${editType}/0`);
        break;
      case "modify":
        if (window.confirm("해당 공지사항은 수정하시겠습니까?"))
          history.push(`/notice/edit/${editType}/${oldNotice.id}`, {
            oldNotice,
          });
        break;
      case "delete":
        if (window.confirm("해당 공지사항은 삭제하시겠습니까?")) {
          axios
            .post(
              "/rest-api/editNotice",
              {
                editType,
                noticeId: oldNotice.id,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                let newList = noticeList;
                alert(response.data.message);
                newList.map((no, index) => {
                  if (no.id === oldNotice.id) newList.splice(index, 1);
                  return 0;
                });
                setNoticeList([...newList]);
              } else alert(response.data.error);
            });
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">공지사항</div>
        <div className={"board_items"}>
          {pageStatus === "seachError" && (
            <div className={"bd_header"}>데이터 불러오기에 실패하였습니다.</div>
          )}
          {pageStatus === "noData" && (
            <div className={"bd_header"}>공지사항 데이터가 없습니다.</div>
          )}
          {pageStatus === "success" &&
            noticeList.map((bdItem, index) => (
              <div key={index} className={"bd_item"}>
                <div
                  className={"bd_header"}
                  onClick={() => {
                    bdOpen(index);
                  }}
                >
                  <div className={"bd_num"}>{index + 1}</div>
                  <div className={"bd_title"}>{bdItem.title}</div>
                  <div className={"bd_time"}>
                    <Moment format={"YYYY. MM. DD"} locale="ko">
                      {bdItem.createdAt}
                    </Moment>
                  </div>
                  <div
                    className={
                      "bd_arrow" + (noticeOpen === index ? " active" : "")
                    }
                  >
                    <img src={"/img/ic_tri.svg"} alt="ic_tri.svg" />
                  </div>
                </div>
                {index === noticeOpen && (
                  <div className={"bd_contents"}>
                    {bdItem.files.length !== 0 && (
                      <div className="input_item">
                        <div className="input_title">첨부파일</div>
                        <div
                          className="input_contents"
                          style={{ paddingTop: 10 + "px" }}
                        >
                          {bdItem.files.map((item, index) => {
                            return (
                              <span key={index}>
                                {index !== 0 && ", "}
                                <a href={item.url} download>
                                  {item.originalFileName}
                                </a>
                              </span>
                            );
                          })}
                          {/* {bdItem.files.length === 0 && (
                        <span style={{ color: "#898989" }}>
                          첨부파일이 없습니다.
                        </span>
                      )} */}
                        </div>
                      </div>
                    )}
                    {bdItem.content.split("\n").map((line, i) => {
                      return (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                    {userInfo.userLevel === "manager" && (
                      <div className="pp_manage_bt_section">
                        <span
                          className="pp_modify_bt"
                          onClick={() => {
                            editNotice("modify", bdItem);
                          }}
                        >
                          수정
                        </span>
                        <span
                          className="pp_modify_bt"
                          onClick={() => {
                            editNotice("delete", bdItem);
                          }}
                        >
                          삭제
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>

        {userInfo.userLevel === "manager" && (
          <button
            className="noticeAdd_bt"
            onClick={() => {
              editNotice("new");
            }}
          >
            신규작성
          </button>
        )}
      </div>
    </div>
  );
}
export default Notice;
