import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import { Link } from "react-router-dom";
import axios from "axios";

function ProManage(props) {
  const token = localStorage.getItem("token");
  const [exp, setExp] = useState({});
  const [pfList, setPfList] = useState([]);

  useEffect((prop) => {
    axios
      .get("/rest-api/getEXPandPF", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (res) {
        //console.log(res.data);
        if (res.status === 200) {
          setPageData(res);
        } else alert(res.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPageData = (res) => {
    setExp(res.data.exp);
    setPfList([...res.data.portfolios]);
  };

  const pfDelete = (pf) => {
    if (!pf) return 0;
    else if (window.confirm("해당 포트폴리오를 삭제하시겠습니까?")) {
      axios
        .get("/rest-api/deletePortfolio", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            pfId: pf.id,
          },
        })
        .then(function (res) {
          if (res.status === 200) {
            alert(res.data.message);
            let templist = pfList;
            pfList.map((item, index) => {
              if (item.id === pf.id) templist.splice(index, 1);
              return 0;
            });
            setPfList([...templist]);
          } else alert(res.data.error);
        });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <div className="page_title">전문가 정보관리</div>
          <div className="pro_manage_info">
            <div className="pm_div">
              <div className="info_item">
                <div className="info_title">기업형태</div>
                <div className="info_contents">{exp.Ctype}</div>
              </div>
              <div className="info_item">
                <div className="info_title">은행명</div>
                <div className="info_contents">{exp.bankName}</div>
              </div>
              <div className="info_item">
                <div className="info_title">회사이름</div>
                <div className="info_contents">{exp.companyName}</div>
              </div>
            </div>
            <div className="pm_div">
              <div className="info_item">
                <div className="info_title">계좌번호</div>
                <div className="info_contents">{exp.acountNumber}</div>
              </div>
              <div className="info_item">
                <div className="info_title">전문분야</div>
                <div className="info_contents">{exp.mainCategory}</div>
              </div>
              <div className="info_item">
                <div className="info_title">예금주</div>
                <div className="info_contents">{exp.acountOwner}</div>
              </div>
            </div>
          </div>
          <div className="info_item full_item">
            <div className="info_title">회사소개</div>
            <div className="info_contents">
              {String(exp.companyInfoDetail)
                .split("\n")
                .map((item, index) => {
                  return (
                    <span key={index}>
                      {index === 0 && <br />}
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
          <Link to={"/mypage/pro/modify"}>
            <button className="default_bt full_modify_bt">
              전문가소개 수정
            </button>
          </Link>
          <div className="info_title section2_title">포트폴리오</div>
          <div className="portfolio_items">
            {pfList.length === 0 && (
              <div
                className={"no_recruit_contents"}
                style={{ paddingTop: 20 + "px", paddingBottom: 20 + "px" }}
              >
                등록된 포트폴리오가 없습니다. 아래 추가하기 버튼으로
                포트폴리오를 등록해주세요.
              </div>
            )}
            {pfList.length >= 0 &&
              pfList.map((pf, index) => {
                const imgTypes = ["png", "jpg", "jpge"];
                let temp = null;
                let src = "/img/ic_file.svg";
                if (pf.file.length > 0) {
                  temp = pf.file[0].originalFileName.split(".");
                  if (imgTypes.indexOf(temp[temp.length - 1]) >= 0)
                    src = pf.file[0].url;
                } // s3 버킷 링크 보안 추가해서 엑박 해결하기
                return (
                  <div className="pp_item" key={index}>
                    <div className="pp_img">
                      <img
                        src={src}
                        className="default_file_img"
                        style={{ maxHeight: 90 + "%", maxWidth: 90 + "%" }}
                        alt={"WNL PFno." + pf.id + "_attachFile"}
                      />
                    </div>
                    <div className="pp_contents">
                      <div className="pp_title">{pf.title}</div>
                      <div className="pp_subinfo">
                        {pf.category}/{pf.date}
                      </div>
                      <div className="pp_desc">
                        {pf.description.length > 75
                          ? pf.description.slice(0, 75)
                          : pf.description}
                      </div>
                      <div className="pp_manage_bt_section">
                        {pf.file.length > 0 && (
                          <a
                            className="pp_modify_bt"
                            href={pf.file[0].url}
                            download={pf.file[0].originalFileName}
                            title={"WNL PFno." + pf.id + "_attachFile"}
                          >
                            파일다운
                          </a>
                        )}
                        <span
                          className="pp_modify_bt"
                          onClick={() => {
                            pfDelete(pf);
                          }}
                        >
                          삭제
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <Link to={"/mypage/pro/PortFolioAdd"}>
            <button className="default_bt full_modify_bt">
              포트폴리오 추가하기
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default ProManage;
