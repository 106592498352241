import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import XLSX from "xlsx";

function FAQ(props) {
  const token = localStorage.getItem("token");
  const userInfo = useSelector((state) => state.userInfo);
  const [faqList, setFaqList] = useState([]);
  const [faqOpen, setFaqOpen] = useState(-1);
  const [cateList, setCateList] = useState([]);
  const [currentCate, setCurrentCate] = useState("");
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getFAQ", {
        params: {
          amount: 200,
        },
      })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          setFaqList(response.data.faqs);
          setCateList(
            response.data.faqs.reduce((acc, fa) => {
              fa.categoryJSON.map((cate) => {
                if (acc.indexOf(cate) < 0) acc.push(cate);
                return 0;
              });
              return acc;
            }, [])
          );
        } else {
          console.log(response.data.error);
        }
      });
  }, []);

  const writeExcel = async (data, fileName) => {
    try {
      /* convert AOA back to worksheet */
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      /* build new workbook */
      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, fileName);

      /* write file */
      XLSX.writeFile(new_workbook, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const downOldFAQ = () => {
    if (window.confirm("현재 FAQ 리스트를 파일로 다운로드 하시겠습니까?")) {
      const fileName = `${new Date()
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "")}_FAQList.xlsx`;
      let list = [];
      list.push(Object.keys(faqList[0]));
      for (const faq of faqList) {
        let item = faq;
        item.categoryJSON = JSON.stringify(faq.categoryJSON);
        list.push(Object.values(item));
      }
      writeExcel(list, fileName);
    }
  };

  const cateSelect = (e) => {
    setCurrentCate(e.target.value);
  };

  const bdOpen = (index) => {
    if (faqOpen === index) setFaqOpen(-1);
    else setFaqOpen(index);
  };

  const editFaq = (editType, oldFaq) => {
    switch (editType) {
      case "all":
        if (window.confirm("파일로 FAQ를 수정하시겠습니까?"))
          history.push(`/faq/edit/fileUpload`);
        break;
      case "new":
        if (window.confirm("신규 FAQ을 작성하시겠습니까?"))
          history.push(`/faq/edit/${editType}/0`);
        break;
      case "modify":
        if (window.confirm("해당 FAQ은 수정하시겠습니까?"))
          history.push(`/faq/edit/${editType}/${oldFaq.id}`, {
            oldFaq,
          });
        break;
      case "delete":
        if (window.confirm("해당 FAQ은 삭제하시겠습니까?")) {
          axios
            .post(
              "/rest-api/editFaq",
              {
                editType,
                FaqId: oldFaq.id,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                let newList = faqList;
                alert(response.data.message);
                newList.map((no, index) => {
                  if (no.id === oldFaq.id) newList.splice(index, 1);
                  return 0;
                });
                setFaqList([...newList]);
              } else alert(response.data.error);
            });
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">
          FAQ
          <div className={"cate_select"}>
            <select
              onChange={(e) => {
                cateSelect(e);
              }}
            >
              <option value={""}>전체</option>
              {cateList.map((cate, index) => (
                <option key={index} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={"board_items"}>
          {faqList.map((bdItem, index) => (
            <div key={index} className={"bd_item"}>
              {(currentCate === "" ||
                bdItem.category.indexOf(currentCate) >= 0) && (
                <div>
                  <div
                    className={"bd_header"}
                    onClick={() => {
                      bdOpen(index);
                    }}
                  >
                    <div className={"bd_num"}>Q.{index + 1}</div>
                    <div className={"bd_title"}>{bdItem.title}</div>
                    <div className={"bd_cate"}>
                      {bdItem.categoryJSON &&
                        bdItem.categoryJSON.map((cate, i) => (
                          <span key={i}>{cate} </span>
                        ))}
                    </div>
                    <div
                      className={
                        "bd_arrow" + (faqOpen === index ? " active" : "")
                      }
                    >
                      <img src={"/img/ic_tri.svg"} alt="ic_tri.svg" />
                    </div>
                  </div>
                  {index === faqOpen && (
                    <div className={"bd_contents"}>
                      <div className={"bd_answer_deco"}>
                        <div className="bd_manage_bt_section text">A</div>
                        {userInfo.userLevel === "manager" && (
                          <div className="bd_manage_bt_section bts">
                            <span
                              className="bd_modify_bt"
                              onClick={() => {
                                editFaq("modify", bdItem);
                              }}
                            >
                              수정
                            </span>
                            <span
                              className="bd_modify_bt"
                              onClick={() => {
                                editFaq("delete", bdItem);
                              }}
                            >
                              삭제
                            </span>
                          </div>
                        )}
                      </div>
                      {bdItem.content.split("\n").map((line, i) => {
                        return (
                          <span key={i}>
                            {line}
                            <br />
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        {userInfo.userLevel === "manager" && (
          <div
            className="bottom_bt_section multi_bt"
            style={{ paddingTop: "20px" }}
          >
            <button
              className="default_bt submit_bt"
              type={"button"}
              onClick={() => {
                editFaq("new");
              }}
            >
              신규작성
            </button>
            <button
              className="default_bt next_bt"
              type={"button"}
              onClick={() => {
                editFaq("all");
              }}
            >
              파일업로드
            </button>
            <button
              className="default_bt prev_bt"
              type={"button"}
              onClick={downOldFAQ}
            >
              엑셀다운
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default FAQ;
