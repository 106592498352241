import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";
import { moneyFormat } from "../../utils/commonApi";
import FileInput from "../../component/fileInput";

function ReviewDetail(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [iss, setIss] = useState({});
  const history = useHistory();

  useEffect(() => {
    // axios
    //   .get("/rest-api/getProject", {
    //     headers: {
    //       Authorization: "Bearer " + token,
    //     },
    //     params: {
    //       id: Number(id),
    //     },
    //   })
    //   .then(function (result) {
    //     if (result.status === 200) {
    //       //console.log(result.data);
    //       setProjectInfo(result.data.project);
    //       setMainPjFiles([...result.data.project.file]);
    //       //setSubProjects(result.data.subProjects);
    //       setSubProjects([{
    //         mainCategory:["디자인"],
    //         category:[{name:"이름"}],
    //         title:"매장비치용",
    //         cost:"40",
    //         nextDueDate:2021-21-21,
    //         nextRegion:{address:"서울 중구"},
    //         status:"모집중",
    //         applicant:2,
    //         detailPop:false // 추가되야 하는 항목
    //       }])
    //       setCreator(result.data.project.user);
    //       axios
    //         .get("/rest-api/getWhetherIs", {
    //           headers: {
    //             Authorization: "Bearer " + token,
    //           },
    //           params: {
    //             projectId: Number(id),
    //           },
    //         })
    //         .then((response) => {
    //           if (response.status !== 200) setButton({ isThere: false });
    //           else {
    //             const iss = response.data;
    //             setIss(iss);
    //             if (iss.isOwn) {
    //               setButton({
    //                 isThere: true,
    //                 linkTo: "/mypage/project/apply",
    //                 className: "default_bt apply_state_view_bt",
    //                 text: "지원업체보기",
    //                 onClick: null,
    //               });
    //               if (result.data.project.status === "종료요청")
    //                 setWorkEndConfirm_bt(true);
    //             } else if (iss.isExpert) {
    //               if (result.data.project.status === "모집중") {
    //                 if (iss.isApply) {
    //                   setButton({
    //                     isThere: true,
    //                     linkTo: `/mypage/pro/apply/detail/${iss.mainApplyId}`,
    //                     className: "default_bt apply_state_view_bt",
    //                     text: "지원내용보기",
    //                     onClick: null,
    //                   });
    //                 } else {
    //                   setButton({
    //                     isThere: true,
    //                     linkTo: "/recruit/apply/" + id,
    //                     className: "default_bt apply_bt",
    //                     text: "지원하기",
    //                     onClick: null,
    //                   });
    //                 }
    //               } else setButton({ isThere: false });
    //             } else {
    //               if (result.data.project.status === "모집중") {
    //                 setButton({
    //                   isThere: true,
    //                   linkTo: "#",
    //                   className: "default_bt apply_bt",
    //                   text: "지원하기",
    //                   onClick: () => {
    //                     if (
    //                       window.confirm(
    //                         "전문가 등급에서 지원 가능합니다. 전문가 신청 페이지로 이동하시겠습니까?"
    //                       )
    //                     )
    //                       history.push("/mypage/pro/Register");
    //                   },
    //                 });
    //               } else setButton({ isThere: false });
    //             }
    //           }
    //         });
    //     } else if (result.data.result === "일치하는 토큰 정보 없음") {
    //       // alert("로그인이 필요한 서비스입니다.");
    //       // history.push("/login");
    //       setIss({
    //         isManager: false,
    //         isExpert: false,
    //         isOwn: false,
    //         isApply: false,
    //         applyType: 'none',
    //         mainApplyId: 0,
    //         applySubIndex: [],
    //       });
    //     }
    //   });
  }, []);

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">리뷰 상세</div>
        <div className="review_header">
          <div className="rv_title">
            조성주
            <span className="rv_time">
              <Moment fromNow>2021-12-12</Moment>
            </span>
          </div>
          <div className="rv_option_items">
            <div className="info_item">
              <div className="info_title">작성자</div>
              <div className="info_contents">엄태성</div>
            </div>
            <div className="info_item">
              <div className="info_title">업체명</div>
              <div className="info_contents">비공개</div>
            </div>
            <div className="info_item">
              <div className="info_title">프로젝트 총 예산</div>
              <div className="info_contents">총 500만원</div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납기일</div>
              <div className="info_contents">2021-12-22</div>
            </div>
            <div className="info_item">
              <div className="info_title">모집마감일</div>
              <div className="info_contents">2021-12-22</div>
            </div>
            <div className="info_item">
              <div className="info_title">모집업무</div>
              <div className="info_contents">인쇄제작</div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납품지역</div>
              <div className="info_contents">경기도 화성시</div>
            </div>
          </div>
          <div className="rv_option_items attachFiles">
            <div className="info_item">
              <div className="info_title">첨부파일</div>
              <div className="info_contents">
                <span>첨부파일이 없습니다.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="review_contents">
          <div className="rv_star_items">
            <span className="star_item">
              <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
            </span>
            <span className="star_item">
              <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
            </span>
            <span className="star_item">
              <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
            </span>
            <span className="star_item">
              <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
            </span>
            <span className="star_item">
              <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
            </span>
          </div>
          와이낫링크 전담매니저님 통해서 쉽고 편리하게 이용했습니다.
          <br />
          상새 내역
          <br />
          <br />
          이미지
        </div>
      </div>
    </div>
  );
}
export default ReviewDetail;
