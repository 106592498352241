import React, { useState } from "react";
import MySideMenu from "../../component/mySideMenu";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as baseActions from "../../store/base";

function DeleteAccount(props) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [isDeleteComplete, setIsDeleteComplete] = useState(false);

  const logout = (e) => {
    dispatch(baseActions.logout());
  };

  const deleteAccountRequest = () => {
    axios
      .post(
        "/rest-api/auth/deleteAccountRequest",
        {
          password: watch("oldPassword"),
          //changePassword: watch("passwordCheck"),
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          alert("회원탈퇴 요청이 완료되었습니다.");
          logout();
          history.push("/mypage/account");
        } else {
          alert(response.data.error);
        }
      });
  };

  return (
    <div className="contents_wrap">
      {isDeleteComplete === true && (
        <div className={"div_page"}>
          <div className="page_title">회원 탈퇴 요청 완료</div>
          <div className={"page_contents"}>
            <div className={"regi_ing"}>
              회원정보 삭제 요청이 완료되었습니다.
              <div className={"info_desc"}>
                회원 정보 삭제 업무는 영업일 기준 1일 정도 소요됩니다.
                <br />
                회원 정보 삭제가 완료되면 최초 등록하신 이메일로 처리 결과가
                안내됩니다.
                <br />
                그동안 "와이낫링크"를 이용해 주셔서 정말 감사했습니다."
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeleteComplete === false && (
        <div className="mypage_section">
          <MySideMenu />
          <div className="mypage_contents my_account_page">
            <form onSubmit={handleSubmit(deleteAccountRequest)}>
              <div className="page_title">회원 탈퇴 요청</div>
              <div className="input_item">
                <div className="input_title">비밀번호확인</div>
                <div className="input_contents">
                  <div className={"change_password_section"}>
                    <input
                      type={"password"}
                      name={"oldPassword"}
                      placeholder={"현재 비밀번호"}
                      {...register("oldPassword", {
                        required: "비밀번호를 입력해주세요",
                        pattern: {
                          value: /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{0,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/,
                          message:
                            "비밀번호는 8자이상(숫자,문자포함) 입력하셔야합니다",
                        },
                      })}
                    />
                    {errors?.oldPassword && (
                      <div className={"error_msg"}>
                        {errors?.oldPassword?.message}
                      </div>
                    )}
                    {/* <input
                    type={"password"}
                    name={"password"}
                    placeholder={"변경될 비밀번호"}
                    {...register("password", {
                      required: "비밀번호를 입력해주세요",
                      pattern: {
                        value: /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{0,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/,
                        message:
                          "비밀번호는 8자이상(숫자,문자포함) 입력하셔야합니다",
                      },
                    })}
                  />
                  {errors?.password && (
                    <div className={"error_msg"}>
                      {errors?.password?.message}
                    </div>
                  )}
                  <input
                    type={"password"}
                    name={"passwordCheck"}
                    placeholder={"변경될 비밀번호 확인"}
                    {...register("passwordCheck", {
                      validate: (value) =>
                        value === watch("password") ||
                        "비밀번호가 일치하지 않습니다",
                    })}
                  />
                  {errors?.passwordCheck && (
                    <div className={"error_msg"}>
                      {errors?.passwordCheck?.message}
                    </div>
                  )} */}
                    <div className="modify_bt_section">
                      <button type={"submit"} className="default_bt submit_bt">
                        탈퇴요청
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export default DeleteAccount;
