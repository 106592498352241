import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as baseActions from "../store/base";

function MySideMenu(props) {
  let current_page = useLocation().pathname;
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [buttons, setButtons] = useState({
    expertEnroll: false,
    expertApply: false,
    expertInfo: false,
  });

  useEffect(() => {
    switch (userInfo.userLevel) {
      case "manager":
      case "expert":
      case "expert(modify)":
        setButtons({
          expertEnroll: false,
          expertApply: true,
          expertInfo: true,
        });
        break;
      case "client":
      case "expert(pre)":
        setButtons({
          expertEnroll: true,
          expertApply: false,
          expertInfo: false,
        });
        break;
      default:
        setButtons({
          expertEnroll: false,
          expertApply: false,
          expertInfo: false,
        });
    }
  }, [userInfo]);

  const logout = (e) => {
    dispatch(baseActions.logout());
  };

  return (
    <div className="side_menu">
      <div className="sm_title">마이페이지</div>
      <div className="sm_contents">
        <div className="sm_items">
          <div
            className={
              "sm_item " + (current_page === "/mypage/account" ? "active" : "")
            }
          >
            <Link to={"/mypage/account"}>회원정보수정</Link>
          </div>
          <div
            className={
              "sm_item " +
              (current_page === "/mypage/changePassword" ? "active" : "")
            }
          >
            <Link to={"/mypage/changePassword"}>비밀번호변경</Link>
          </div>
        </div>
        <div className="sm_items">
          <div className="sm_cate_title">클라이언트</div>
          <div
            className={
              "sm_item " + (current_page === "/mypage/project" ? "active" : "")
            }
          >
            <Link to={"/mypage/project"}>프로젝트 관리</Link>
          </div>
          <div
            className={
              "sm_item " +
              (current_page === "/mypage/project/apply" ? "active" : "")
            }
          >
            <Link to={"/mypage/project/apply"}>업체 지원내역</Link>
          </div>
        </div>
        <div className="sm_items">
          <div className="sm_cate_title">전문가</div>
          {buttons.expertEnroll && (
            <div
              className={
                "sm_item " +
                (current_page === "/mypage/pro/Register/" ? "active" : "")
              }
            >
              <Link to={"/mypage/pro/Register/"}>전문가 지원</Link>
            </div>
          )}
          {buttons.expertInfo && (
            <div
              className={
                "sm_item " + (current_page === "/mypage/pro" ? "active" : "")
              }
            >
              <Link to={"/mypage/pro"}>전문가 정보관리</Link>
            </div>
          )}
          {buttons.expertApply && (
            <div
              className={
                "sm_item " +
                (current_page === "/mypage/pro/apply" ? "active" : "")
              }
            >
              <Link to={"/mypage/pro/apply"}>프로젝트 지원내역</Link>
            </div>
          )}
          <div
            className="sm_item"
            onClick={logout}
            style={{ paddingTop: "15px" }}
          >
            로그아웃
          </div>
        </div>
      </div>
    </div>
  );
}
export default MySideMenu;
