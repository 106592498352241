import React,{useState} from "react"
import useBus from "use-bus";



function WhyMark(props){
    const [descView,setDescView]= useState(false);
    useBus(
        'popup_clear',
        () => {
            setDescView(false);
        },
        [],
    )
    return(
        <div className="whl_mark" onClick={(e)=>{setDescView(!descView);e.stopPropagation()}}>WNL
            {descView&&<div className="whl_desc">와이낫에서 직접 관리하는 솔루션 제안입니다</div>}
        </div>
    )
}
export default WhyMark
