import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const OldUrlRedirct = () => {
  const history = useHistory();
  const url = window.location.pathname;
  const redirectUrl = "/",
    message = "등록되지 않은 라우팅 주소입니다.",
    type = "페이지 주소 에러";
  useEffect(() => {
    switch (url) {
      case "/projects/list/all":
        history.push("/recruit");
        break;
      case "/users/expert-enroll":
        history.push("/mypage/pro/Register/");
        break;
      case "/projects/process-guide":
        history.push("/recruit/register");
        break;
      case "/about":
        history.push("intro");
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="error_page">
      <div className="error_section">
        <div className="er_header">
          <img src="./img/logo_w.svg" alt={"logo"} />
        </div>
        <div className="er_contents er_join">
          <div className="er_title">페이지 에러</div>
          <div className="er_bt_section">
            - 가이트 링크:{" "}
            <a href={redirectUrl}>여기를 클릭해 다시 시스템을 이용해주세요.</a>
          </div>
          <div className="er_subtitle">- 오류 메세지: {message}</div>
          <div className="er_div_line"></div>

          <div className="er_sub_desc">
            {type}, 위 링크를 통해 다시 접근해주세요.
          </div>
        </div>
      </div>
    </div>
  );
};
export default OldUrlRedirct;
