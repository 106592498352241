import React, { useEffect } from "react"; //useState,
import { useHistory } from "react-router-dom";
import queryString from "query-string";

function SocialJoin(props) {
  const { name, email, socialId, provider } = queryString.parse(
    props.location.search
  );
  const history = useHistory();

  useEffect(() => {
    if (socialId && provider) {
      setTimeout(() => {
        history.push("/join", {
          isSocialUser: true,
          name,
          email,
          socialId,
          provider,
        });
      }, 500);
    } else {
      alert(
        "소셜 로그인과정에서 오류가 발생하였습니다. 일반 회원가입으로 진행됩니다."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login_page">
      <svg className="loader" viewBox="0 0 24 24">
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
        <circle className="loader__value" cx="12" cy="12" r="10" />
      </svg>
    </div>
  );
}
export default SocialJoin;
