import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const andApp = "https://play.google.com/store/apps/details?id=whynot.mobile";
  const iosApp = "https://apps.apple.com/app/id1584293937";
  const oneApp =
    "https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000759776";
  return (
    <div className="footer">
      <div className={"terms_items"}>
        <ul>
          <li>
            <Link to={"/notice"}>공지사항</Link>
          </li>
          <li>
            <Link to={"/faq"}>FAQ</Link>
          </li>
          <li>
            <Link to={"/terms"}>이용약관</Link>
          </li>
          <li>
            <Link to={"/privacy"}>개인정보취급방침</Link>
          </li>
          <li>
            <a
              href="https://blog.naver.com/whynotlink/222122213208"
              target="_blank"
              rel="noreferrer"
            >
              유저이용가이드
            </a>
          </li>
          <li>
            <a
              href="https://blog.naver.com/whynotlink/222181853610"
              target="_blank"
              rel="noreferrer"
            >
              전문가이용가이드
            </a>
          </li>
        </ul>
      </div>
      <div className="company_info">
        <ul>
          <li>
            (주)와이낫엠앤씨(대표이사: 박승훈, 조성주/ 개인정보보호책임자:
            조성주)
          </li>
          <li>주소 : 서울특별시 송파구 중대로 135, IT벤처타워 서관 11층</li>
          <li>사업자등록번호 : 611-81-28336</li>
          <li>통신판매신고번호: 제2023-서울송파-0519호</li>
          <li>
            <a
              href={"https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6118128336"}
              target={"_blank"}
              rel="noreferrer"
            >
              사업자정보확인
            </a>
          </li>
          <br />
          <li>
            고객센터: 02-6964-6479 | 평일 10:00 ~ 18:00(토/일, 공휴일 휴무)
          </li>
          <li>help@whynotmnc.com</li>
        </ul>
      </div>
      <div className="spon_logo">
        <div className="spon_logos patent_check_logo">
          <img
            src="/img/patent_check_gray_logo.svg"
            alt="patent_check_gray_logo.svg"
          />
        </div>
        <div className="spon_logos kibo_social_logo">
          <img
            src="/img/kibo_social_gray_logo.svg"
            alt="kibo_social_gray_logo.svg"
          />
        </div>
        {/* <div className="spon_logos break"></div> */}
        <div className="spon_logos ksepa_logo">
          <img src="/img/ksepa_logo_300.svg" alt="ksepa_logo_300.svg" />
        </div>
        <div className="spon_logos kosme_logo">
          <img src="/img/kosme_logo.svg" alt="kosme_logo.svg" />
        </div>
        {/* <div className="spon_logos break"></div> */}
        <div className="spon_logos whynot_patent_logo">
          <img
            src="/img/whynot_patent_gray_logo.svg"
            alt="whynot_patent_gray_logo.svg"
          />
        </div>
      </div>
      <div className="ft_socialLink">
        <a href={andApp} target="_blank" rel="noreferrer">
          <img
            src={"/img/google_play_logo.svg"}
            alt="/img/google_play_logo.svg"
          ></img>
        </a>
        <a href={iosApp} target="_blank" rel="noreferrer">
          <img
            src={"/img/apple_store_logo.svg"}
            alt="/img/apple_store_logo.svg"
          ></img>
        </a>
        <a href={oneApp} target="_blank" rel="noreferrer">
          <img
            src={"/img/one_downloadbadge.svg"}
            alt="/img/one_downloadbadge.svg"
          ></img>
        </a>
        <a
          href={"https://pf.kakao.com/_wbxcnxb"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={"/img/icon_kakaoChannel_full.jpg"}
            alt="icon_kakaoChannel_full.jpg"
          ></img>
        </a>
        <a
          href={"https://blog.naver.com/whynotlink"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={"/img/icon_naverBlog_full.jpg"}
            alt="icon_naverBlog_full.jpg"
          ></img>
        </a>
      </div>
      <div className="ft_copyright">
        Copyright@WhyNotM&C co., Ltd. All rights reserved
      </div>
    </div>
  );
}
export default Footer;
