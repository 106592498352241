/**
 * 페이지 라우팅 정의 영역
 */
// import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import Login from "./pages/auth/login";
import Join from "./pages/auth/join";
import PasswordChange from "./pages/auth/passwordChange";
import IdSearch from "./pages/auth/idSearch";
import SocialJoin from "./pages/auth/socialJoin";
import SocialLogin from "./pages/auth/socialLogin";
import ErrorPage from "./pages/auth/errorPage";
import SendPWEmail from "./pages/auth/PWEmail";
import Main from "./pages/main";
import Intro from "./pages/intro";
import RecruitList from "./pages/recruit/recruitList";
import RecruitRegister from "./pages/recruit/recruitRegister";
import RequestRegister from "./pages/recruit/requestRegister";
import RecruitDetail from "./pages/recruit/recruitDetail";
import RecruitApply from "./pages/recruit/recruitApply";
import RecruitApplyCoEx from "./pages/recruit/recruitApplyCoEx";
import Account from "./pages/mypage/account";
import ChangePassword from "./pages/mypage/changePassword";
import DeleteAccount from "./pages/mypage/deleteAccount";
import ProjectManage from "./pages/mypage/projectManage";
import ProjectModify from "./pages/mypage/projectModify";
import ProjectApplyDetail from "./pages/mypage/projectApplyDetail";
import ProjectApplyHistory from "./pages/mypage/projectApplyHistory";
import ProManage from "./pages/mypage/proManage";
import ProModify from "./pages/mypage/proModify";
import ProApplyDetail from "./pages/mypage/proApplyDetail";
import ProApplyModify from "./pages/mypage/proApplyModify";
import ProApplyHistory from "./pages/mypage/proApplyHistory";
import ProjectApplyComplete from "./pages/mypage/projectApplyComplete";

import ReviewList from "./pages/review/reviewList";
import ReviewWrite from "./pages/review/reviewWrite";
import ReviewDetail from "./pages/review/reviewDetail";

import ProRegister from "./pages/mypage/proRegister";
import PortFolioAdd from "./pages/mypage/portfolioAdd";

import Terms from "./pages/etc/terms";
import Privacy from "./pages/etc/privacy";
import Alarms from "./pages/etc/alarms";
import FAQ from "./pages/etc/faq";
import Notice from "./pages/etc/notice";

//admin
import AdminMain from "./pages/admin/adminMain";
import RequestList from "./pages/admin/RequestList";
import RequestCheck from "./pages/admin/requestCheck";
import RecruitListAdmin from "./pages/admin/recruitListAdmin";
import RecruitCheck from "./pages/admin/recruitCheck";
import ProjectWrite from "./pages/admin/ProjectWrite";
import ProjectWriteV2 from "./pages/admin/ProjectWriteV2";
import ExpertEnrollList from "./pages/admin/ExpertEnrollList";
import ExpertInfoCheck from "./pages/admin/expertInfoCheck";
import NoticeEdit from "./pages/admin/noticeEdit";
import FaqEdit from "./pages/admin/faqEdit";
import FAQEditAll from "./pages/admin/faqEditAll";
import RecruitRegisterAdmin from "./pages/admin/recruitRegister";
import ExpertManage from "./pages/admin/expertManage";
import ProjectAdmin from "./pages/admin/projectAdmin";
import AdminRecruitDetail from "./pages/admin/adminRecruitDetail";
import EstimateTest from "./pages/admin/estimateTest";

// old url redirect
import OldUrlRedirect from "./pages/oldUrlRedirect";

// auth seo

/*
//인증기반 라우팅 로직 처리 함수
const PrivateRoute = ({ component: Component, roles, ...rest }) => (


    <Route {...rest} render={props => {

        const isAuthTokenValid = isUserAuthenticated();

        //비로그인시 로그인 페이지로 이동
        if (!isAuthTokenValid) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        //정상 로그인시 루트 페이지로 이동
        const loggedInUser = getLoggedInUser();
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
            return <Redirect to={{ pathname: '/' }} />
        }

        return <Component {...props} />
    }} />
)

 */

//라우팅 명세 목록
const routes = [
  { path: "/login", name: "Login", component: Login, route: Route },
  { path: "/join", name: "Join", component: Join, route: Route },
  {
    path: "/socialJoin",
    name: "SocialJoin",
    component: SocialJoin,
    route: Route,
  },
  {
    path: "/socialLogin",
    name: "SocialLogin",
    component: SocialLogin,
    route: Route,
  },
  {
    path: "/errorPage",
    name: "ErrorPage",
    component: ErrorPage,
    route: Route,
  },
  {
    path: "/changePassword/:url",
    name: "PasswordChange",
    component: PasswordChange,
    route: Route,
  },
  {
    path: "/SendPWEmail",
    name: "SendPWEmail",
    component: SendPWEmail,
    route: Route,
  },
  { path: "/idsearch", name: "IdSearch", component: IdSearch, route: Route },
  { path: "/", name: "Main", component: Main, route: Route },
  { path: "/intro", name: "Intro", component: Intro, route: Route },
  {
    path: "/request/register",
    name: "RequestResister",
    component: RequestRegister,
    route: Route,
  },
  {
    path: "/recruit",
    name: "RecruitList",
    component: RecruitList,
    route: Route,
  },
  {
    path: "/recruit/detail/:id",
    name: "RecruitDetail",
    component: RecruitDetail,
    route: Route,
  },
  {
    path: "/recruit/register",
    name: "RecruitResister",
    component: RecruitRegister,
    route: Route,
  },
  {
    path: "/recruit/apply/:id",
    name: "RecruitApply",
    component: RecruitApply,
    route: Route,
  },
  {
    path: "/recruit/apply/:id/coEx/:expertId",
    name: "RecruitApplyCoEx",
    component: RecruitApplyCoEx,
    route: Route,
  },
  {
    path: "/mypage/account",
    name: "Account",
    component: Account,
    route: Route,
  },
  {
    path: "/mypage/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
    route: Route,
  },
  {
    path: "/mypage/deleteAccount",
    name: "DeleteAccount",
    component: DeleteAccount,
    route: Route,
  },
  {
    path: "/mypage/project",
    name: "ProjectManage",
    component: ProjectManage,
    route: Route,
  },
  {
    path: "/mypage/project/modify/:id",
    name: "ProjectModify",
    component: ProjectModify,
    route: Route,
  },
  {
    path: "/mypage/project/apply",
    name: "ProjectApplyHistory",
    component: ProjectApplyHistory,
    route: Route,
  },
  {
    path: "/mypage/project/apply/detail/:id",
    name: "ProjectApplyDetail",
    component: ProjectApplyDetail,
    route: Route,
  },
  {
    path: "/mypage/project/apply/complete",
    name: "ProjectApplyDetail",
    component: ProjectApplyComplete,
    route: Route,
  },
  {
    path: "/mypage/pro",
    name: "ProManage",
    component: ProManage,
    route: Route,
  },
  {
    path: "/mypage/pro/modify",
    name: "ProModify",
    component: ProModify,
    route: Route,
  },
  {
    path: "/mypage/pro/apply",
    name: "ProApplyHistory",
    component: ProApplyHistory,
    route: Route,
  },
  {
    path: "/mypage/pro/apply/detail/:id",
    name: "ProApplyDetail",
    component: ProApplyDetail,
    route: Route,
  },
  {
    path: "/mypage/pro/apply/modify/:id",
    name: "ProApplyModify",
    component: ProApplyModify,
    route: Route,
  },

  {
    path: "/mypage/pro/Register/",
    name: "ProRegister",
    component: ProRegister,
    route: Route,
  },
  {
    path: "/mypage/pro/PortFolioAdd/",
    name: "PortFolioAdd",
    component: PortFolioAdd,
    route: Route,
  },

  //review
  {
    path: "/review",
    name: "reviewList",
    component: ReviewList,
    route: Route,
  },
  {
    path: "/recruit/review/write/:id",
    name: "reviewWrite",
    component: ReviewWrite,
    route: Route,
  },
  {
    path: "/review/:id",
    name: "reviewDetail",
    component: ReviewDetail,
    route: Route,
  },

  { path: "/terms", name: "Terms", component: Terms, route: Route },
  { path: "/privacy", name: "Privacy", component: Privacy, route: Route },
  { path: "/alarmHistory", name: "Alarms", component: Alarms, route: Route },
  { path: "/faq", name: "FAQ", component: FAQ, route: Route },
  { path: "/notice", name: "Notice", component: Notice, route: Route },

  // admin
  {
    path: "/admin/main",
    name: "adminMain",
    component: AdminMain,
    route: Route,
  },
  {
    path: "/admin/recruitList",
    name: "RecruitListAdmin",
    component: RecruitListAdmin,
    route: Route,
  },
  {
    path: "/admin/requestList",
    name: "RequestList",
    component: RequestList,
    route: Route,
  },
  {
    path: "/admin/request/check/:id",
    name: "RequestCheck",
    component: RequestCheck,
    route: Route,
  },
  {
    path: "/admin/recruit/check/:id",
    name: "RecruitCheck",
    component: RecruitCheck,
    route: Route,
  },
  {
    path: "/admin/recruit/write/:id",
    name: "ProjectWrite",
    component: ProjectWrite,
    route: Route,
  },
  {
    path: "/admin/recruit/write/:id/v2",
    name: "ProjectWriteV2",
    component: ProjectWriteV2,
    route: Route,
  },
  {
    path: "/admin/expertEnrollList",
    name: "ExpertEnrollList",
    component: ExpertEnrollList,
    route: Route,
  },
  {
    path: "/admin/expertEnroll/check/:id",
    name: "ExpertInfoCheck",
    component: ExpertInfoCheck,
    route: Route,
  },
  {
    path: "/notice/edit/:editType/:id",
    name: "NoticeEdit",
    component: NoticeEdit,
    route: Route,
  },
  {
    path: "/faq/edit/:editType/:id",
    name: "FaqEdit",
    component: FaqEdit,
    route: Route,
  },
  {
    path: "/faq/edit/fileUpload",
    name: "FAQEditAll",
    component: FAQEditAll,
    route: Route,
  },
  {
    path: "/admin/recruit/register/:id",
    name: "recruitRegisterAdmin",
    component: RecruitRegisterAdmin,
    route: Route,
  },
  {
    path: "/admin/expertManage",
    name: "ExpertManage",
    component: ExpertManage,
    route: Route,
  },
  {
    path: "/admin/projectAdmin",
    name: "ProjectAdmin",
    component: ProjectAdmin,
    route: Route,
  },
  {
    path: "/admin/recruit/detail/:id",
    name: "AdminRecruitDetail",
    component: AdminRecruitDetail,
    route: Route,
  },

  // dev testing
  {
    path: "/admin/estimateTest",
    name: "EstimateTest",
    component: EstimateTest,
    route: Route,
  },

  // old url redirection
  {
    path: "/projects/list/all",
    name: "oldProjectList",
    component: OldUrlRedirect,
    route: Route,
  },
  {
    path: "/users/expert-enroll",
    name: "oldExpertEnroll",
    component: OldUrlRedirect,
    route: Route,
  },
  {
    path: "/projects/process-guide",
    name: "oldRequest",
    component: OldUrlRedirect,
    route: Route,
  },
  {
    path: "/about",
    name: "oldAbout",
    component: OldUrlRedirect,
    route: Route,
  },
];

export { routes };
