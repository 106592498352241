import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import axios from "axios";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import { moneyFormat } from "../../utils/commonApi";
import moment from "moment";
import "moment-timezone";

function ProjectManage(props) {
  const token = localStorage.getItem("token");
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [projects, setProjects] = useState([]);
  const [amount, setAmount] = useState(15);
  const history = useHistory();
  const dueHours = 21;
  useEffect(() => {
    axios
      .get("/rest-api/getMyProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount: amount,
          index: pageNum,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.totalProjectCount <= amount * pageNum)
            setLastPage(true);
          setProjects([...response.data.projects]);
          setPageNum(response.data.nextIndex);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageMore = () => {
    axios
      .get("/rest-api/getMyProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount,
          index: pageNum,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setProjects([...projects, ...response.data.projects]);
          if (response.data.totalProjectCount <= amount * pageNum)
            setLastPage(true);
          setPageNum(response.data.nextIndex);
        } else if (response.status === 202) {
          console.log("last");
          setLastPage(true);
        }
      });
  };

  const prModify = (projectId) => {
    if (!projectId) return 0;
    else
      history.push({
        pathname: `/mypage/project/modify/${projectId}`,
      });
  };

  const prDelete = (projectId, index, type) => {
    if (!projectId) return 0;
    else {
      if (
        window.confirm(
          `해당 프로젝트 삭제 요청을 ${
            type === "cancel" ? "취소" : ""
          }하시겠습니까?`
        )
      )
        axios
          .get("/rest-api/deleteProject", {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              projectId,
              type,
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              let tempProjects = projects;
              alert(response.data.message);
              tempProjects[index].status =
                type === "cancel" ? "모집중" : "삭제요청";
              setProjects([...tempProjects]);
            }
          });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents project_list_page">
          <div className="page_title">프로젝트 관리</div>
          <div className="project_items">
            {projects.length > 0 &&
              projects.map((project, index) => {
                const now = moment().tz("Asia/Seoul");
                const applyDueDate = moment(project.applyDueDate)
                  .tz("Asia/Seoul")
                  .add(dueHours, "hours");
                const dueDate = moment(project.dueDate)
                  .tz("Asia/Seoul")
                  .add(dueHours, "hours");
                let activeClass = "";
                switch (project.status) {
                  case "업무검토":
                    activeClass = "";
                    break;
                  case "업무종료":
                  case "삭제요청":
                    activeClass = " not_active";
                    break;
                  default:
                    activeClass = " active";
                }
                return (
                  <div className={"pr_item" + activeClass} key={index}>
                    <div className="pr_info">
                      <div className="pr_state">{project.status}</div>
                      <div className="pr_contents">
                        <div className="pr_cate">
                          {project.mainCategory.map((cat, index) => {
                            return (
                              <span id={index} key={index}>
                                {index !== 0 && "/"}
                                {cat}
                              </span>
                            );
                          })}
                        </div>
                        <div className="pr_title">{project.title}</div>
                        <div className="pr_options">
                          <ul>
                            <li>총예산 {moneyFormat(project.cost)}만원/</li>
                            <li>
                              모집마감{" "}
                              <Moment format="MM/DD">
                                {project.applyDueDate}
                              </Moment>
                              {applyDueDate.diff(now, "minutes") <= 0 && (
                                <span>(기간만료)</span>
                              )}
                              {applyDueDate.diff(now, "minutes") > 0 && (
                                <span>
                                  (
                                  <Moment
                                    diff={moment().tz("Asia/Seoul").format()}
                                    unit={"days"}
                                    tz="Asia/Seoul"
                                  >
                                    {applyDueDate}
                                  </Moment>
                                  일 남음)
                                </span>
                              )}
                              /
                            </li>
                            <li>
                              납기일{" "}
                              <Moment format="MM/DD">{project.dueDate}</Moment>
                              {dueDate.diff(now, "minutes") <= 0 && (
                                <span>(기간만료)</span>
                              )}
                              {dueDate.diff(now, "minutes") > 0 && (
                                <span>
                                  (
                                  <Moment
                                    diff={new Date()}
                                    unit={"days"}
                                    tz="Asia/Seoul"
                                  >
                                    {dueDate}
                                  </Moment>
                                  일 남음)
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {project.status !== "삭제요청" && (
                        <div className="pr_bt_section">
                          <button className="default_bt">
                            <Link to={`/recruit/detail/${project.id}`}>
                              상세보기
                            </Link>
                          </button>
                          <button
                            className="default_bt"
                            onClick={(event) => {
                              event.preventDefault();
                              if (
                                project.status === "업무검토" 
                                // || project.status === "모집중" 
                                // || project.status === "선정중"
                              )
                                prModify(project.id);
                              else
                                alert(
                                  `"${project.status}" 상태에서는 수정/삭제가 불가합니다. 관리자에게 문의해주세요.`
                                );
                            }}
                          >
                            수정
                          </button>
                          <button
                            className="default_bt"
                            onClick={(event) => {
                              event.preventDefault();
                              if (
                                project.status === "업무검토" 
                                // || project.status === "모집중" 
                                // || project.status === "선정중"
                              )
                                prDelete(project.id, index, "request");
                              else
                                alert(
                                  `"${project.status}" 상태에서는 수정/삭제가 불가합니다. 관리자에게 문의해주세요.`
                                );
                            }}
                          >
                            삭제
                          </button>
                        </div>
                      )}
                      {project.status === "삭제요청" && (
                        <div className="pr_bt_section">
                          <button
                            className="default_bt"
                            onClick={(event) => {
                              event.preventDefault();
                              prDelete(project.id, index, "cancel");
                            }}
                          >
                            삭제취소
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          {!lastPage && projects.length !== 0 && (
            <div className={"more_page_bt_section"}>
              <button className={"default_bt more_page_bt"} onClick={pageMore}>
                더보기
              </button>
            </div>
          )}
          {projects.length === 0 && (
            <div className={"no_recruit_contents"}>
              해당 조건에 일치하는 모집이 없습니다.
            </div>
          )}
          <div className="bottom_bt_section multi_bt">
            <button className="default_bt pr_add_bt">
              <Link to={"/recruit/register"}>프로젝트 추가</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjectManage;
