import React from "react"; //, { useState, useEffect }
import { Link } from "react-router-dom"; //useLocation,
import { useDispatch } from "react-redux"; //, useSelector
import * as baseActions from "../store/base";

function AdminSideMenu(props) {
  // let current_page = useLocation().pathname;
  // const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  // const [buttons, setButtons] = useState({
  //   expertEnroll: false,
  //   expertApply: false,
  //   expertInfo: false,
  // });

  // useEffect(() => {
  //   switch (userInfo.userLevel) {
  //     case "manager":
  //     case "expert":
  //     case "expert(modify)":
  //       setButtons({
  //         expertEnroll: false,
  //         expertApply: true,
  //         expertInfo: true,
  //       });
  //       break;
  //     case "client":
  //     case "expert(pre)":
  //       setButtons({
  //         expertEnroll: true,
  //         expertApply: false,
  //         expertInfo: false,
  //       });
  //       break;
  //     default:
  //       setButtons({
  //         expertEnroll: false,
  //         expertApply: false,
  //         expertInfo: false,
  //       });
  //   }
  // }, [userInfo]);

  const logout = (e) => {
    dispatch(baseActions.logout());
  };

  return (
    <div className="side_menu">
      <div className="sm_title">
        <Link to={"/admin/main"}>관리자페이지</Link>
      </div>
      <div className="sm_contents">
        <div className="sm_items">
          <div className={"sm_cate_title"}>업무요청</div>
          <div className={"sm_item"}>
            <Link to={"/admin/requestList"}>간편문의관리</Link>
          </div>
          <div className={"sm_item "}>
            <Link to={"/admin/recruitList"}>업무요청관리</Link>
          </div>
        </div>
        <div className="sm_items">
          <div className={"sm_cate_title"}>전문가</div>
          <div className={"sm_item "}>
            <Link to={"/admin/expertEnrollList"}>전문가신청관리</Link>
          </div>
          <div className={"sm_item "}>
            <Link to={"/admin/expertManage"}>전문가관리</Link>
          </div>
        </div>
        <div className="sm_items">
          <div className={"sm_cate_title"}>프로젝트</div>
          <div className={"sm_item "}>
            <Link to={"/admin/projectAdmin"}>프로젝트관리(관리자)</Link>
          </div>
        </div>
        <div className="sm_items">
          <div className={"sm_cate_title"}>개발테스트</div>
          <div className={"sm_item "}>
            <Link to={"/admin/estimateTest"}>견적시스템</Link>
          </div>
        </div>
        <div className="sm_items">
          <div className="sm_item" onClick={logout}>
            로그아웃
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminSideMenu;
