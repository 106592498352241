import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";

function RequestCheck(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [request, setRequest] = useState({});
  const [files, setFiles] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요한 페이지입니다.");
      history.push("/login");
    } else {
      axios
        .get("/rest-api/getRequest", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            id: Number(id),
          },
        })
        .then(function (res) {
          //console.log(res.data);
          if (res.status === 200) {
            setRequest(res.data.request);
            setFiles([...res.data.request.files]);
          } else if (res.data.error.indexOf("jwt")) {
            alert("권한이 없는 요청입니다.");
            history.push("/");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeProcess = (status) => {
    if (window.confirm(`이 문의글을 "${status}" 처리 하시겠습니까?`))
      axios
        .post(
          "/rest-api/changeRQstatus",
          {
            requestId: Number(id),
            changeStatus: status,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            alert(response.data.message);
            history.push("/admin/requestList");
          } else alert(response.data.error);
        });
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">간편문의내역</div>
        <div className="recruit_header">
          <div className="rc_title">
            {request.title}{" "}
            <span className="rc_time">
              <Moment fromNow>{request.createdAt}</Moment>
            </span>
            <span
              className={
                request.status === "문의등록" ? "rc_time active" : "rc_time"
              }
            >
              {request.status}
            </span>
          </div>
          <div className="rc_option_items">
            <div className="info_item">
              <div className="info_title">문의자</div>
              <div className="info_contents">{request.userName}</div>
            </div>
            <div className="info_item">
              <div className="info_title">문의No.</div>
              <div className="info_contents">{request.requestId}</div>
            </div>
            <div className="info_item">
              <div className="info_title">연락처</div>
              <div className="info_contents">{request.userPhone}</div>
            </div>
            <div className="info_item">
              <div className="info_title">이메일</div>
              <div className="info_contents">{request.userEmail}</div>
            </div>
          </div>
          <div className="rc_option_items attachFiles">
            <div className="info_item">
              <div className="info_title">첨부파일</div>
              <div className="info_contents">
                {files.length === 0 && <span>첨부파일이 없습니다.</span>}
                {files.length > 0 &&
                  files.map((file, index) => {
                    return (
                      <span key={index}>
                        {index !== 0 && <br />}
                        <a href={file.url} download={file.originalFileName}>
                          {file.originalFileName}
                        </a>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="recruit_summary">
          <div className="info_title">업무개요</div>
          <div className="rcs_contents">
            {request.content &&
              request.content.split("\n").map((item, idx) => {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </div>
        </div>
        <div className="bottom_bt_section">
          <button
            className="default_bt submit_bt"
            onClick={() => {
              changeProcess("확인완료");
            }}
          >
            완료처리
          </button>
          <button
            className="default_bt more_page_bt"
            onClick={() => {
              changeProcess("문의등록");
            }}
          >
            신규처리
          </button>
        </div>
      </div>
    </div>
  );
}
export default RequestCheck;
