import React, { useState, useEffect } from "react";
import MySideMenu from "../../component/mySideMenu";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { moneyFormat } from "../../utils/commonApi";
import moment from "moment";
import "moment-timezone";

function ProApplyDetail(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [project, setProject] = useState({});
  const [projectType, setProjectType] = useState("main");
  const [expertInfo, setExpertInfo] = useState({});
  const [apply, setApply] = useState({});
  const [applyFiles, setApplyFiles] = useState([]);
  const [buttons, setButtons] = useState({ modify: false, endWork: false });

  useEffect(() => {
    const getApply = async () => {
      try {
        const response = await axios.get("/rest-api/getApply", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            id: Number(id),
          },
        });
        // console.log("res: ", response.data);
        if (response.status === 200) {
          const pType = response.data.apply.projectType;
          setProjectType(pType);
          setProject(response.data.project);
          setExpertInfo(response.data.expertInfo);
          setApply(response.data.apply);
          setApplyFiles([...response.data.apply.file]);
          if (
            response.data.project.status === "모집중" &&
            response.data.apply.status === "지원중"
          )
            setButtons({ modify: true, endWork: false });
          else if (
            response.data.project.status === "업무진행" &&
            response.data.apply.status === "선정"
          )
            setButtons({ modify: false, endWork: true });
        } else {
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apModify = () => {
    if (!apply) return 0;
    else {
      if (apply.status === "지원중" && project.status === "모집중") {
        history.push({
          pathname: `/mypage/pro/apply/modify/${apply.id}`,
        });
      } else {
        alert(
          `${apply.status} 상태에서는 수정이 불가합니다. 관리자에게 문의해주세요.`
        );
      }
    }
  };

  const apEndWork = () => {
    if (
      window.confirm(
        "납품을 완료하셨나요? 프로젝트 종료를 요청하시려면 확인을 눌러주세요."
      )
    )
      axios
        .get("/rest-api/projectEndRequest", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            applyId: Number(id),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setButtons({ modify: false, endWork: false });
            alert(res.data.message);
          } else alert(res.data.error);
        });
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <div className="page_title">프로젝트 지원내역</div>
          <div className="info_item">
            <div className="info_title">프로젝트</div>
            <div className="info_contents">
              {projectType === "main"
                ? project.title
                : project.project !== undefined
                ? `${project.project.title}(${project.project.status})`
                : "프로젝트 타이틀"}
            </div>
          </div>
          <div className="info_item">
            <div className="info_title">
              {projectType === "main" ? "프로젝트 No" : "공정내용"}
            </div>
            <div className="info_contents">
              {projectType === "main" ? project.projectId : project.title}
            </div>
          </div>
          <div className="info_item">
            <div className="info_title">업무분야</div>
            <div className="info_contents">
              {project.category &&
                project.category.split(",").map((item, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && "/"}
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="div_line"></div>
          <div className="info_item">
            <div className="info_title">업체(개인)이름</div>
            <div className="info_contents">{expertInfo.companyName}</div>
          </div>
          <div className="info_item">
            <div className="info_title">진행상태</div>
            <div className="info_contents">{apply.status}</div>
          </div>
          <div className="info_item full_item">
            <div className="info_title">회사소개</div>
            <div className="info_contents">
              {expertInfo.content &&
                expertInfo.content.split("\n").map((item, index) => {
                  return (
                    <span key={index}>
                      {item}
                      <br />
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="div_line"></div>
          <div className="info_item">
            <div className="info_title">제안금액</div>
            <div className="info_contents">
              {moneyFormat(apply.cost)}만원(VAT별도)
            </div>
          </div>
          <div className="info_item">
            <div className="info_title">납기일</div>
            <div className="info_contents">{apply.dueDate}까지</div>
          </div>
          <div className="info_item full_item">
            <div className="info_title">제안내역</div>
            <div className="info_contents">
              {apply.content &&
                apply.content.split("\n").map((item, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && <br />}
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="info_item">
            <div className="info_title">첨부파일</div>
            <div className="info_contents">
              {applyFiles.length === 0 && <span>첨부파일이 없습니다.</span>}
              {applyFiles.length > 0 &&
                applyFiles.map((file, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && <br />}
                      {
                        <a href={file.url} download={file.originalFileName}>
                          {file.originalFileName}
                        </a>
                      }
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="bottom_bt_section multi_bt">
            <button
              className="default_bt prev_bt"
              onClick={() => {
                history.goBack();
              }}
            >
              뒤로가기
            </button>
            {buttons.modify && (
              <button className="default_bt submit_bt" onClick={apModify}>
                수정하기
              </button>
            )}
            {buttons.endWork && (
              <button className="default_bt submit_bt" onClick={apEndWork}>
                납품완료
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProApplyDetail;
