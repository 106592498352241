import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import { moneyFormat } from "../../utils/commonApi";
import MySideMenu from "../../component/mySideMenu";
import Moment from "react-moment";
import "moment/locale/ko";
import WhyMark from "../../component/WhyMark";
import moment from "moment";
import "moment-timezone";

function ProjectApplyHistory(props) {
  const [applies, setApplies] = useState([]);
  const [projects, setProjects] = useState([]);
  const token = localStorage.getItem("token");
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const amount = 5;
  const history = useHistory();
  const location = useLocation();
  const dueHours = 21;
  useEffect(() => {
    axios
      .get("/rest-api/getMyProjectAppliesV2", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount: amount,
          index: pageNum,
        },
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          // console.log(response.data);
          if (response.data.totalApplyCount <= amount * pageNum)
            setLastPage(true);
          setProjects([...response.data.projects]);
          setPageNum(response.data.nextIndex);
        } else console.log(response.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const pageMore = () => {
    axios
      .get("/rest-api/getMyProjectAppliesV2", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          amount,
          index: pageNum,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setProjects([...projects, ...response.data.projects]);
          if (response.data.totalApplyCount <= amount * pageNum)
            setLastPage(true);
          setPageNum(response.data.nextIndex);
        } else if (response.status === 202) {
          console.log("last");
          setLastPage(true);
        }
      });
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents project_list_page">
          <div className="page_title">업체지원내역</div>
          <div className="project_items">
            {projects.length > 0 &&
              projects.map((project, index) => {
                let activeClass = "";
                switch (project.status) {
                  case "업무검토":
                    activeClass = "";
                    break;
                  case "업무종료":
                  case "삭제요청":
                    activeClass = " not_active";
                    break;
                  default:
                    activeClass = " active";
                }
                return (
                  <div className={"pr_item" + activeClass} key={index}>
                    <div className="pr_info">
                      <div className="pr_state">{project.status}</div>
                      <div className="pr_contents">
                        <div className="pr_cate">{project.mainCategory}</div>
                        <div className="pr_title">{project.title}</div>
                      </div>
                    </div>
                    <div className="pr_apply_items">
                      {project.applies.length === 0 && (
                        <div className={"pra_item no_apply"}>
                          해당 프로젝트에 지원한 전문가가 없습니다.
                        </div>
                      )}
                      {project.applies.length > 0 &&
                        project.applies.map((apply, idx) => {
                          const startTime = new Date(apply.createdAt);
                          let activeApply = "";
                          switch (apply.status) {
                            case "업무진행":
                              activeApply = "";
                              break;
                            case "업무종료":
                            case "미선정":
                              activeApply = " not_active";
                              break;
                            default:
                              activeApply = " active";
                          }
                          return (
                            <div className={"pra_item" + activeApply} key={idx}>
                              <div className="pra_state">
                                {apply.isManager && <WhyMark />}
                              </div>
                              <div className="pra_info">
                                {apply.companyName +
                                  "/ 금액 " +
                                  apply.cost +
                                  "만원/ 납기일 " +
                                  apply.dueDate}
                              </div>
                              <div className="pra_time">
                                <Moment fromNow>{startTime}</Moment>
                              </div>
                              <div className="pra_bt_section">
                                <Link
                                  to={`/mypage/project/apply/detail/${apply.id}`}
                                >
                                  <button className="default_bt">
                                    상세보기
                                  </button>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
          {!lastPage && projects.length !== 0 && (
            <div className={"more_page_bt_section"}>
              <button className={"default_bt more_page_bt"} onClick={pageMore}>
                더보기
              </button>
            </div>
          )}
          {projects.length === 0 && (
            <div className={"no_recruit_contents"}>
              내 프로젝트에 지원한 글이 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProjectApplyHistory;

// 퍼블리싱, 기존 코드
/*
<div className="mypage_contents project_list_page">
          <div className="page_title">업체지원내역</div>
          <div className="project_items">
            <div className="pr_item">
              <div className="pr_info">
                <div className="pr_state">진행중</div>
                <div className="pr_contents">
                  <div className="pr_cate">디자인/인쇄제작</div>
                  <div className="pr_title">
                    신메뉴 홍보용 플라이어 제작 의뢰드립니다.
                  </div>
                </div>
              </div>
              <div className="pr_apply_items">
                <div className="pra_item">
                  <div className="pra_state">
                    <WhyMark />
                  </div>
                  <div className="pra_info">
                    씨엔티테크/ 금액 170만원/ 납기일 2020-07-22
                  </div>
                  <div className="pra_time">5시간전</div>
                  <div className="pra_bt_section">
                    <Link to={`/mypage/project/apply/detail/`}>
                      <button className="default_bt">상세보기</button>
                    </Link>
                  </div>
                </div>
                <div className="pra_item not_active">
                  <div className="pra_state"></div>
                  <div className="pra_info">
                    씨엔티테크/ 금액 170만원/ 납기일 2020-07-22
                  </div>
                  <div className="pra_time">5시간전</div>
                  <div className="pra_bt_section">
                    <Link to={`/mypage/project/apply/detail/`}>
                      <button className="default_bt">상세보기</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {applies.length > 0 &&
              applies.map((apply, index) => {
                const now = moment().tz("Asia/Seoul");
                const dueDate = moment(apply.dueDate)
                  .tz("Asia/Seoul")
                  .add(dueHours, "hours");
                let className = "pr_item";
                switch (apply.status) {
                  case "업무진행":
                    className = "pr_item";
                    break;
                  case "업무종료":
                  case "미선정":
                    className = "pr_item not_active";
                    break;
                  default:
                    className = "pr_item active";
                }
                return (
                  <div className={className} key={index}>
                    <div className="pr_state">
                      {apply.status}
                      {apply.isManager === true && <WhyMark />}
                    </div>
                    <div className="pr_contents">
                      <div className="pr_cate">
                        {apply.mainCategory.map((cat, index) => {
                          return (
                            <span id={index} key={index}>
                              {index !== 0 && "/"}
                              {cat}
                            </span>
                          );
                        })}
                      </div>
                      <div className="pr_title">{apply.title}</div>
                      <div className="pr_options">
                        <ul>
                          <li>{apply.companyName}/</li>
                          <li>총예산 {moneyFormat(apply.cost)}만원/</li>
                          <li className="pr_option">
                            납기일{" "}
                            {<Moment format="MM/DD">{apply.dueDate}</Moment>}
                            {dueDate.diff(now, "minutes") <= 0 && (
                              <span>(기간만료)</span>
                            )}
                            {dueDate.diff(now, "minutes") > 0 && (
                              <span>
                                (
                                <Moment
                                  diff={new Date()}
                                  unit={"days"}
                                  add={{ hours: 24 }}
                                >
                                  {apply.dueDate}
                                </Moment>
                                일 남음)
                              </span>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pr_bt_section">
                      <button className="default_bt">
                        <Link to={`/mypage/project/apply/detail/${apply.id}`}>
                          상세보기
                        </Link>
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          {!lastPage && applies.length !== 0 && (
            <div className={"more_page_bt_section"}>
              <button className={"default_bt more_page_bt"} onClick={pageMore}>
                더보기
              </button>
            </div>
          )}
          {applies.length === 0 && (
            <div className={"no_recruit_contents"}>
              해당 조건에 일치하는 지원글이 없습니다.
            </div>
          )}
        </div>
      </div>
*/
