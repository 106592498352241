import React from "react";
import MySideMenu from "../../component/mySideMenu";

function ProjectApplyHistory(props) {
  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <div className="page_title">업체지원 선정완료</div>
          <div className="apply_select_complete">
            <div className="asc_title">
              업체(개인)선정이 완료되었습니다.
              <br />
              시일내(영업일기준 1~2내)로 프로젝트 담당자가 따로 연락을
              드리겠습니다
            </div>
            <div className="asc_num">프로젝트 No - 1973293</div>
          </div>

          <div className="div_line"></div>
          <div className="info_item">
            <div className="info_title">프로젝트</div>
            <div className="info_contents">판촉물 패키지 제안해주세요</div>
          </div>
          <div className="info_item">
            <div className="info_title">프로젝트 No</div>
            <div className="info_contents">1973293</div>
          </div>
          <div className="info_item">
            <div className="info_title">업무분야</div>
            <div className="info_contents">디자인/인쇄제작</div>
          </div>
          <div className="info_item">
            <div className="info_title">업체(개인)이름</div>
            <div className="info_contents">와이낫링크</div>
          </div>
          <div className="info_item">
            <div className="info_title">제안금액</div>
            <div className="info_contents">140만원(VAT별도)</div>
          </div>
          <div className="info_item">
            <div className="info_title">납기일</div>
            <div className="info_contents">2020-06-34까지</div>
          </div>
          <div className="info_item full_item">
            <div className="info_title">제안내역</div>
            <div className="info_contents">
              빠르게 제작 가능합니다
              <br />
              지속적인 성장 파트너로 함께 할 것을 약속 드립니다.
            </div>
          </div>

          <div className="bottom_bt_section multi_bt">
            <button className="default_bt prev_bt">목록으로</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjectApplyHistory;
