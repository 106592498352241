import React, { useState, useEffect } from "react";
import axios from "axios";
import MySideMenu from "../../component/mySideMenu";
import { Link, useParams, useHistory } from "react-router-dom";
import { moneyFormat } from "../../utils/commonApi";

function ProjectApplyDetail(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [project, setProject] = useState({});
  const [expertInfo, setExpertInfo] = useState({});
  const [portfolios, setPortfolios] = useState([]);
  const [apply, setApply] = useState({});
  const [applyFiles, setApplyFiles] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getApply", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //console.log("res", response);
          setProject(response.data.project);
          setExpertInfo(response.data.expertInfo);
          setPortfolios([...response.data.portfolios]);
          setApply(response.data.apply);
          setApplyFiles([...response.data.apply.file]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectApply = () => {
    if (window.confirm("해당 지원자를 선정하시겠습니까?")) {
      axios
        .get("/rest-api/selectApply", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            projectType: apply.projectType,
            projectId: project.id,
            applyId: apply.id,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            alert(res.data.message);
            history.push("/mypage/project/apply");
          } else alert(res.data.error);
        });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        <MySideMenu />
        <div className="mypage_contents">
          <div className="page_title">업체지원내역</div>
          <div className="info_item">
            <div className="info_title">프로젝트</div>
            <div className="info_contents">{project.title}</div>
          </div>
          <div className="info_item">
            <div className="info_title">프로젝트 No</div>
            <div className="info_contents">{project.projectId}</div>
          </div>
          <div className="info_item">
            <div className="info_title">업무분야</div>
            <div className="info_contents">
              {project.category &&
                project.category.split(",").map((item, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && "/"}
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="div_line"></div>
          <div className="info_item">
            <div className="info_title">업체(개인)이름</div>
            <div className="info_contents">{expertInfo.companyName}</div>
          </div>
          <div className="info_item">
            <div className="info_title">진행상태</div>
            <div className="info_contents">{apply.status}</div>
          </div>
          <div className="info_item full_item">
            <div className="info_title">회사소개</div>
            <div className="info_contents">
              {expertInfo.content &&
                expertInfo.content.split("\n").map((item, index) => {
                  return (
                    <span key={index}>
                      {item}
                      <br />
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="info_title section2_title">포트폴리오</div>
          <div className="portfolio_items">
            {portfolios.length === 0 && (
              <div
                className={"no_recruit_contents"}
                style={{ paddingTop: 20 + "px", paddingBottom: 20 + "px" }}
              >
                등록된 전문가 포트폴리오가 없습니다.
              </div>
            )}
            {portfolios.length > 0 &&
              portfolios.map((pf, index) => {
                const imgTypes = ["png", "jpg", "jpge"];
                let temp = null;
                let src = "/img/ic_file.svg";
                if (pf.file.length > 0) {
                  temp = pf.file[0].originalFileName.split(".");
                  if (imgTypes.indexOf(temp[temp.length - 1]) >= 0)
                    src = pf.file[0].url;
                }
                return (
                  <div className="pp_item" key={index}>
                    <div className="pp_img">
                      <img
                        src={src}
                        className="default_file_img"
                        style={{ maxHeight: 90 + "%", maxWidth: 90 + "%" }}
                        alt={"default_file_img"}
                      />
                    </div>
                    <div className="pp_contents">
                      <div className="pp_title">{pf.title}</div>
                      <div className="pp_subinfo">
                        {pf.category}/{pf.date}
                      </div>
                      <div className="pp_desc">
                        {pf.content.length > 75
                          ? pf.content.slice(0, 75)
                          : pf.content}
                      </div>
                      {pf.file.length > 0 && (
                        <div className="pp_manage_bt_section">
                          <a
                            className="pp_modify_bt"
                            href={pf.file[0].url}
                            download={pf.file[0].originalFileName}
                            title={"WNL PFno." + pf.id + "_attachFile"}
                          >
                            파일다운
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="div_line"></div>
          <div className="info_item">
            <div className="info_title">제안금액</div>
            <div className="info_contents">
              {moneyFormat(apply.cost)}만원(VAT별도)
            </div>
          </div>
          <div className="info_item">
            <div className="info_title">납기일</div>
            <div className="info_contents">{apply.dueDate}까지</div>
          </div>
          <div className="info_item full_item">
            <div className="info_title">제안내역</div>
            <div className="info_contents">
              {apply.content &&
                apply.content.split("\n").map((item, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && <br />}
                      {item}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="info_item">
            <div className="info_title">첨부파일</div>
            <div className="info_contents">
              {applyFiles.length === 0 && <span>첨부파일이 없습니다.</span>}
              {applyFiles.length > 0 &&
                applyFiles.map((file, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && <br />}
                      {
                        <a href={file.url} download={file.originalFileName}>
                          {file.originalFileName}
                        </a>
                      }
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="bottom_bt_section multi_bt">
            <button
              className="default_bt prev_bt"
              onClick={() => {
                history.goBack();
              }}
            >
              뒤로가기
            </button>
            {apply.status === "지원중" && project.status === "모집중" && (
              <button className="default_bt submit_bt" onClick={selectApply}>
                선정하기
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjectApplyDetail;
