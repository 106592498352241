import React, { useState, useEffect, useRef } from "react";
import * as baseActions from "../../store/base";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import axios from "axios";
import geoInfo from "../../geoInfo/sigungu";
import ProCate from "../../component/proCate";
import { useHistory } from "react-router-dom";
import FileInput from "../../component/fileInput";
import { useDispatch } from "react-redux";
import { moneyFormat } from "../../utils/commonApi";

function RecruitRegister(props) {
  const [recruitEndDate, setRecruitEndDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm();
  const [geoSelected, setGeoSelected] = useState(geoInfo[0]);
  const token = localStorage.getItem("token");
  const [proCateItems, setProCateItems] = useState([]);
  const [isRegist, setIsRegist] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요한 페이지 입니다.");
      history.push({
        pathname: "/login",
      });
    }
    axios
      .get("/rest-api/getClientCategory", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (result) {
        setProCateItems(result.data.category);
      });
  }, [history, token]);
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };
  const geoChange = (e) => {
    // console.log("target.value: ", e.target.value);
    const selectGeo = geoInfo.filter((item) => item.name === e.target.value);
    // console.log("selectGeo: ", selectGeo[0]);
    setGeoSelected(selectGeo[0]);
  };
  const projectSubmit = (data) => {
    const selectCate = proCateItems.reduce((acc, item) => {
      if (item.value === true) acc.push(item.idx);
      return acc;
    }, []);

    axios
      .post(
        "/rest-api/postProject",
        {
          title: data.title,
          companyName: data.company || "비공개",
          cost: data.price,
          region: data.geo1 + " " + data.geo2,
          applyDueDate: recruitEndDate,
          dueDate: dueDate,
          category: watch("whnManage") === true ? ["매니징"] : [...selectCate],
          content: data.contents,
          file: files,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        if (response.data.code === 200) {
          alert("프로젝트 등록 요청이 성공하였습니다.");
          setIsRegist(true);
          axios
            .get("/rest-api/getUser", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((response) => {
              dispatch(baseActions.setUserInfo(response.data.user));
            });
        }
      });
  };
  const proCateChange = (item) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        return cate.name === item.name ? { ...item, value: !item.value } : cate;
      })
    );
  };

  return (
    <div className="contents_wrap">
      {isRegist === true && (
        <div className={"div_page"}>
          <div className="page_title">프로젝트 요청완료</div>
          <div className={"page_contents"}>
            <div className={"regi_ing"}>
              프로젝트 등록 요청이 완료되었습니다.
              <div className={"info_desc"}>
                프로젝트 업무 검토는 영업일기준 1~3일정도 소요됩니다.
                <br />
                프로젝트는 매니저 검토 후 자동으로 게시됩니다.
              </div>
            </div>
          </div>
        </div>
      )}
      {isRegist === false && (
        <div className="div_page">
          <form onSubmit={handleSubmit(projectSubmit)}>
            <div className="page_title">프로젝트 등록</div>
            <div className="page_contents project_register">
              <div className="input_item wide_item">
                <div className="input_title">요청제목</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="요청 내용을 간략히 입력해주세요."
                    name={"title"}
                    {...register("title", {
                      required: "프로젝트 제목을 입력해주세요",
                    })}
                  />
                  {errors?.title && (
                    <div className={"error_msg"}>{errors?.title?.message}</div>
                  )}
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">회사이름</div>
                <div className="input_contents">
                  <span className="on_off_bt">
                    <label>
                      <input
                        type="checkbox"
                        className="check_toggle"
                        name="companySecret"
                        {...register("companySecret")}
                      />
                      <span className="toggle_bt"></span>
                    </label>
                  </span>
                  <span className="on_off_value">비공개</span>
                  <div>
                    {!watch("companySecret") && (
                      <input
                        type="text"
                        placeholder="회사명을 입력해주세요"
                        name="company"
                        {...register("company")}
                        onChange={() => {
                          if (watch("company") !== "" || watch("companySecret"))
                            clearErrors("company");
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">총예산</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="희망예산"
                    name={"price"}
                    {...register("price", {
                      required: "희망예산을 입력해주세요",
                    })}
                  />
                  만원
                  {errors?.price && (
                    <div className={"error_msg"}>{errors?.price?.message}</div>
                  )}
                  <span className="input_desc">
                    (부가세 별도 금액입니다. 실제 결제금액은 + 10% ={" "}
                    {moneyFormat(watch("price") * 1.1)}만원){" "}
                  </span>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">납품지역</div>
                <div className="input_contents">
                  <select
                    name="geo1"
                    {...register("geo1")}
                    onChange={geoChange}
                  >
                    {geoInfo.map((item, index) => (
                      <option key={index}>{item.name}</option>
                    ))}
                  </select>
                  <select name="geo2" {...register("geo2")}>
                    {geoSelected.gugun.map((item, index2) => (
                      <option key={index2}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input_item date_item">
                <div className="input_title">모집마감일</div>
                <div className="input_contents">
                  <DatePicker
                    locale="ko"
                    dateFormat="yyyy/MM/dd(eee)"
                    selected={recruitEndDate}
                    onChange={(date) => {
                      setRecruitEndDate(date);
                    }}
                  />
                </div>
              </div>
              <div className="input_item date_item">
                <div className="input_title">최종납기일</div>
                <div className="input_contents">
                  <DatePicker
                    locale="ko"
                    dateFormat="yyyy/MM/dd(eee)"
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                  />
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">업무분야</div>
                <div className="input_contents">
                  <span className="on_off_bt">
                    <label>
                      <input
                        type="checkbox"
                        className="check_toggle"
                        name="whnManage"
                        {...register("whnManage")}
                      />
                      <span className="toggle_bt"></span>
                    </label>
                  </span>
                  <span className="on_off_value">와이낫링크 매니징</span>
                  <span className="input_desc">
                    와이낫링크에서 알아서 필요한 부분을 업무세부분야를
                    정해줍니다.{" "}
                  </span>
                  {!watch("whnManage") && (
                    <ProCate
                      proItems={proCateItems}
                      proChange={proCateChange}
                    ></ProCate>
                  )}
                </div>
              </div>
              <div className="input_item full_item">
                <div className="input_title">세부내용</div>
                <div className="input_contents">
                  <textarea
                    name={"contents"}
                    {...register("contents", {
                      required: "세부내용을 작성해주세요",
                    })}
                    placeholder="요청 내용을 자유롭게 작성해주세요. 목적, 의뢰범위, 사양, 수량 등 자세히 적을수록 매칭될 확률이 높습니다. 작성이 어려울 경우 '상담요청'이라고 기입해주세요. 저희 담당매니저가 전화로 자세히 안내해드리겠습니다."
                  ></textarea>
                  {errors?.contents && (
                    <div className={"error_msg"}>
                      {errors?.contents?.message}
                    </div>
                  )}
                </div>
              </div>
              <FileInput
                titleYN={true}
                files={files}
                fileChange={fileChange}
                fileX={fileX}
              />
            </div>
            <div className="page_bottom">
              <div className="submit_desc">
                * 프로젝트등록은 작성 후 업무일 기준 1~2일의 심사가 승인되어야
                등록이 가능합니다.
              </div>
              <button className="default_bt submit_bt" type={"submit"}>
                등록하기
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default RecruitRegister;
