import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import FileInput from "../../component/fileInput";

export const NoticeEdit = (props) => {
  const [files, setFiles] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm(); //, watch
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { editType, id } = useParams();
  // const [oldNotice, setOldNotice] = useState(null);
  const [notice, setNotice] = useState({
    title: "",
    content: "",
    viewCount: 0,
  });

  const getOldNoviceInfo = () => {
    const oldNotice = props.location.state.oldNotice;
    setValue("title", oldNotice.title);
    setNotice(oldNotice);
    setFiles(oldNotice.files);
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요한 페이지 입니다.");
      history.push({
        pathname: "/login",
      });
    } else {
      if (editType === "modify") {
        getOldNoviceInfo();
      }
    }
  });

  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };

  const noticeSubmit = (data) => {
    data.files = files;
    if (window.confirm("이대로 작성하시겠습니까?")) {
      axios
        .post(
          "/rest-api/editNotice",
          {
            editType,
            noticeId: id,
            noticeData: data,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert(res.data.message);
            history.push("/notice");
          } else alert(res.data.error);
        });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <form onSubmit={handleSubmit(noticeSubmit)}>
          <div className="page_title">
            공지사항 {editType === "new" ? "신규 작성" : "수정 등록"}
          </div>
          <div className="page_contents project_register">
            <div className="input_item wide_item">
              <div className="input_title">제목</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="요청 내용을 간략히 입력해주세요."
                  name={"title"}
                  {...register("title", {
                    required: "공지사항 제목을 입력해주세요",
                  })}
                />
                {errors?.title && (
                  <div className={"error_msg"}>{errors?.title?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">세부내용</div>
              <div className="input_contents">
                <textarea
                  name={"content"}
                  {...register("content", {
                    required: "세부내용을 작성해주세요",
                  })}
                  placeholder="공지사항 내용을 입력해주세요."
                  defaultValue={notice.content}
                ></textarea>
                {errors?.content && (
                  <div className={"error_msg"}>{errors?.content?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <FileInput
                titleYN={files.length >= 0 ? true : false}
                files={files}
                fileChange={fileChange}
                fileX={fileX}
              />
            </div>
          </div>
          <div className="page_bottom">
            <button
              className="default_bt prev_bt"
              type={"button"}
              onClick={() => history.goBack()}
            >
              작성취소
            </button>
            <button className="default_bt submit_bt" type={"submit"}>
              등록하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default NoticeEdit;
