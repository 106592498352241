import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";

function RecruitCheck(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [projectInfo, setProjectInfo] = useState({});
  const [mainPjFiles, setMainPjFiles] = useState([]);
  const [subProjectInfo, setSubProjectInfo] = useState({});
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getProject", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      })
      .then(function (result) {
        const data = result.data;
        //console.log("result: ", data);
        //console.log("mainFiles: ", data.project.file);
        if (result.status === 200) {
          setProjectInfo(data.project);
          setMainPjFiles(data.project.file);
          setSubProjectInfo(data.subProjects);
        } else alert(data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prDelete = () => {
    if (window.confirm("해당 프로젝트 삭제 요청을 승인하시겠습니까?")) {
      axios
        .get("/rest-api/deleteprojectConfirm", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            projectId: Number(id),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            alert(res.data.message);
            history.push("/admin/requestList");
          } else alert(res.data.error);
        });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">
          프로젝트: {projectInfo.projectId}의 {projectInfo.status}
        </div>
        <div className="recruit_header">
          <div className="rc_title">
            {projectInfo.title}{" "}
            <span className="rc_time">
              <Moment fromNow>{projectInfo.createdAt}</Moment>
            </span>
          </div>
          <div className="rc_option_items">
            <div className="info_item">
              <div className="info_title">업체명</div>
              <div className="info_contents">{projectInfo.companyName}</div>
            </div>
            <div className="info_item">
              <div className="info_title">프로젝트 총 예산</div>
              <div className="info_contents">{projectInfo.cost}만원</div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납기일</div>
              <div className="info_contents">{projectInfo.dueDate}</div>
            </div>
            <div className="info_item">
              <div className="info_title">모집마감</div>
              <div className="info_contents">
                {projectInfo.applyDueDate}({projectInfo.status})
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">모집업무</div>
              <div className="info_contents">
                {projectInfo.category &&
                  projectInfo.category.map((item, idx) => {
                    return (
                      <span key={idx}>
                        {idx > 0 && ", "}
                        {item.name}
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납품지역</div>
              <div className="info_contents">{projectInfo.region}</div>
            </div>
          </div>
          <div className="rc_option_items attachFiles">
            <div className="info_item">
              <div className="info_title">첨부파일</div>
              <div className="info_contents">
                {mainPjFiles.length === 0 && <span>첨부파일이 없습니다.</span>}
                {mainPjFiles.length > 0 &&
                  mainPjFiles.map((file, index) => {
                    return (
                      <span key={index}>
                        {index !== 0 && <br />}
                        <a href={file.url} download={file.originalFileName}>
                          {file.originalFileName}
                        </a>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="recruit_summary">
          <div className="info_title">업무개요</div>
          <div className="rcs_contents">
            {projectInfo.content &&
              projectInfo.content.split("\n").map((item, idx) => {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </div>
        </div>
        <div className="recruit_department">
          <div className="info_title">업무분야 상세</div>
          <div>
            {Array.from(subProjectInfo).map((subProject, index) => {
              return (
                <div className="rcd_item" key={index}>
                  <div className="rcd_title">{subProject.mainCategory}</div>
                  <div className="rcd_contents">
                    <div className="rcd_detail">{subProject.content}</div>
                    <div className="rcd_option">
                      <span>희망금액 {subProject.cost}만원/ </span>
                      <span>납기일 {subProject.nextDueDate}/ </span>
                      <span>납품지역 {subProject.nextRegion.region}</span>
                    </div>
                    <div className="rcd_state">
                      {subProject.status}(지원자 {subProject.applicant}명)
                    </div>
                  </div>
                </div>
              );
            })}
            {Array.from(subProjectInfo).length === 0 && (
              <div className={"no_recruit_contents"}>
                하위 업무 분할이 적용되지 않았습니다.
              </div>
            )}
          </div>
          <div className="apply_section">
            {projectInfo.status === "삭제요청" && (
              <button
                className="default_bt apply_bt"
                style={{ backgroundColor: "#f15839" }}
                onClick={prDelete}
              >
                삭제승인
              </button>
            )}
            <Link to={`/admin/recruit/register/${projectInfo.id}`}>
              <button className="default_bt apply_bt">작성/수정하기</button>
            </Link>
            <Link to={"/admin/recruitList"}>
              <button className="default_bt prev_bt">목록으로</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RecruitCheck;
