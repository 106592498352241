import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import XLSX from "xlsx";
import Spreadsheet from "react-spreadsheet";

function ExpertManage(props) {
  const token = localStorage.getItem("token");
  const userInfo = useSelector((state) => state.userInfo);
  const history = useHistory();
  const [cateList, setCateList] = useState([]);
  const [currentCate, setCurrentCate] = useState("");
  const [exList, setExList] = useState([]);
  const [data, setData] = useState([
    [{ value: "Vanilla" }, { value: "Chocolate" }],
    [{ value: "Strawberry" }, { value: "Cookies" }],
  ]);

  useEffect(() => {
    // if (userInfo.userLevel !== "manager") {
    //   alert("매니저만 접근 가능합니다!");
    //   history.push("/");
    // }
  });

  const changeData = (e) => {
    console.log(e);
  };

  const downExcel = async () => {
    try {
      if (
        window.confirm(
          "서버 전문가(수집) 리스트를 파일로 다운로드 하시겠습니까?"
        )
      ) {
        const res = await axios.get("/rest-api/getCoExInfos", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        console.log(res.data);
        const fileName = `${new Date()
          .toISOString()
          .split("T")[0]
          .replaceAll("-", "")}_CoExInfoList.xlsx`;
        const exList = res.data.exList;
        let list = [];
        list.push(Object.keys(exList[0]));
        for (const ex of exList) list.push(Object.values(ex));
        writeExcel(list, fileName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewData = () => {
    let tempData = [];
    // if(exList.length===0){
    //   if(window.confirm("불러온 파일 내용이 없습니다. 기존 데이터를 서버에서 받아 표기합니다")){

    //   }
    // }

    tempData.push(
      Object.keys(exList[0]).map((ex) => {
        return { value: ex };
      })
    );
    for (const ex of exList) {
      tempData.push(
        Object.values(ex).map((value) => {
          return { value };
        })
      );
    }
    setData(tempData);
  };

  const setDataToFile = (rows) => {
    let newFAQs = [];
    if (!rows || !rows.length) return 0;
    else {
      setExList(rows);
      setCateList(
        rows.reduce((acc, fa) => {
          fa.category.split("/").map((cate) => {
            if (acc.indexOf(cate) < 0) acc.push(cate);
            return 0;
          });
          return acc;
        }, [])
      );
    }
  };

  const writeExcel = async (data, fileName) => {
    try {
      /* convert AOA back to worksheet */
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      /* build new workbook */
      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, fileName);

      /* write file */
      XLSX.writeFile(new_workbook, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const fileRead = (event) => {
    let input = event.target;
    let reader = new FileReader();
    event.target.nextSibling.innerText = event.target.files[0].name;

    reader.onload = () => {
      let data = reader.result;
      let workBook = XLSX.read(data, { type: "binary" });

      workBook.SheetNames.forEach(function (sheetName) {
        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
        setDataToFile(rows);
      });
    };
    reader.readAsBinaryString(input.files[0]);
  };

  const cateSelect = (e) => {
    setCurrentCate(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      console.log("exList: ", exList);
      if (exList.length > 50) {
        let reqList = [];
        let resList = [];
        let result = {
          success: 0,
          fail: 0,
        };
        for (let i = 0; i < exList.length; i = i + 50) {
          reqList.push({ exList: exList.slice(i, i + 50) });
        }
        for (const req of reqList) {
          const res = await axios.post(
            "/rest-api/updateCoExInfo",
            {
              exList: req.exList,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          resList.push({
            status: res.status,
            data: res.data,
          });
          result = {
            success: result.success + res.data.result.success,
            fail: result.fail + res.data.result.fail,
          };
        }
        window.alert(
          `수집 전문가 업데이트(성공:${result.success}개/실패:${result.fail})가 적용되었습니다.`
        );
      } else {
        const res = await axios.post(
          "/rest-api/updateCoExInfo",
          {
            exList,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.status === 200)
          window.alert(`수집 전문가 ${res.data.count}개가 적용되었습니다.`);
        else window.alert("수집 전문가 파일 수정 적용이 실패하였습니다.");
      }
    } catch (error) {
      window.alert("수집 전문가 파일 수정 적용이 실패하였습니다.");
      console.log(error);
    }
  };

  return (
    <div className="contents_wrap for_excel">
      <div className="div_page">
        <div className="page_title exMenage_up">
          <span>전문가 관리</span>
          <span className="goBack" onClick={() => history.goBack()}>
            ◁ 관리자메인
          </span>
        </div>
        <div
          className="input_item file_item"
          style={{
            paddingTop: "20px",
            paddingLeft: "20px",
            margin: "0",
          }}
        >
          <div className={"input_title"}>파일첨부</div>
          <div className="input_contents">
            <div className="file_input">
              <label>
                <input type="file" onChange={fileRead} id={"fileInputFAQ"} />
                <div className="file_text">
                  jpg/jpeg/png/ppt/doc/docx/hwp/pdf/zip
                </div>
                <div className="file_bt">파일찾기</div>
              </label>
              <label>
                <button
                  className="file_bt"
                  onClick={viewData}
                  style={{ backgroundColor: "#181818" }}
                >
                  내용표시
                </button>
              </label>
              <label>
                <button
                  className="file_bt"
                  onClick={handleUpdate}
                  style={{ backgroundColor: "#2f2f96" }}
                >
                  업데이트
                </button>
              </label>
              <label>
                <button
                  className="file_bt"
                  onClick={downExcel}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "solid black 1px",
                  }}
                >
                  엑셀다운
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="div_page spread_sheet">
        <Spreadsheet data={data} onChange={changeData} />
      </div>
    </div>
  );
}
export default ExpertManage;
