import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import { Link, useLocation, useHistory } from "react-router-dom";
import useHistoryState from "use-history-state";

function ProjectAdmin(props) {
  let token = localStorage.getItem("token");
  const history = useHistory();
  const [recruits, setRecruits] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [sortBy, setSortBy] = useHistoryState("createdAt", "sortBy");
  const [cate, setCate] = useHistoryState([], "cate");
  const [recruitState, setRecruitState] = useHistoryState(
    ["recruit", "processing", "end"],
    "recruitState"
  );
  const pageAmount = 15;
  const location = useLocation();
  const [mobileFilterView, setMobileFilterView] = useState(false);
  const searchKey = decodeURI(
    decodeURIComponent(location.search.substr(8, location.search.length))
  );
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [frontOp, setFrontOp] = useState("all");
  const dueHours = 21;
  useEffect(() => {
    //console.log("status: ", recruitState);
    axios
      .get("/rest-api/getAdminProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          categories: cate,
          sortBy: sortBy,
          status: recruitState,
          amount: pageAmount,
          searchKey: searchKey,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          // console.log("ToServer: ", response.data);
          setRecruits([...response.data.projects]);
          setViewData([...response.data.projects]);
          // frontOpCheck({ target: { value: "all" } });
          if (response.data.totalProjectCount <= pageAmount * pageNum)
            setLastPage(true);
          setPageNum(response.data.nextIndex);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const frontOpCheck = (e) => {
    const type = e.target.value;
    let viewList = [];
    console.log("viewData: ", viewData);
    setFrontOp(type);
    switch (type) {
      case "sub":
        viewList = recruits
          .filter((rc) => rc.subProjects.length > 0)
          .map((rc) => rc);
        break;
      case "main":
        viewList = recruits
          .filter((rc) => rc.subProjects.length === 0)
          .map((rc) => rc);
        break;
      default:
        viewList = recruits;
    }
    console.log("viewList: ", viewList);
    setViewData(viewList);
  };

  const pageMore = () => {
    axios
      .get("/rest-api/getAdminProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          categories: cate,
          sortBy: sortBy,
          status: recruitState,
          amount: pageAmount,
          searchKey: searchKey,
          index: pageNum,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          // console.log("ToServer: ", response.data);
          setRecruits([...recruits, ...response.data.projects]);
          if (response.data.totalProjectCount <= pageAmount * pageNum)
            setLastPage(true);
          else setLastPage(false);
          setPageNum(response.data.nextIndex);
          frontOpCheck({ target: { value: frontOp } });
        } else if (response.status === 202) {
          console.log("lastPage");
          setLastPage(true);
        }
      });
  };

  const cateCheck = (e) => {
    let cateTemp = [];
    if (e.target.checked) {
      cateTemp = [...cate, e.target.name];
    } else {
      cateTemp = cate.filter((item) => item !== e.target.name);
    }
    setCate(cateTemp);
    axios
      .get("/rest-api/getAdminProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          categories: cateTemp,
          sortBy: sortBy,
          status: recruitState,
          amount: pageAmount,
          searchKey: searchKey,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setRecruits([...response.data.projects]);
          frontOpCheck({ target: { value: frontOp } });
        }
      });
  };
  const recruitStateCheck = (e) => {
    let stateTemp = recruitState;
    //console.log(e.target.name, stateTemp, stateTemp.indexOf(e.target.name));
    // if (e.target.checked) {
    //   stateTemp = [...cate, e.target.name];
    // } else {
    //   stateTemp = cate.filter((item) => item !== e.target.name);
    // }
    if (stateTemp.indexOf(e.target.name) === -1) stateTemp.push(e.target.name);
    else stateTemp.splice(stateTemp.indexOf(e.target.name), 1);
    if (stateTemp.length === 0) stateTemp.push("recruit");
    setRecruitState(stateTemp);
    axios
      .get("/rest-api/getAdminProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          categories: cate,
          sortBy: sortBy,
          status: stateTemp,
          amount: pageAmount * pageNum,
          searchKey: searchKey,
        },
      })
      .then(function (result) {
        console.log(result.data);
        setRecruits([...result.data.projects]);
        if (result.data.totalProjectCount <= pageAmount * pageNum)
          setLastPage(true);
        else setLastPage(false);
        setPageNum(result.data.nextIndex);
        frontOpCheck({ target: { value: frontOp } });
      });
  };

  const sortClick = (order) => {
    setSortBy(order);
    axios
      .get("/rest-api/getAdminProjects", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          categories: cate,
          sortBy: order,
          status: recruitState,
          amount: pageAmount,
          searchKey: searchKey,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setRecruits(response.data.projects);
          frontOpCheck({ target: { value: frontOp } });
        }
      });
  };
  return (
    <div className="contents_wrap">
      <div className="recruit_section">
        <div
          className={
            "mobile_filter_section" + (mobileFilterView ? " active" : "")
          }
        >
          <div
            className={"filter_open_bt"}
            onClick={() => {
              setMobileFilterView(!mobileFilterView);
            }}
          >
            필터{mobileFilterView ? "닫기" : "보기"}
          </div>
          <div className={"select_order"}>
            <select
              value={sortBy}
              onChange={(e) => {
                sortClick(e.target.value);
              }}
            >
              <option value={"createdAt"}>최신등록순</option>
              <option value={"offerCost"}>금액높은순</option>
              <option value={"applyDueDate"}>마감임박순</option>
              <option value={"applicant"}>지원자낮은순</option>
            </select>
          </div>
        </div>
        <div
          className={
            "cate_section" +
            (mobileFilterView ? " mobile_active" : "") +
            " adminCase"
          }
        >
          <div className="cate_title">업무영역</div>
          <div className="cate_items">
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"design"}
                  checked={cate.indexOf("design") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                디자인
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"print"}
                  checked={cate.indexOf("print") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                인쇄제작
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"silsa"}
                  checked={cate.indexOf("silsa") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                실사제작
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"otherPrint"}
                  checked={cate.indexOf("otherPrint") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                기타제작
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"other"}
                  checked={cate.indexOf("other") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                기타
              </label>
            </div>
          </div>
          <div className="cate_title">상태</div>
          <div className="cate_items">
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"recruit"}
                  checked={recruitState.indexOf("recruit") >= 0 ? true : false}
                  onChange={recruitStateCheck}
                />{" "}
                모집중
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"processing"}
                  checked={
                    recruitState.indexOf("processing") >= 0 ? true : false
                  }
                  onChange={recruitStateCheck}
                />{" "}
                업무진행중
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"end"}
                  checked={recruitState.indexOf("end") >= 0 ? true : false}
                  onChange={recruitStateCheck}
                />{" "}
                업무종료
              </label>
            </div>
          </div>
          <div className="cate_title">분할공정관리</div>
          <div className="cate_items">
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"frontOp_all"}
                  checked={frontOp === "all" ? true : false}
                  value={"all"}
                  onChange={frontOpCheck}
                />{" "}
                전체
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"frontOp_sub"}
                  checked={frontOp === "sub" ? true : false}
                  value={"sub"}
                  onChange={frontOpCheck}
                />{" "}
                분할공정
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"frontOp_main"}
                  checked={frontOp === "main" ? true : false}
                  value={"main"}
                  onChange={frontOpCheck}
                />{" "}
                통합수행
              </label>
            </div>
          </div>
        </div>
        <div className="recruit_items">
          <div className="order_section">
            {searchKey && (
              <div className={"search_desc"}>
                <span className={"search_key"}>{searchKey}</span> 을(를) 검색한
                검색결과입니다({viewData.length})
              </div>
            )}
            {!searchKey && (
              <ul>
                <li
                  className={sortBy === "createdAt" ? "active" : ""}
                  onClick={() => {
                    sortClick("createdAt");
                  }}
                >
                  최신등록순
                </li>
                <li
                  className={sortBy === "offerCost" ? "active" : ""}
                  onClick={() => {
                    sortClick("offerCost");
                  }}
                >
                  금액높은순
                </li>
                <li
                  className={sortBy === "applyDueDate" ? "active" : ""}
                  onClick={() => sortClick("applyDueDate")}
                >
                  마감임박순
                </li>
                <li
                  className={sortBy === "applicant" ? "active" : ""}
                  onClick={() => {
                    sortClick("applicant");
                  }}
                >
                  지원자낮은순
                </li>
              </ul>
            )}
          </div>
          {viewData.map((rc, index) => {
            const now = moment().tz("Asia/Seoul");
            const applyDueDate = moment(rc.applyDueDate)
              .tz("Asia/Seoul")
              .add(dueHours, "hours");
            const dueDate = moment(rc.dueDate)
              .tz("Asia/Seoul")
              .add(dueHours, "hours");
            let info = {
              mainApply: rc.applicant,
              subProjectCount: rc.subProjects.length,
              subProjects: [],
              requiredManage: false,
            };
            info.subProjects = rc.subProjects.map((sub, idx) => {
              if (sub.alertIndex === 0) info.requiredManage = true;
              return {
                applicant: sub.applicant,
                alertIndex: sub.alertIndex,
                status: sub.status,
              };
            });

            return (
              <div
                className={
                  "recruit_item" +
                  (rc.status === "업무종료" || rc.status === "진행중"
                    ? " not_active"
                    : "")
                }
                key={rc.id}
                // onClick={() => {
                //    history.push("/recruit/detail/" + rc.id);
                //   // if (token)else if (
                //   //   window.confirm(
                //   //     "로그인이 필요한 메뉴입니다. 로그인 페이지로 이동하시겠습니까?"
                //   //   )
                //   // )
                //   //   history.push("/login");
                // }}
              >
                <Link to={"/admin/recruit/detail/" + rc.id}>
                  <div className="rc_title">
                    {rc.subProjects.length === 0 ? "통합모집: " : "분할모집: "}
                    {rc.title}{" "}
                    <span className="rc_time">
                      {rc.createdAt.split("T")[0]}
                    </span>
                    {info.requiredManage && (
                      <span className="rc_noticeMark">{"알림발송필요"}</span>
                    )}
                  </div>
                  <div className="rc_cate">
                    <ul>
                      {rc.mainCategory.split(",").map((cate_item, index) => {
                        return <li key={index}>{cate_item}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="rc_option subInfo">
                    <ul>
                      <li>통합지원자:{" " + info.mainApply}명</li>
                      {rc.subProjects.length > 0 &&
                        rc.subProjects.map((sub, idx) => {
                          return (
                            <li>
                              {`공정#${idx + 1}: 지원 ${
                                sub.applicant
                              }명/ 알림 ${sub.alertIndex}회`}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="rc_option">
                    <ul>
                      <li>
                        총예산{" "}
                        {rc.cost !== "제안금액" ? rc.cost + "만원" : "제안금액"}
                      </li>
                      <li>
                        {rc.status}
                        {applyDueDate.diff(now, "minutes") > 0 && (
                          <span>
                            (
                            <Moment
                              diff={moment().tz("Asia/Seoul").format()}
                              unit={"days"}
                              tz="Asia/Seoul"
                            >
                              {applyDueDate}
                            </Moment>
                            일 남음)
                          </span>
                        )}
                      </li>
                      <li>
                        납기일 <Moment format="MM/DD">{rc.dueDate}</Moment>
                        {dueDate.diff(now, "minutes") > 0 && (
                          <span>
                            (
                            <Moment
                              diff={new Date()}
                              unit={"days"}
                              tz="Asia/Seoul"
                            >
                              {dueDate}
                            </Moment>
                            일 남음)
                          </span>
                        )}
                      </li>
                      <li>{rc.region}</li>
                    </ul>
                  </div>
                </Link>
              </div>
            );
          })}
          {!lastPage && recruits.length !== 0 && (
            <div className={"more_page_bt_section"}>
              <button className={"default_bt more_page_bt"} onClick={pageMore}>
                더보기
              </button>
            </div>
          )}
          {viewData.length === 0 && (
            <div className={"no_recruit_contents"}>
              해당 조건에 일치하는 모집이 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProjectAdmin;
