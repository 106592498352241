import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
//import FileInput from "../../component/fileInput";

export const FaqEdit = (props) => {
  //const [files, setFiles] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm(); //, watch
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { editType, id } = useParams();

  const getOldNoviceInfo = () => {
    const oldFaq = props.location.state.oldFaq;

    [
      { title: oldFaq.title },
      {
        category: oldFaq.categoryJSON.reduce((acc, cate, index) =>
          index === 0 ? cate : acc + ", " + cate
        ),
      },
      { content: oldFaq.content },
      { viewCount: oldFaq.viewCount },
    ].map((item) => setValue(Object.keys(item)[0], Object.values(item)[0]));

    //setFiles(oldFaq.files);
  };

  useEffect(() => {
    console.log(props);
    if (!token) {
      alert("로그인이 필요한 페이지 입니다.");
      history.push({
        pathname: "/login",
      });
    } else if (editType === "modify") getOldNoviceInfo();
  });

  // const fileChange = (e, index) => {
  //   [...e.target.files].map((file) => {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     axios
  //       .post("/rest-api/uploadFile", formData, {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then(function (result) {
  //         setFiles((files) => [...files, result.data]);
  //       })
  //       .catch((error) => {
  //         alert(
  //           `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
  //         );
  //       });
  //     return 0;
  //   });
  // };
  // const fileX = (index) => {
  //   setFiles(files.filter((file, i) => i !== index));
  //   document.getElementById("fileInput").value = null;
  // };

  const faqSubmit = (data) => {
    //data.files = files;
    const faqData = {
      title: data.title,
      content: data.content,
      category: data.category.replaceAll(" ", ""),
    };
    if (window.confirm("이대로 작성하시겠습니까?")) {
      axios
        .post(
          "/rest-api/editFaq",
          {
            editType,
            faqId: id,
            faqData,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            alert(res.data.message);
            history.push("/faq");
          } else alert(res.data.error);
        });
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <form onSubmit={handleSubmit(faqSubmit)}>
          <div className="page_title">
            FAQ {editType === "new" ? "신규 작성" : "수정 등록"}
          </div>
          <div className="page_contents project_register">
            <div className="input_item wide_item">
              <div className="input_title">질문</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="FAQ 질문을 간략히 입력해주세요."
                  name={"title"}
                  {...register("title", {
                    required: "FAQ 질문을 입력해주세요",
                  })}
                />
                {errors?.title && (
                  <div className={"error_msg"}>{errors?.title?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item wide_item">
              <div className="input_title">카테고리</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="카테고리를 입력해주세요."
                  name={"category"}
                  {...register("category", {
                    required: '카테고리를 입력해주세요.(없으면 "기타"로 작성)',
                  })}
                />
                {errors?.category && (
                  <div className={"error_msg"}>{errors?.category?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">답변내용</div>
              <div className="input_contents">
                <textarea
                  name={"content"}
                  {...register("content", {
                    required: "답변 내용을 작성해주세요",
                  })}
                  placeholder="답변 내용을 입력해주세요."
                ></textarea>
                {errors?.content && (
                  <div className={"error_msg"}>{errors?.content?.message}</div>
                )}
              </div>
            </div>
            {/* <div className="input_item">
              <FileInput
                titleYN={files.length >= 0 ? true : false}
                files={files}
                fileChange={fileChange}
                fileX={fileX}
              />
            </div> */}
          </div>
          <div className="page_bottom">
            <button
              className="default_bt prev_bt"
              type={"button"}
              onClick={() => history.goBack()}
            >
              작성취소
            </button>
            <button className="default_bt submit_bt" type={"submit"}>
              등록하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default FaqEdit;
