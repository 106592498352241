import React from "react";

function Terms(props) {
  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">이용약관</div>
        <div className={"page_contents terms_contents"}>
          제1조 목적
          <br />
          이 약관은 (주)와이낫엠앤씨(이하 “회사”라 합니다.)가 제공하는
          와이낫링크(www.whynotlink.co.kr) 서비스(이하 “서비스”라 합니다.)와{" "}
          <br />
          관련하여, 이용 고객과 회사간의 권리, 의무 및 책임사항, 서비스 이용조건
          및 절차 등 기본적인 사항을 규정함을 목적으로 합니다. 본 약관은 PC통신,
          스마트폰(안드로이드, 아이폰 등)앱 등을 이용하는 전자상거래에 대해서도
          그 성질에 반하지 않는 한 준용됩니다.
          <br />
          <br />
          제2조 용어의 정의
          <br />
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />
          1. 회원: 회사에 개인정보를 제공하여 회원 등록을 한 자로서, 회사의
          정보를 지속적으로 제공받으며 이 약관에 따라 회사와 서비스 이용 계약을
          체결한 자를 말합니다.
          <br />
          2. 사이트: 회사가 정보통신설비를 이용하여 다수의 회원간, 또는 회사와
          회원간 서비스 상품을 거래할 수 있도록 제공하는 가상의
          영업장(와이낫링크)을 말하며 추후 회사에서 공지하고 제공하는 기타
          웹사이트를 포함합니다.
          <br />
          3. 아이디: 회원의 식별과 서비스 이용을 위하여 회원이 선정하고 회사가
          승인한 회원의 전자우편주소를 말합니다.
          <br />
          4. 비밀번호: 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여
          회원 스스로가 설정하여 사이트에 등록한 영문자와 숫자, 특수문자 등의
          조합을 말합니다.
          <br />
          5. 포인트: 회사로부터 회원에게 지급하는 것으로, 회원이 서비스를
          이용함에 있어 결제수단 등으로 사용할 수 있는 회사의 전자적 지급수단을
          말합니다.
          <br />
          <br />
          본 조항에서 정의되지 않은 용어는 관현 법령이 정하는 바에 따르며, 그
          외에는 일반적인 상거래 관행에 의합니다.
          <br />
          <br />
          제 3조 약관의 명시, 설명과 개정
          <br />
          1. 회사는 이 약관의 내용과 회사의 상호, 영업소 소재지 주소, 대표자
          성명, 사업자등록번호, 연락처 등과 함께 회원이 확인할 수 있도록 사이트
          초기 서비스 화면(전면)에 게시합니다. 다만, 이 약관의 내용은 연결화면을
          통하여 볼 수 있습니다.
          <br />
          2. 회사는 필요하다고 인정하는 경우, 약관의 규제에 관한 법률, 전자문서
          및 전자거래기본법, 전자상거래 등에서의 소비자보호에 관한 법률,
          전자금융거래법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
          전자서명법, 소비자기본법 등 관련 법을 위배하지 않는 범위에서 본 약관을
          개정할 수 있습니다.
          <br />
          3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유 등을 명시하여
          현행 약관과 함께 사이트를 통해 그 적용일자 7일 이전부터 적용일자
          전일까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는
          경우에는 최소한 30일 이상의 사전 유예기간을 두고 회원이 알기 쉽도록
          표시하여 공지합니다.
          <br />
          4. 3항에 따라 공지된 적용일자 이후에 회원이 회사의 서비스를 계속
          이용하는 경우에는 개정된 약관에 동의하는 것으로 간주합니다. 개정된
          약관에 동의하지 아니하는 회원은 언제든지 자유롭게 서비스 이용계약을
          해지할 수 있습니다.
          <br />
          5. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가
          제정한 전자상거래 등에서의 소비자보호지침 및 관련법령 또는 상관례에
          따릅니다.
          <br />
          <br />
          제 4조 서비스 이용계약의 성립
          <br />
          1. 서비스 이용계약은 회원 등 이용자의 약관 동의, 이용신청, 회사의
          승낙에 의하여 성립됩니다.
          <br />
          2. 서비스 이용계약의 성립시기는 회사가 ‘가입완료’ 및 ‘결제완료’ 등의
          표시를 신청절차 상에서 표시한 시점으로 합니다.
          <br />
          3. 회원 등 서비스 이용자는 서비스 이용신청 계약을 체결하기 전에 해당
          서비스에 대하여 회사가 공지하는 사항(각 서비스 영역에서 안내하는
          서비스의 내용, 가격, 결제정보, 이용기간, 이용방법, 청약철회 및 해지
          조건 및 그 방법, 환불에 관한 사항, 결제방법에 필요한 결제정보의 기입
          등)을 사전에 숙지하고, 착오 없이 정확히 거래할 수 있도록 해야 합니다.
          <br />
          <br />
          제 5조 서비스 이용신청 및 승낙
          <br />
          1. 회원 등 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입하고
          이 약관에 동의한다는 의사표시를 함으로 회원가입을 신청합니다.
          <br />
          2. 회사는 전 항에 따른 이용신청 시 고객에게 전문기관을 통한 본인인증
          절차를 요청할 수 있습니다.
          <br />
          3. 회사는 다음 각 호에 해당하는 이용신청에 대하여는 이를 승낙하지
          아니하거나 사후에 서비스 이용 계약을 해지할 수 있으며, 사안의 경중에
          따라서는 손해배상을 청구할 수 있습니다.
          <br />
          ① 실명확인절차 과정에서 고객의 실명이 아님이 확인된 경우
          <br />
          ② 이미 가입된 회원과 이름 및 이메일 주소가 동일한 경우
          <br />
          ③ 타인의 명의를 도용하거나 기입내용에 허위, 기재누락, 오기가 있는 경우
          <br />
          ④ 회사에 의하여 이전에 회원자격을 상실한 적이 있는 경우(다만, 회원자격
          상실 후 회사의 회원 재이용 승낙을 얻은 경우에는 예외로 함)
          <br />
          ⑤ 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는
          경우
          <br />
          ⑥ 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는
          목적으로 신청한 경우
          <br />
          ⑦ 회사의 서비스 운영에 따른 설비 및 기술상 지장이 있는 경우
          <br />
          ⑧ 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우
          및 회사가 합리적인 판단에 의하여 이용신청 거절 또는 이용계약 해지가
          필요하다고 판단하는 경우
          <br />
          <br />
          제 6조 서비스 이용자 정보의 수정
          <br />
          회원 등 이용자는 가입신청 시 작성한 이용신청 사항에 변경이 있는 경우
          본 서비스 사이트에서 온라인으로 개인정보를 수정하거나 이메일 등
          기타방법으로 회사에게 그 변경 사항을 알려야 하며 이를 알리지 않아
          발생하는 불이익에 대한 책임은 이용자에게 있습니다.
          <br />
          <br />
          제 7조 서비스의 이용 개시 및 회원에 대한 통지
          <br />
          1. 회사는 회원의 이용신청을 승낙하고 회사가 지정하는 수단으로 결제가
          완료된 후부터 서비스를 개시하며, 회사의 업무상 또는 기술상의 장애
          등으로 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게
          이를 통지합니다.
          <br />
          2. 회원에 대한 통지를 하는 경우 회사는 회원이 등록한 이메일 및 SMS
          등을 통해 할 수 있으며 회원의 연락처 변경 등으로 인해 통지를 받지 못해
          발생하는 불이익에 대한 책임은 회원에게 있습니다.
          <br />
          <br />
          제 8조 서비스 이용 시간
          <br />
          서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기점검
          등 회사의 업무상 또는 기술상의 이유로 일시 중지 될 수 있습니다.
          <br />
          <br />
          제 9조 서비스의 변경 및 중지
          <br />
          1. 회사는 상당한 이유가 있는 경우 운영상, 기술상 필요에 따라 서비스를
          변경할 수 있으며, 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를
          일시적으로 제한하거나 중지할 수 있습니다.
          <br />
          ① 회원 등 이용자가 의도적으로 회사의 정상적 영업 및 서비스 제공 활동을
          방해하는 경우
          <br />
          ② 정전, 제반 설비의 장애 또는 이용량 폭주 등으로 정상적인 서비스
          이용에 지장이 있는 경우
          <br />
          ③ 회사의 제반 사정으로 서비스를 유지할 수 없는 경우
          <br />
          ④ 기타 천재지변, 국가비상사태, 방송통신위원회, 한국정보보호진흥원 등
          국가기관, 정부조직, 수사기관, 법원 등의 행정 및 사법 처분 등
          행정행위로 인한 서비스 중단 등 회사가 통제할 수 없는 불가항력적 사유가
          있는 경우
          <br />
          2. 제 1항에 의한 서비스 중단의 경우에는 회사가 제 3조(약관의 명시,
          설명과 개정) 제 3항에서 정한 방법과 동일 수준으로 호스트 및 회원 등
          이용자에게 통지합니다. 다만, 미리 통지하는 것이 곤란, 급박하거나
          불가피한 사정이 있는 경우에는 사후에 통지할 수 있습니다.
          <br />
          <br />
          제 10조 서비스의 제한
          <br />
          1. 회원은 언제든지 회사에게 해지의사를 통지함으로써 이용계약을 해지할
          수 있으며 서비스 이용 계약은 회사가 회원의 해지의사를 받아 해지
          처리하는 시점에 종료됩니다. (본 항에 따라 해지한 회원은 본 약관이
          정하는 회원가입절처 및 관련 조항에 따라 다시 회원으로 가입할 수 있음)
          <br />
          2. 회사는 다음과 같은 사유가 있는 경우 회원과의 이용계약을 해지할 수
          있으며 이 경우, 회사는 회원에게 이메일, 전화, SMS 등의 방법을 통하여
          해지 사유를 밝혀 해지 의사를 통보합니다. 다만, 불특정다수 회원에 대한
          통지의 경우 7일 이상 사이트에 게시함으로써 개별통지를 갈음할 수
          있습니다.
          <br />
          ① 제 5조(서비스 이용신청 및 승낙) 제 3항에서 정하고 있는 해지 사유가
          있음이 확인 된 경우 및 기타 이 약관에서 정한 해지 사유가 발행한 경우
          <br />
          ② 회원 등 이용자가 회사나 다른 회원 및 타인의 권리나 명예, 신용, 기타
          정당한 이익을 침해하는 행위를 한 경우
          <br />
          ③ 기타 약관에서 정하는 의무를 다하지 않은 경우
          <br />
          3. 회사는 다음의 경우에 한하여 서비스의 이용을 제한할 수 있습니다.
          <br />
          ① 전기통신 사업법에 규정된 기간통신 사업자가 전기통신서비스를 중지했을
          경우
          <br />
          ② 기타 불가항력적 사유가 있는 경우
          <br />
          <br />
          제 11조 사용 가능한 결제 수단
          <br />
          회원은 회사에서 제공하는 물품 또는 서비스의 결제를 위하여 이용할 수
          있는 수단은 다음과 같습니다.
          <br />
          ① 신용카드, 선불카드, 직불카드, 체크카드 등의 각종 카드
          <br />
          ② 실시간계좌이체
          <br />
          ③ 회사가 지급, 혹은 인정하는 포인트, 쿠폰 등<br />
          ④ 기타 회사가 지정하는 지급 수단
          <br />
          <br />
          제 12조 포인트
          <br />
          1. 회원에게 지급하는 포인트는 회사의 정책에 따라 정하며 포인트의
          유효기간, 적립기준, 사용조건 등에 관한 사항은 서비스 화면에 별도로
          게시하거나 통지하며, 회사의 정책에 따라 달라질 수 있습니다.
          <br />
          2. 회사는 서비스의 효율적인 이용 및 운영을 위해 사전 공지 후 포인트의
          일부 또는 전부를 조정할 수 있으며, 회원의 탈퇴 및 자격상실이 될 경우,
          유효기간 만료의 경우 포인트는 자동 소멸됩니다.
          <br />
          <br />
          제 13조 저작권 및 이용제한
          <br />
          1. 회원이 서비스 내에 게시한 내용물의 저작권은 회원이 소유하며 회사는
          서비스 내에 이를 게시할 수 있는 권리를 갖습니다.
          <br />
          2. 회사가 작성한 저작물에 대한 저작권 등 지적재산권은 회사에 귀속되며
          회사의 사전 승낙 없이 복제, 배포 등 기타 방법에 의하여 이용하거나
          제3자에게 이용하게 해서는 안됩니다.
          <br />
          3. 회원이 서비스 내에 게시한 사진, 이미지 등의 게시물은 기타 고객의
          검색결과 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해
          필요한 범위 내에서는 일부 수정, 복제, 편집되어 사이트내 게시될 수
          있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지
          고객센터 또는 기타 회사가 서비스내 제공하는 관리기능을 통해 해당
          게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수
          있습니다.
          <br />
          4. 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램
          저작권 등을 침해하더라도 이에 대한 민사 및 형사상의 책임을 지지
          않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을 침해하였음을
          이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우
          회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우
          회원은 그로 인해 회사에 발생한 모든 손해를 배상하여야 합니다.
          <br />
          5. 회사는 회원이 게시한 내용물이 다음 각 호의 경우에 해당된다고
          판단되는 경우 사전통지 없이 게시물을 삭제할 수 있습니다.
          <br />
          ① 회사 및 타인의 명예훼손, 사생활 침해 등의 경우
          <br />
          ② 안녕질서 및 미풍양속에 위배되는 경우
          <br />
          ③ 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 경우
          <br />
          ④ 회사의 서비스와 무관한 경우 및 회사가 승인하지 않은 광고, 판촉의
          경우
          <br />
          ⑤ 기타 관계 법령 및 회사의 지침 등에 위배된다고 판단되는 경우
          <br />
          5. 회원은 서비스를 이용하여 얻은 정보를 가공, 활용하여 영리목적으로
          이용하거나 제3자를 통해 이용하는 등 저작권 침해의 경우 관계 법령의
          적용을 받습니다.
          <br />
          <br />
          제 14조 정보 제공 및 광고의 게재
          <br />
          1. 회사는 각종 정보나 광고 등을 서비스 화면에 게재하거나 전자우편, SMS
          등의 방법으로 회원에게 제공할 수 있으며 회원은 이와 관련하여 언제든지
          수신을 거절할 수 있습니다.(단, 회사는 광고성 정보 전달에 대해 회원의
          사전 수신 동의를 받아야 합니다.)
          <br />
          2. 회원이 서비스상에 게재된 광고를 통해 광고주의 판촉활동에 참여하는
          등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의
          문제로 이들간의 문제가 발생할 경우에도 회원과 광고주가 직접
          해결해야하며 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.
          <br />
          <br />
          제15조 회사의 의무
          <br />
          1. 회사는 관련법령과 이 약관이 금지하거나 공공질서, 미풍양속에 반하는
          행위를 하지 않으며, 지속적으로 안정적인 서비스를 제공하기 위하여
          최선을 다하여야 합니다.
          <br />
          2. 회사는 서비스 제공과 관련하여 알고 있는 회원의 개인정보(신용정보
          포함) 취급방침을 준수합니다.
          <br />
          3. 회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의
          개인정보에 관한 통계자료를 작성하여 이를 회원 맞춤화 등 양질의 서비스
          제공을 위해 활용할 수 있고, 이를 위하여 회원의 컴퓨터에 쿠키를 전송할
          수 있습니다. 회원은 쿠키의 수신을 거부하거나 컴퓨터 브라우저 설정을
          변경할 수 있으며 쿠키 설정 변경에 의한 서비스 이용의 제한은 회원의
          책임입니다.
          <br />
          4. 회사는 관련법령이 정한 의무사항을 준수합니다.
          <br />
          <br />
          제 16조 회원의 의무
          <br />
          1. 회원은 회사의 서비스 이용 시 관계법령, 이 약관의 규정, 이용안내 등
          회사가 공지하는 사항을 준수하여야 하며, 기타 회사 업무에 방해되는
          행위를 해서는 안됩니다.
          <br />
          2. 회원은 다음 각 호의 행위를 하여서는 안됩니다.
          <br />
          ① 이용신청 및 정보 변경 시 허위 사실을 기재하거나 다른 회원 정보를
          부정하게 사용하는 행위
          <br />
          ② 회사의 서비스에 게시된 정보를 이용하여 얻은 정보를 회사의 사전
          승낙없이 복제, 유통시키거나 영리목적으로 이용하는 행위
          <br />
          ③ 회사 및 기타 제 3자의 저작권 등 기타 권리를 침해하는 행위
          <br />
          ④ 회사 및 기타 제 3자의 명예를 손상시키거나 불이익 및 피해를 주는 행위
          <br />
          ⑤ 정보통신망법 등 관련 법령에 의거, 전송 및 게시가 금지된 정보 전송
          행위 등<br />
          ⑥ 공공질서 및 미풍양속에 반하는 정보 게시 및 유포 행위
          <br />
          ⑦ 기타 불법적이거나 부당한 행위
          <br />
          <br />
          제 17조 회원 아이디 및 비밀번호에 대한 의무
          <br />
          1. 회원은 회원 아이디 및 비밀번호 관리를 철저히 해야하며 관리 소홀로
          인한 모든 결과에 대한 책임은 회원 본인에게 있습니다.(단, 회사의 과실
          및 책임에 의한 문제는 회사가 책임을 집니다.)
          <br />
          2. 회원은 회원 아이디 및 비밀번호를 제 3자가 이용하게 해서는 안되며
          도난 당하거나 제 3자가 사용하고 있음을 인지하는 경우에는 즉시 회사에
          통보하고, 회사의 조치를 따라야 합니다. 통지를 하지 않거나 회사의
          안내에 따르지 않아 발생하는 모든 불이익에 대한 책임은 회원 본인에게
          있습니다.
          <br />
          <br />
          제 18조 개인정보보호
          <br />
          1. 회사는 개인정보보호를 위하여 정보통신망 이용촉진 및 정보보호 등에
          관람 법률 등 관계 법령에서 정하는 바를 준수합니다.
          <br />
          2. 회사는 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관계 법령에
          따라 회원의 사전 동의를 얻은 경우, 동의를 받은 범위 내에서 개인정보를
          3자에게 제공할 수 있습니다.
          <br />
          3. 회사는 개인정보취급방침을 서비스 사이트를 통하여 게재합니다.
          <br />
          <br />
          제 19조 청약 철회
          <br />
          1. 회사는 회원 등 이용자의 이용 화면 등에 서비스 내용, 기간, 가격 및
          결제방법, 취소 가능 시간, 취소에 따른 불이익, 환불, 거래조건 등과 같은
          사항을 안내합니다.
          <br />
          2. 카드 결제에 대한 환불의 경우, 회사가 결제대행사에 요청하는 카드
          결제 취소는 즉시 접수되나 카드사 사정에 따라 일정 기간이 소요될 수
          있습니다. 자세한 일정은 해당 카드사에서 확인해야 합니다.
          <br />
          <br />
          제 20조 손해배상
          <br />
          1. 회사는 귀책사유 없는 서비스의 장애, 제공 중단, 보관된 자료 멸실
          또는 삭제, 변조 등으로 인한 회원의 손해에 대하여는 배상하지 않습니다.
          <br />
          2. 회사는 회사의 귀책사유로 인하여 회원에게 지속적으로 서비스를
          제공하지 못하는 경우 이로 인하여 회원에게 손해가 발생했을 경우 이에
          대하여 배상책임을 집니다.
          <br />
          3. 회사의 고의 또는 중대한 과실로 인하여 회원에게 손해가 발생한 경우
          회사는 회원에게 실제 발생한 손해만을 배상합니다.
          <br />
          4. 회원이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로
          인하여 회사가 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종
          이의제기를 받는 경우 해당 회원은 자신의 책임과 비용으로 회사를
          면책하여야 하며, 회사가 면책되지 못한 경우 해당 회원은 그로 인하여
          회사에 발행한 모든 손해를 배상해야 합니다.
          <br />
          5. 기타 손해배상의 방법과 절차 등은 관계법령에 따릅니다.
          <br />
          <br />
          제 21조 면책
          <br />
          1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.
          <br />
          2. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등
          내용에 관하여 어떠한 책임을 부담하지 아니하고, 회원이 게재한 게시물에
          대한 이용으로 손해가 발생하는 경우에도 책임을 지지 않습니다.
          <br />
          3. 회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지
          않습니다.
          <br />
          4. 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여
          책임을 지지 않습니다.
          <br />
          5. 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로
          발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할
          책임도 없습니다.
          <br />
          <br />
          제 22조 준거법 및 관할법원
          <br />
          1. 이 약관의 해석 및 회사와 회원간의 분쟁에 대하여는 대한민국의 법령을
          적용합니다.
          <br />
          2. 서비스 이용으로 발생한 분쟁은 회사 본점 소재지를 관할하는 법원에
          제기합니다.
          <br />
          <br />
          부칙
          <br />본 약관은 2019년 12월 1일부터 시행합니다.
        </div>
      </div>
    </div>
  );
}
export default Terms;
