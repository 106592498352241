import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Header from "./component/header";
import Footer from "./component/footer";
import base from "./store/base";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider, useSelector } from "react-redux";
import penderMiddleware from "redux-pender";
import { routes } from "./routes";
import { dispatch } from "use-bus";

const Middlewares = [penderMiddleware()];
const isDev = process.env.NODE_ENV === "development";
const devTools = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools || compose;
const store = createStore(
  base,
  composeEnhancers(applyMiddleware(...Middlewares))
);

export const useTitle = (initialTitle) => {
  const [title, setTitle] = useState(initialTitle);
  const updateTitle = () => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerText = title;
  };
  useEffect(updateTitle, [title]);
  return setTitle;
};

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App" onClick={() => dispatch("popup_clear")}>
          <Header></Header>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                exact
                path={route.path}
                name={route.name}
                component={route.component}
              />
            );
          })}
          <Footer></Footer>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
