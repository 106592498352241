import React, { useState, useEffect } from "react"; // , useRef
import axios from "axios";
import Moment from "react-moment";
import { Link } from "react-router-dom";

function ExpertEnrollList(props) {
  const token = localStorage.getItem("token");
  const [expertEnrolls, setExpertEnrolls] = useState([]);
  const [list, setList] = useState([]);
  const [state, setState] = useState({ request: true, modify: true });

  useEffect(() => {
    axios
      .get("/rest-api/getExpertEnrollList", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setExpertEnrolls([...response.data.expertInfos]);
          setList([...response.data.expertInfos]);
        }
      });
    // eslint-disable-next-line
  }, []);

  const stateCheck = (type) => {
    const request = type === "request" ? !state.request : state.request;
    const modify = type === "modify" ? !state.modify : state.modify;
    let tempList = [];
    expertEnrolls.map((item, index) => {
      if (item.status === "승인요청" && request) tempList.push(item);
      else if (item.status === "수정요청" && modify) tempList.push(item);
      return 0;
    });
    setList([...tempList]);
    setState({
      request: type === "request" ? !state.request : state.request,
      modify: type === "modify" ? !state.modify : state.modify,
    });
  };

  return (
    <div className="contents_wrap">
      <div className="recruit_section">
        <div className="cate_section">
          <div className="cate_title">처리상태</div>
          <div className="cate_items">
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"request"}
                  checked={state.request}
                  onChange={() => {
                    stateCheck("request");
                  }}
                />{" "}
                신규요청
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"modify"}
                  checked={state.modify}
                  onChange={() => {
                    stateCheck("modify");
                  }}
                />{" "}
                수정요청
              </label>
            </div>
          </div>
          <div className="bottom_bt_section">
            <Link to="/admin/main">
              <button className="default_bt submit_bt">관리자메인</button>
            </Link>
          </div>
        </div>
        <div className="recruit_items">
          <div className="order_section">
            <div className={"search_desc"}>
              <span className={"search_key"}>전문가 신청/수정 요청 리스트</span>
            </div>
          </div>
          {list.map((exp, index) => {
            return (
              <div className={"recruit_item active"} key={index}>
                <Link to={"/admin/expertEnroll/check/" + exp.user.id}>
                  <div className="rc_title">
                    {exp.user.name}님의 전문가 {exp.status}{" "}
                    <span className="rc_time">
                      <Moment fromNow locale="ko">
                        {exp.createdAt}
                      </Moment>
                    </span>
                  </div>
                  <div className="rc_cate">
                    <ul>
                      <li>{exp.status}</li>
                    </ul>
                  </div>
                  <div className="rc_contents">
                    {exp.user.name}(id:{exp.user.id})님의 전문가 정보{" "}
                    {exp.status}이 있습니다. 확인하고 처리해주세요.
                  </div>
                  <div className="rc_option">
                    <ul>
                      <li>
                        신청형태:{" "}
                        {exp.status === "승인요청"
                          ? "신규 등록 요청"
                          : "정보 수정 요청"}
                      </li>
                    </ul>
                  </div>
                </Link>
              </div>
            );
          })}
          {list.length === 0 && (
            <div className={"no_recruit_contents"}>
              해당 조건에 일치하는 요청글이 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ExpertEnrollList;
