import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { moneyFormat, useQuery } from "../../utils/commonApi";
import FileInput from "../../component/fileInput";
import { useHistory } from "react-router-dom";

function RecruitApply(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [projectInfo, setProjectInfo] = useState({});
  const [subProjects, setSubProjects] = useState([]);
  const history = useHistory();
  const [iss, setIss] = useState({
    isManager: false,
    isOwn: false,
    isApply: false,
    applyType: "none",
    mainApplyId: 0,
    applySubIndex: [],
  });
  const [files, setFiles] = useState([]);
  const [applyCate, setApplyCate] = useState([]);
  const [dueDate, setDueDate] = useState(new Date());
  const [expertInfo, setExpertInfo] = useState({
    type: "none",
    id: 0,
  });

  useEffect(() => {
    axios
      .get("/rest-api/getWhetherIs", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          projectId: Number(id),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setIss(response.data);
          if (!response.data.isExpert) {
            alert(
              "전문가 정보가 없습니다. 정문가 정보를 먼저 등록하고 이용해주세요."
            );
            history.goBack();
          } else if (response.data.mainApplyId !== 0) {
            alert(
              "통합지원 이력이 존재합니다. 지원글 관리 페이지로 이동합니다."
            );
            history.push(
              `/mypage/pro/apply/detail/${response.data.mainApplyId}`
            );
          } else {
            axios
              .get("/rest-api/getProject", {
                headers: {
                  Authorization: "Bearer " + token,
                },
                params: {
                  id: Number(id),
                },
              })
              .then(function (result) {
                if (!result.error) {
                  ///console.log(result.data);
                  setProjectInfo(result.data.project);
                  setSubProjects(result.data.subProjects);
                  setDueDate(new Date(result.data.project.dueDate));
                  setApplyCate(
                    result.data.subProjects.map((item) => ({
                      name: item.category,
                      checked: false,
                    }))
                  );
                  axios
                    .get("/rest-api/getExpertInfo", {
                      headers: {
                        Authorization: "Bearer " + token,
                      },
                    })
                    .then((resExInfo) => {
                      //console.log(resExInfo.data);
                      setExpertInfo(resExInfo.data);
                    });
                } else {
                  console.log(result.error);
                }
              });
          }
        } else {
          console.log(response.error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyCateClick = (cate, index) => {
    const applyCheck = iss.applyInfo.filter(
      (apply) => apply.jobIndex - 1 === index
    );
    console.log(applyCheck);
    if (applyCheck.length > 0) {
      if (
        window.confirm(
          `[${applyCate[index].name}] 지원 이력이 있습니다. 내용 확인/수정을 위해 해당 페이지로 이동하시겠습니까?`
        )
      )
        history.push(`/mypage/pro/apply/detail/${applyCheck[0].applyId}`);
      else return 0;
    } else {
      setApplyCate(
        applyCate.map((item) => {
          return item.name === cate.name
            ? { ...item, checked: !item.checked }
            : { ...item, checked: false };
        })
      );
      setDueDate(new Date(subProjects[index].nextDueDate));
      setValue("applyCost", subProjects[index].cost);
    }
  };
  const applySubmit = (data) => {
    let subIndex = 0;
    if (data.projectType === "each")
      applyCate.map((item, index) => {
        if (item.checked) subIndex = index;
        return 0;
      });
    axios
      .post(
        "/rest-api/postApply",
        {
          projectType: data.projectType,
          projectId:
            data.projectType === "main" ? id : subProjects[subIndex].id,
          cost: data.applyCost,
          dueDate,
          content: data.desc,
          file: files,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          alert("프로젝트 지원글이 성공적으로 등록되었습니다.");
        } else {
          alert("프로젝트 지원글 등록에 실패하였습니다.");
        }
        history.push(`/recruit/detail/${id}`);
      });
  };
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };
  const costSet = (prop) => {
    const input = watch("applyCost") * 10000;
    let costInfo = {
      fee: parseInt(input) * 0.1,
      cost: 0,
      total: 0,
      tax: 0,
    };

    if (input < 10000) costInfo.fee = 0;
    else if (costInfo.fee < 10000) costInfo.fee = 10000;
    else if (costInfo.fee > 1000000) costInfo.fee = 1000000;

    costInfo.cost = input - costInfo.fee;
    costInfo.tax = costInfo.cost * 0.1;
    costInfo.total = costInfo.cost + costInfo.tax;

    return costInfo[prop];
  };
  return (
    <div className="contents_wrap">
      {projectInfo && (
        <div className="div_page">
          <div className="page_title">프로젝트 지원</div>
          <form onSubmit={handleSubmit(applySubmit)}>
            <div className="page_contents project_apply">
              <div className="project_title">
                {projectInfo.title}{" "}
                <span className="rc_time">
                  <Moment fromNow>{projectInfo.createdAt}</Moment>
                </span>
              </div>
              <div className="apply_info">
                <div className="info_item">
                  <div className="info_title">업체명</div>
                  <div className="info_contents">{projectInfo.companyName}</div>
                </div>
                <div className="info_item">
                  <div className="info_title">희망금액</div>
                  <div className="info_contents">{projectInfo.cost}만원</div>
                </div>
                <div className="info_item">
                  <div className="info_title">납품지역</div>
                  <div className="info_contents">{projectInfo.region}</div>
                </div>
                <div className="info_item">
                  <div className="info_title">희망납기일</div>
                  <div className="info_contents">{projectInfo.dueDate}</div>
                </div>
              </div>
              <div className={"recruit_summary"}>
                <div className="info_title">업무개요</div>
                <div className="rcs_contents">
                  {projectInfo.content &&
                    projectInfo.content.split("\n").map((item, idx) => {
                      return (
                        <span key={idx}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                </div>
              </div>
              <div className="input_item apply_type">
                <div className="input_title">업무형태</div>
                <div className="input_contents">
                  <select name={"projectType"} {...register("projectType")}>
                    <option value={"main"}>통합지원</option>
                    {subProjects.length !== 0 && (
                      <option value={"each"}>개별지원</option>
                    )}
                  </select>
                  {subProjects.length === 0 && (
                    <div className="info_desc">
                      ※ 해당 프로젝트는 통합 지원만 가능합니다.
                    </div>
                  )}
                </div>
              </div>
              {watch("projectType") === "main" && (
                <div className="info_desc" style={{ paddingLeft: "10px" }}>
                  * 개별 공정으로 지원하시려면 위 업무 형태를 "개별지원"으로
                  변경해주세요.
                </div>
              )}
              {watch("projectType") === "each" && (
                <div className="each_apply">
                  <div className="input_item mobile_full_item">
                    <div className="input_title">업무지원</div>
                    <div className="input_contents">
                      <div className="job_cate_items">
                        {applyCate.map((item, index) => (
                          <div
                            key={index}
                            className={
                              "job_item" + (item.checked ? " active" : "")
                            }
                            onClick={() => {
                              applyCateClick(item, index);
                            }}
                          >
                            {item.name}
                          </div>
                        ))}
                      </div>
                      <div className="input_desc">
                        지원할 업무파트를 선택해주세요.
                      </div>
                    </div>
                  </div>
                  {subProjects.map((spr, index) =>
                    applyCate[index].checked ? (
                      <div className="job_part" key={index}>
                        <div className="job_title">{spr.process}</div>
                        <div className="info_item">
                          <div className="info_title">희망금액</div>
                          <div className="info_contents">{spr.cost}만원</div>
                        </div>
                        <div className="info_item">
                          <div className="info_title">납품지역</div>
                          <div className="info_contents">
                            {spr.nextRegion.region}
                          </div>
                        </div>
                        <div className="info_item">
                          <div className="info_title">희망납기일</div>
                          <div className="info_contents">{spr.nextDueDate}</div>
                        </div>
                        <div className="info_item">
                          <div className="info_title">업무내용</div>
                          <div className="info_contents">{spr.title}</div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              )}
              <div className="offer_section">
                <div className="input_item">
                  <div className="input_title">제안금액</div>
                  <div className="input_contents">
                    <input
                      type="text"
                      placeholder="희망총금액"
                      name={"applyCost"}
                      {...register("applyCost", {
                        required: "제안금액을 적어주세요",
                      })}
                    />
                    만원
                    {errors?.applyCost && (
                      <div className={"error_msg"}>
                        {errors?.applyCost?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="info_item">
                  <div className="info_title">수수료</div>
                  <div className="info_contents">
                    {moneyFormat(costSet("fee"))}원
                  </div>
                </div>
                <div className="info_item">
                  <div className="info_title">입금예정액</div>
                  <div className="info_contents">
                    {/* {moneyFormat(costSet("total"))}원 */}
                    수수료제외 공급가액 : {moneyFormat(costSet("cost"))}원,
                    부가세 :{moneyFormat(costSet("tax"))}원
                    <div className="info_desc">
                      (세금계산서 발행 = 공급가액 :{" "}
                      {moneyFormat(costSet("cost"))}원, 부가세 :
                      {moneyFormat(costSet("tax"))}원)
                      <br />
                      원활한 서비스 제공을 위해 수수료는 제안 금액의 10%로
                      책정합니다.(최소 1만원 / 최대 100만원)
                      <br />
                      예시1: 제안금액 100만원의 경우 수수료 10만원을 제하고
                      90만원+부가세10%(9만원)=99만원 입금
                      <br />
                      예시2: 제안금액 2,000만원의 경우 수수료 100만원을 제하고
                      1,900만원+부가세10%(190만원)=2,090만원 입금
                    </div>
                  </div>
                </div>
                <div className="input_item date_item">
                  <div className="input_title">제안 납기일</div>
                  <div className="input_contents">
                    <DatePicker
                      locale="ko"
                      dateFormat="yyyy/MM/dd(eee)"
                      selected={dueDate}
                      onChange={(date) => setDueDate(date)}
                    />
                  </div>
                </div>
                {/* <div className="input_item date_item">
                  <div className="input_title">2차 납기일</div>
                  <div className="input_contents">
                    <DatePicker
                      locale="ko"
                      dateFormat="yyyy/MM/dd(eee)"
                      selected={dueDate2}
                      onChange={(date) => setDueDate2(date)}
                    />
                  </div>
                </div> */}
              </div>

              <div className="input_item full_item">
                <div className="input_title">세부내용</div>
                <div className="input_contents">
                  <textarea
                    name={"desc"}
                    {...register("desc", {
                      required: "세부내용을 적어주세요",
                    })}
                    placeholder="프로젝트 지원 내용을 자유롭게 작성해주세요. 금액, 제작 관련 사항을 자세히 입력하면 매칭 확률이 늘어납니다."
                  ></textarea>
                  {errors?.desc && (
                    <div className={"error_msg"}>{errors?.desc?.message}</div>
                  )}
                </div>
              </div>

              <FileInput
                titleYN={true}
                files={files}
                fileChange={fileChange}
                fileX={fileX}
              />
            </div>
            <div className="page_bottom">
              <button type={"submit"} className="default_bt submit_bt">
                지원완료
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default RecruitApply;
