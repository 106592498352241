import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "cross-fetch/polyfill";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import "./style/common.scss";
import "./style/mobile.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "moment/locale/ko";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
registerLocale("ko", ko);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
