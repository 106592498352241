import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import XLSX from "xlsx";
import Spreadsheet from "react-spreadsheet";
import "moment-timezone";
import { Link, useHistory } from "react-router-dom";
import AdminSideMenu from "../../component/adminSideMenu";

function EstimateTest(props) {
  const { register, watch } = useForm();
  let token = localStorage.getItem("token");
  const inputFileDom = useRef();
  const history = useHistory();
  //  const [data, setData] = useState([]);
  const [fileText, setFileText] = useState(
    "jpg/jpeg/png/ppt/doc/docx/hwp/pdf/zip"
  );
  const [fileType, setFileType] = useState("지류");
  const [optionTypes, setOptionTypes] = useState([
    "지류",
    "기종",
    "인쇄",
    "직접입력",
  ]);
  const opInitData = optionTypes.map((opt) => {
    return { type: opt, fileName: "기본파일", info: {} };
  });
  const [options, setOptions] = useState(opInitData);
  const customInitData = [];
  const [customData, setCustomData] = useState(customInitData);
  const [viewData, setViewData] = useState([
    [{ value: "Vanilla" }, { value: "Chocolate" }],
    [{ value: "Strawberry" }, { value: "Cookies" }],
  ]);

  useEffect(() => {
    console.log("inputDOM: ", inputFileDom.current.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const viewUpdate = () => {
    console.log("options :", options);
  };

  const setPaperInfo = (rows, sheetName, dataList) => {
    let tempData = [];
    if (sheetName === "정보") {
      const keyList = Object.keys(rows[0]);
      tempData = rows.map((r) => {
        return {
          [keyList[0]]: r["지종"],
          [keyList[1]]: r["평량"],
          [keyList[2]]: r["사이즈"],
          [keyList[3]]: r["할인률"],
          [keyList[4]]: String(r["기준일"]),
          priceList: [],
        };
      });
    } else {
      tempData = dataList.map((d) => {
        if (d["지종"] === sheetName) {
          let temp = d;
          temp.priceList = rows;
          return temp;
        } else {
          return d;
        }
      });
    }
    return tempData;
  };

  const machineFileRead = (event) => {
    let input = event.target;
    let reader = new FileReader();
    setFileText(input.files[0].name);
    reader.onload = () => {
      let fileData = reader.result;
      let workBook = XLSX.read(fileData, { type: "binary" });
      let rows = XLSX.utils.sheet_to_json(
        workBook.Sheets[workBook.SheetNames[0]]
      );
      console.log("rows: ", rows);
      setOptions(
        options.map((d) =>
          d.type === "기종"
            ? {
                type: d.type,
                fileName: input.files[0].name,
                info: rows,
              }
            : d
        )
      );
    };
    reader.readAsBinaryString(input.files[0]);
  };

  const paperFileRead = (event) => {
    let input = event.target;
    let reader = new FileReader();
    setFileText(input.files[0].name);

    reader.onload = () => {
      let tempData = [];
      let tempOp = [];
      let fileData = reader.result;
      let workBook = XLSX.read(fileData, { type: "binary" });
      workBook.SheetNames.forEach(function (sheetName) {
        let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
        tempData = [...setPaperInfo(rows, sheetName, tempData)];
      });
      tempOp = options.map((d) =>
        d.type === "지류"
          ? {
              type: d.type,
              fileName: input.files[0].name,
              info: tempData,
            }
          : d
      );
      setOptions(tempOp);
    };
    reader.readAsBinaryString(input.files[0]);
  };

  const changeFileType = () => {
    let tempFileType = fileType;
    if (watch("fileTypes") === "직접입력")
      tempFileType = watch("fileType_other") || "지류";
    else tempFileType = watch("fileTypes");
    setFileType(tempFileType);
  };

  const fileRead = (event) => {
    const fileType = watch("fileTypes");
    console.log("filepath: ", event.target.files[0]);
    switch (fileType) {
      case "지류":
        paperFileRead(event);
        break;
      case "기종":
        machineFileRead(event);
        break;
      default:
        window.alert("지정되지 않은 유형의파일 타입입니다.");
    }
    return 0;
  };

  return (
    <div className="contents_wrap">
      <div className="mypage_section admin_mainPage">
        <AdminSideMenu />
        <div className="mypage_contents admin_mainContents">
          <div className="admin_contents_container">
            <div className="page_title">
              <div className="title">견적 입력</div>
              <div className="input_item"></div>
            </div>
            {options.length > 0 &&
              options.map((op, index) => {
                let deps = 1;
                switch (op.type) {
                  case "지류":
                    deps = 2;
                    break;
                  default:
                    deps = 1;
                }
                return (
                  <div className="input_item" key={index}>
                    <div className="input_title">{op.type}</div>
                    <div className="input_selectBox">
                      <select className="estInput"></select>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="admin_contents_container">
            <div className="page_title">산출 견적</div>
            <div className="estInput">
              <div className="input_category">용지대</div>
              <div className="input_selectBox">##원</div>
              <div className="input_selectBox">비고</div>
            </div>
          </div>
          <div className="admin_contents_container newUser_section">
            <div className="page_title">
              <div className="title">입력 정보 변경</div>
              <div className="input_item">{"입력 파일 타입: " + fileType}</div>
              <div className="input_item">
                <div className="input_contents">
                  <select name={"fileTypes"} {...register("fileTypes")}>
                    {optionTypes.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {watch("fileTypes") === "직접입력" && (
                    <input
                      type={"text"}
                      placeholder={"직접입력"}
                      name={"fileType_other"}
                      {...register("fileType_other")}
                    />
                  )}
                  <div className="reload_bt" onClick={changeFileType}>
                    {"🔄"}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="input_item file_item"
              style={{
                paddingTop: "20px",
                paddingLeft: "20px",
                margin: "0",
              }}
            >
              <div className={"input_title"}>파일첨부</div>
              <div className="input_contents">
                <div className="file_input">
                  <label>
                    <input
                      type="file"
                      onChange={fileRead}
                      id={"inputFileEst"}
                      ref={inputFileDom}
                    />
                    <div className="file_text">{fileText}</div>
                    <div className="file_bt">파일찾기</div>
                  </label>
                  <label>
                    <button
                      className="file_bt"
                      onClick={viewUpdate}
                      style={{ backgroundColor: "#181818" }}
                    >
                      적용/콘솔
                    </button>
                  </label>
                </div>
              </div>
            </div>
            {options.length > 0 &&
              options.map((op, index) => (
                <div className="input_item" key={index}>
                  <div className="input_title ref_info">{op.type}</div>
                  <div className="input_contents file_names">{op.fileName}</div>
                </div>
              ))}
            {options.length === 0 && (
              <div className="input_item">
                <div className="input_title noInfo">
                  {" "}
                  참조 파일 정보가 없습니다.
                </div>
              </div>
            )}
            {/* <div className="div_page spread_sheet">
              <Spreadsheet data={viewData} onChange={changeData} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default EstimateTest;
