import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import { Link, useLocation, useHistory } from "react-router-dom";
import useHistoryState from "use-history-state";

function RecruitList(props) {
  let token = localStorage.getItem("token");
  const [cate, setCate] = useHistoryState([], "cate");
  const [mobileFilterView, setMobileFilterView] = useState(false);
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    // axios
    //   .get("/rest-api/getProjects", {
    //     params: {
    //       categories: cate,
    //       sortBy: sortBy,
    //       status: recruitState,
    //       amount: pageAmount,
    //       searchKey: searchKey,
    //     },
    //   })
    //   .then(function (response) {
    //     if (response.status === 200) {
    //       setRecruits([...response.data.projects]);
    //       if (response.data.totalProjectCount <= pageAmount * pageNum)
    //         setLastPage(true);
    //       setPageNum(response.data.nextIndex);
    //     }
    //   });
  }, []);

  const pageMore = () => {
    // axios
    // .get("/rest-api/getProjects", {
    //   params: {
    //     categories: cate,
    //     sortBy: sortBy,
    //     status: recruitState,
    //     amount: pageAmount,
    //     searchKey: searchKey,
    //     index: pageNum,
    //   },
    // })
    // .then(function (response) {
    //   if (response.status === 200) {
    //     setRecruits([...recruits, ...response.data.projects]);
    //     if (response.data.totalProjectCount <= pageAmount * pageNum)
    //       setLastPage(true);
    //     setPageNum(response.data.nextIndex);
    //   } else if (response.status === 202) {
    //     console.log("lastPage");
    //     setLastPage(true);
    //   }
    // });
  };

  const cateCheck = (e) => {
    let cateTemp = [];
    if (e.target.checked) {
      cateTemp = [...cate, e.target.name];
    } else {
      cateTemp = cate.filter((item) => item !== e.target.name);
    }
    setCate(cateTemp);
    // axios
    //   .get("/rest-api/getProjects", {
    //     params: {
    //       categories: cateTemp,
    //       sortBy: sortBy,
    //       status: recruitState,
    //       amount: pageAmount,
    //       searchKey: searchKey,
    //     },
    //   })
    //   .then(function (response) {
    //     if (response.status === 200) {
    //       setRecruits([...response.data.projects]);
    //     }
    //   });
  };

  return (
    <div className="contents_wrap">
      <div className="review_section">
        <div
          className={
            "cate_section" + (mobileFilterView ? " mobile_active" : "")
          }
        >
          <div className="cate_title">업무영역</div>
          <div className="cate_items">
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"design"}
                  checked={cate.indexOf("design") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                디자인
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"print"}
                  checked={cate.indexOf("print") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                인쇄제작
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"silsa"}
                  checked={cate.indexOf("silsa") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                실사제작
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"otherPrint"}
                  checked={cate.indexOf("otherPrint") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                기타제작
              </label>
            </div>
            <div className="cate_item">
              <label>
                <input
                  type="checkbox"
                  name={"other"}
                  checked={cate.indexOf("other") >= 0 ? true : false}
                  onChange={cateCheck}
                />{" "}
                기타
              </label>
            </div>
          </div>
        </div>
        <div className="review_items">
          <div className="review_item">
            <div className="rv_writer">
              조성주<div className="rv_time">2021-11-29</div>
            </div>
            <div className="rv_cate">
              <ul>
                <li>디자인</li>
                <li>인쇄제작</li>
              </ul>
            </div>
            <div className="rv_star_items">
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
            </div>
            <div className="rv_contents">
              와이낫링크 전담매니저님 통해서 쉽고 편리하게 이용했습니다.
            </div>
          </div>
          <div className="review_item">
            <div className="rv_writer">
              엄태성<div className="rv_time">2021-11-29</div>
            </div>
            <div className="rv_cate">
              <ul>
                <li>디자인</li>
              </ul>
            </div>
            <div className="rv_star_items">
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star_active.svg" alt="ic_star_active.svg" />
              </span>
              <span className="star_item">
                <img src="/img/ic_star.svg" alt="ic_star.svg" />
              </span>
            </div>
            <div className="rv_contents">
              와이낫링크 전담매니저님 통해서 쉽고 편리하게
              이용했습니다.와이낫링크 전담매니저님 통해서 쉽고 편리하게
              이용했습니다.와이낫링크 전담매니저님 통해서 쉽고 편리하게
              이용했습니다.
            </div>
          </div>

          <div className={"more_page_bt_section"}>
            <button className={"default_bt more_page_bt"} onClick={pageMore}>
              더보기
            </button>
          </div>

          {false && (
            <div className={"no_recruit_contents"}>
              해당 조건에 일치하는 모집이 없습니다.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default RecruitList;
