import React, { useState, useEffect } from "react";
import AdminSideMenu from "../../component/adminSideMenu";
import axios from "axios";
import ProCate from "../../component/proCate";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
//import FileInput from "../../component/fileInput";

function ExpertInfoCheck(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    //setError,
    setValue,
  } = useForm();
  const { id } = useParams();
  const [exp, setExp] = useState({});
  const [proCateItems, setProCateItems] = useState([]);
  //const [proCate, setProCate] = useState([]);
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const [proCateItems2, setProCateItems2] = useState([]);
  const [toggleBox, setToggleBox] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/rest-api/getExpertCategory", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setProCateItems2(response.data.businessStatus);
        setProCateItems(response.data.category);
        axios
          .get("/rest-api/getExpertInfo", {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              userId: id,
            },
          })
          .then(function (response2) {
            const info = response2.data.modifyRequest.modify
              ? response2.data.modifyRequest.content
              : response2.data;
            setIntiValue(response, response2, info);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIntiValue = (response, response2, info) => {
    setValue("type", info.Ctype);
    setValue("type2", info.businessStatus);
    setValue("cName", info.companyName);
    setValue("desc", info.companyInfoDetail);
    setValue("accountCompany", info.bankName);
    setValue("accountNum", info.acountNumber);
    setValue("accountName", info.acountOwner);
    setValue("channelTypeCode", info.ispublicName);
    setToggleBox(info.ispublicName ? true : false);
    setFiles([...info.file]);
    setExp(response2.data);
    setProCateItems(
      response.data.category.map((cate) => {
        if (
          info.category.filter((cateinfo) => {
            return cateinfo.idx === cate.idx;
          }).length > 0
        )
          return { ...cate, value: true };
        else return cate;
      })
    );
  };

  const proCateChange = (item) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        return cate.name === item.name ? { ...item, value: !item.value } : cate;
      })
    );
  };

  const modifySubmit = (e) => {
    let sendData = {};
    const selectCate = proCateItems.reduce((acc, item) => {
      if (item.value === true) acc.push(item.idx);
      return acc;
    }, []);

    sendData = {
      result: e.target.innerText,
      opnion: watch("opnion") || "승인",
      id: exp.id,
      Ctype: watch("type"),
      category: selectCate,
      businessStatus: watch("type2"),
      companyName: watch("cName"),
      ispublicName: toggleBox,
      companyInfoDetail: watch("desc"),
      businessRegUrl: files,
      bankName: watch("accountCompany"),
      acountNumber: watch("accountNum"),
      acountOwner: watch("accountName"),
      file: files,
    };

    axios
      .post("/rest-api/postExpertModify", sendData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
          history.push("/admin/expertEnrollList");
        } else alert(res.data.error);
      });
  };
  return (
    <div className="contents_wrap">
      <div className="mypage_section">
        {/* <MySideMenu /> */}
        <AdminSideMenu />
        <div className="mypage_contents">
          <form onSubmit={handleSubmit(modifySubmit)}>
            <div className="page_title">전문가 신청/수정 검토 및 확정</div>
            <div className="input_item">
              <div className="input_title">기업형태</div>
              <div className="input_contents">
                <select name={"type"} {...register("type")}>
                  <option value={"법인사업자"}>법인사업자</option>
                  <option value={"개인사업자"}>개인사업자</option>
                </select>
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">업무형태</div>
              <div className="input_contents">
                <select name={"type2"} {...register("type2")}>
                  {proCateItems2.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                {watch("type2") === "기타" && (
                  <input
                    type={"text"}
                    placeholder={"직접입력"}
                    name={"proCateEtc"}
                    {...register("proCateEtc")}
                  />
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">회사이름</div>
              <div className="input_contents">
                <span className="on_off_bt">
                  <label>
                    <input
                      type="checkbox"
                      className="check_toggle"
                      name="channelTypeCode"
                      onClick={() => {
                        setToggleBox(!toggleBox);
                      }}
                    />
                    <span className="toggle_bt"></span>
                  </label>
                </span>
                <span className="on_off_value">
                  {toggleBox ? "공개" : "비공개"}
                </span>
                <div>
                  <input
                    type="text"
                    placeholder="회사명을 입력해주세요"
                    name={"cName"}
                    {...register("cName", {
                      required: "회사명을 입력해주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.cName && errors?.cName?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">회사소개</div>
              <div className="input_contents">
                <textarea
                  name={"desc"}
                  {...register("desc", {
                    required: "회사소개를 입력해주세요",
                  })}
                  placeholder="기타 세부 회사(개인) 소개를 입력해주세요.
원활한 서비스 제공을 위해 전화번호, 이메일주소, SNS계정 등 개인정보 기입을 금지합니다.
(입력했을 시 임의삭제되며, 승인이 취소될 수 있습니다)"
                ></textarea>
                <div className={"error_msg"}>
                  {errors?.desc && errors?.desc?.message}
                </div>
              </div>
            </div>
            <div className="job_select_section">
              <div className="info_title">전문분야(중복가능)</div>
              <ProCate
                proItems={proCateItems}
                proChange={proCateChange}
              ></ProCate>
            </div>
            <div className="account_section">
              <div className="input_item mobile_full_item">
                <div className="input_title">제출서류</div>
                <div className="input_contents">
                  {files.length === 0 && (
                    <div className="info_item need_item">
                      첨부파일이 없습니다.
                    </div>
                  )}
                  {files.length !== 0 &&
                    files.map((file, index) => {
                      return (
                        <div className="info_item need_item" key={index}>
                          <a href={file.url} download>
                            {file.originalFileName}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">은행명</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="은행명"
                    name={"accountCompany"}
                    {...register("accountCompany", {
                      required: "은행명을 적어주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.accountCompany && errors?.accountCompany?.message}
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">계좌번호</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="계좌번호"
                    name={"accountNum"}
                    {...register("accountNum", {
                      required: "계좌번호를 적어주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.accountNum && errors?.accountNum?.message}
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">예금주</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="예금주"
                    name={"accountName"}
                    {...register("accountName", {
                      required: "예금주를 적어주세요",
                    })}
                  />
                  <div className={"error_msg"}>
                    {errors?.accountName && errors?.accountName?.message}
                  </div>
                </div>
              </div>
              <div className="info_desc">
                *회사의 경우 사업자 및 법인명의, 개인의 경우 본인명의 계좌를
                등록해주세요.
              </div>
              <div className="input_item">
                <div className="input_title">처리 의견</div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="처리의견"
                    name={"opnion"}
                    {...register("opnion")}
                  />
                </div>
              </div>
            </div>
            <div className="bottom_bt_section">
              <button
                type={"submit"}
                className="default_bt apply_state_view_bt"
                style={{ marginRight: 20 + "px" }}
                onClick={(event) => {
                  event.preventDefault();
                  if (watch("opnion") === "")
                    alert("반려 의견을 작성해주세요.");
                  else modifySubmit(event);
                }}
              >
                요청반려
              </button>
              <button
                type={"submit"}
                className="default_bt apply_bt"
                onClick={(event) => {
                  event.preventDefault();
                  modifySubmit(event);
                }}
              >
                검토완료
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ExpertInfoCheck;
