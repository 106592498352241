import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";
import { moneyFormat } from "../../utils/commonApi";
import FileInput from "../../component/fileInput";

function RecruitDetail(props) {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [projectInfo, setProjectInfo] = useState({});
  const [mainPjFiles, setMainPjFiles] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [creator, setCreator] = useState({ id: 0, name: "유저정보없음" });
  const [button, setButton] = useState({ isThere: false });
  const [iss, setIss] = useState({});
  const history = useHistory();
  const [workEndConfirm_bt, setWorkEndConfirm_bt] = useState(false);
  const [files, setFiles] = useState([]);
  const [review, setReview] = useState(null);
  const [reviewImg, setReviewImg] = useState("/img/logo.png");

  const status = [
    "업무검토",
    "모집중",
    "선정중",
    "결제대기",
    "결제완료",
    "업무진행",
    "업무종료",
    "보류",
  ];

  useEffect(() => {
    axios
      .get("/rest-api/getProject", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      })
      .then(function (result) {
        if (result.status === 200) {
          // console.log("result: ", result.data);
          setProjectInfo(result.data.project);
          setMainPjFiles([...result.data.project.file]);
          setSubProjects([...result.data.subProjects]);
          setCreator(result.data.project.user);
          if (result.data.project.review) {
            setReview(result.data.project.review);
            if (result.data.project.review.files.length === 1) {
              const tempString = result.data.project.review.files[0].originalFileName.split(
                "."
              );
              const dotName = tempString[tempString.length - 1];
              const imgList = ["png", "jpg", "jpge"];
              // console.log("확장자", dotName);
              if (imgList.indexOf(dotName) !== -1)
                setReviewImg(result.data.project.review.files[0].url);
              else setReviewImg("/img/logo.png");
            }
          }
          axios
            .get("/rest-api/getWhetherIs", {
              headers: {
                Authorization: "Bearer " + token,
              },
              params: {
                projectId: Number(id),
              },
            })
            .then((response) => {
              if (response.status !== 200) setButton({ isThere: false });
              else {
                const iss = response.data;
                // console.log("iss: ", iss);
                setIss(iss);
                if (iss.isOwn) {
                  setButton({
                    isThere: true,
                    linkTo: "/mypage/project/apply",
                    className: "default_bt apply_state_view_bt",
                    text: "지원업체보기",
                    onClick: null,
                  });
                  if (result.data.project.status === "종료요청")
                    setWorkEndConfirm_bt(true);
                } else if (iss.isExpert) {
                  if (result.data.project.status === "모집중") {
                    if (iss.isApply && iss.applyType === "main") {
                      setButton({
                        isThere: true,
                        linkTo: `/mypage/pro/apply/detail/${iss.mainApplyId}`,
                        className: "default_bt apply_state_view_bt",
                        text: "지원내용보기",
                        onClick: null,
                      });
                    } else {
                      setButton({
                        isThere: true,
                        linkTo: "/recruit/apply/" + id,
                        className: "default_bt apply_bt",
                        text: "지원하기",
                        onClick: null,
                      });
                    }
                  } else setButton({ isThere: false });
                } else {
                  if (result.data.project.status === "모집중") {
                    setButton({
                      isThere: true,
                      linkTo: "#",
                      className: "default_bt apply_bt",
                      text: "지원하기",
                      onClick: () => {
                        if (
                          window.confirm(
                            "전문가 등급에서 지원 가능합니다. 전문가 신청 페이지로 이동하시겠습니까?"
                          )
                        )
                          history.push("/mypage/pro/Register");
                      },
                    });
                  } else setButton({ isThere: false });
                }
              }
            });
        } else if (result.data.result === "일치하는 토큰 정보 없음") {
          // alert("로그인이 필요한 서비스입니다.");
          // history.push("/login");
          setIss({
            isManager: false,
            isExpert: false,
            isOwn: false,
            isApply: false,
            applyType: "none",
            mainApplyId: 0,
            applySubIndex: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, id, token]);

  const changeProcess = (status) => {
    if (window.confirm(`프로젝트 상태를 "${status}"으로 변경하시겠습니까?`))
      axios
        .post(
          "/rest-api/changeProcess",
          {
            projectId: Number(id),
            status,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            alert(response.data.message);
            projectInfo.status = status;
            setProjectInfo(projectInfo);
          } else alert(response.data.error);
        });
  };
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };

  const workEndConfirm = async () => {
    if (
      window.confirm(
        "프로젝트를 종료하시겠습니까?(납품을 정상적으로 받으신 경우에만 확인을 눌러주세요)"
      )
    ) {
      const res = await axios("/rest-api/projectEndConfirm", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          projectId: Number(id),
        },
      });
      if (res.status === 200) {
        setWorkEndConfirm_bt(false);
        alert(res.data.message);
        if (
          window.confirm(
            "전문가에 대한 리뷰를 작성해주세요. 지금 작성하시겠습니까?"
          )
        )
          history.push({
            pathname: `/recruit/review/write/${id}`,
            state: { project: projectInfo },
          });
      } else alert(res.data.error);
    }
  };

  const datePrint = (date) => {
    const dates =
      date === "none"
        ? [projectInfo.applyDueDate, projectInfo.dueDate]
        : date.split("~");
    const start = {
      year: dates[0].split("-")[0],
      month: dates[0].split("-")[1],
      day: dates[0].split("-")[2],
    };
    const end = {
      year: dates[1].split("-")[0],
      month: dates[1].split("-")[1],
      day: dates[1].split("-")[2],
    };
    const returnDate = `${"`" + dates[0].slice(2)}~${
      start.year === end.year ? "" : end.year + "-"
    }${start.month === end.month ? "" : end.month + "-"}${end.day}`;
    return returnDate;
  };

  const findApplyId = (applies, jobIndex) => {
    let info = [];
    // console.log("applies: ", applies);
    if (applies === undefined || applies.length === 0) return false;
    else {
      applies.map((app) => {
        if (app.jobIndex === jobIndex) info.push(app);
        return 0;
      });
      if (info.length > 0) return info[0].applyId;
      else return false;
    }
  };

  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">모집상세내역</div>
        <div className="recruit_header">
          <div className="rc_title">
            {projectInfo.title}{" "}
            <span className="rc_time">
              <Moment fromNow>{projectInfo.createdAt}</Moment>
            </span>
          </div>
          <div className="rc_option_items">
            <div className="info_item">
              <div className="info_title">작성자</div>
              <div className="info_contents">{creator.name}</div>
            </div>
            <div className="info_item">
              <div className="info_title">업체명</div>
              <div className="info_contents">{projectInfo.companyName}</div>
            </div>
            <div className="info_item">
              <div className="info_title">프로젝트 총 예산</div>
              <div className="info_contents">
                총 {moneyFormat(projectInfo.cost)}만원
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납기일</div>
              <div className="info_contents">{projectInfo.dueDate}</div>
            </div>
            <div className="info_item">
              <div className="info_title">모집마감일</div>
              <div className="info_contents">
                {projectInfo.applyDueDate}({projectInfo.status})
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">모집업무</div>
              <div className="info_contents">
                {projectInfo.mainCategory &&
                  projectInfo.mainCategory.map(
                    (item, index) =>
                      item +
                      (index === projectInfo.mainCategory.length - 1
                        ? ""
                        : ", ")
                  )}
              </div>
            </div>
            <div className="info_item">
              <div className="info_title">최종납품지역</div>
              <div className="info_contents">{projectInfo.region}</div>
            </div>
          </div>
          <div className="rc_option_items attachFiles">
            <div className="info_item">
              <div className="info_title">첨부파일</div>
              <div className="info_contents">
                {mainPjFiles.length === 0 && <span>첨부파일이 없습니다.</span>}
                {mainPjFiles.length > 0 &&
                  mainPjFiles.map((file, index) => {
                    return (
                      <span key={index} className="file_item">
                        {index !== 0 && <br />}
                        <a href={file.url} download={file.originalFileName}>
                          {file.originalFileName}
                        </a>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="recruit_summary">
          <div className="info_title">업무개요</div>
          <div className="rcs_contents">
            {projectInfo.content &&
              projectInfo.content.split("\n").map((item, idx) => {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </div>
        </div>
        <div className="recruit_department">
          {subProjects.length > 0 && (
            <div className="info_title">업무분야 상세</div>
          )}
          {subProjects.map((spr, index) => (
            <div className="rcd_item" key={index}>
              <div className="rcd_title">{spr.category}</div>
              <div className="rcd_contents">
                <div className="rcd_detail">{spr.process}</div>
                <div className="rcd_option">
                  {/* 희망금액 {moneyFormat(spr.cost)}만원/  */}
                  작업기간 {datePrint(spr.date)}/ 납품지역{" "}
                  {spr.jobIndex === subProjects.length
                    ? projectInfo.region
                    : spr.nextRegion.region}
                  {/* spr.nextRegion.address */}
                </div>

                {findApplyId(iss.applyInfo, spr.jobIndex) !== false ? (
                  <div className="rcd_state">
                    <button
                      className={"default_bt change_bt"}
                      onClick={() => {
                        history.push(
                          `/mypage/pro/apply/detail/${findApplyId(
                            iss.applyInfo,
                            spr.jobIndex
                          )}`
                        );
                      }}
                    >
                      내용보기
                    </button>
                    <span>지원중</span>
                  </div>
                ) : (
                  <div className="rcd_state">{spr.status}</div>
                )}
                {/* (지원자 {spr.applicant}명) */}
              </div>
            </div>
          ))}
          {review && <div className="info_title">프로젝트 리뷰</div>}
          {review && (
            <div className="recruit_review">
              <div className="img_part">
                <img src={`${reviewImg}`} alt={"이미지가없습니다."}></img>
              </div>
              <div className="contents_part">
                {review.contents.length > 134
                  ? review.contents.slice(0, 100) + "..."
                  : review.contents}
              </div>
            </div>
          )}
          <div className="apply_section">
            <div className="info_desc">
              {!review &&
                (subProjects.length === 0
                  ? "* 해당 프로젝트는 하위 업무 분할 요청이 없어 단일 업무로 처리됩니다."
                  : "* 업무별 각각 개별 지원도 가능합니다.(지원신청페이지 안에서 선택가능)")}
            </div>
            <div className="info_desc">
              * 상기 일정은 프로젝트 진행 상황에 따라 변경 될 수 있습니다.
            </div>
            {button.isThere === true && (
              <Link to={button.linkTo}>
                <button className={button.className} onClick={button.onClick}>
                  {button.text}
                </button>
              </Link>
            )}
            {workEndConfirm_bt === true && (
              <button
                className={"default_bt apply_bt"}
                onClick={workEndConfirm}
              >
                프로젝트종료
              </button>
            )}
            {iss.isOwn &&
              projectInfo.status === "업무종료" &&
              projectInfo.review === null && (
                <button
                  className={"default_bt apply_bt"}
                  onClick={() => {
                    if (
                      window.confirm(
                        "해당 프로젝트 수행 업무에 대한 리뷰를 작성하시겠습니까?"
                      )
                    )
                      history.push({
                        pathname: `/recruit/review/write/${id}`,
                        state: { project: projectInfo },
                      });
                  }}
                >
                  리뷰작성
                </button>
              )}
            <button
              className={"default_bt prev_bt"}
              onClick={() => {
                history.goBack();
              }}
            >
              뒤로가기
            </button>
            {iss.isManager === true && (
              <div className="recruit_admin_section">
                <div className="info_title">상태변경</div>
                <div className="rcs_contents manager_status">
                  <ul>
                    {status.map((item, index) => {
                      return (
                        <li key={index}>
                          <button
                            className="default_bt status_bt"
                            onClick={() => {
                              changeProcess(item);
                            }}
                          >
                            {item}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default RecruitDetail;
