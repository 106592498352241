import React, { useState, useEffect } from "react";
import * as baseActions from "../../store/base";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "axios";
import geoInfo from "../../geoInfo/sigungu";
import ProCate from "../../component/proCate";
import { useHistory, useParams } from "react-router-dom";

function ProjectWriteV2(props) {
  const [recruitEndDate, setRecruitEndDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [files, setFiles] = useState([]);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm(); //
  const [geo, setGeo] = useState({ geo1: "미정", geo2: "미정" });
  const [geoSelected, setGeoSelected] = useState(geoInfo[0]);
  const token = localStorage.getItem("token");
  const [proCateItems, setProCateItems] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [projectInfo, setProjectInfo] = useState({});
  const [, setSubProjectInfo] = useState({}); //subProjectInfo
  const [originCate, setOriginCate] = useState([]);
  const [modifyInfo, setModifyInfo] = useState({ modify: false, content: {} });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요한 페이지 입니다.");
      history.push({
        pathname: "/login",
      });
    }
    axios
      .get("/rest-api/getProject", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      })
      .then(function (result) {
        //console.log("res: ", result.data);
        if (result.status === 200) {
          const info = result.data.project.modifyRequest.modify
            ? result.data.project.modifyRequest.content
            : result.data.project;
          setProjectInfo(result.data.project);
          setSubProjectInfo(result.data.subProjects);
          if (result.data.project.modifyRequest.modify) {
            setModifyInfo(result.data.project.modifyRequest);
            setOriginCate([
              ...result.data.project.modifyRequest.content.category,
            ]);
          } else setOriginCate([...result.data.project.category]);
          setFiles(info.file);
          setRecruitEndDate(new Date(info.applyDueDate));
          setDueDate(new Date(info.dueDate));
          let geoTemp = result.data.project.modifyRequest.modify
            ? result.data.project.modifyRequest.content.region
            : result.data.project.region;
          geoTemp = String(geoTemp).split(" ");
          setGeo({ geo1: geoTemp[0], geo2: geoTemp[1] });
          setValue("geo1", geoTemp[0]);
          geoInfo.map((item, index) => {
            if (item.name === geoTemp[0]) setGeoSelected(geoInfo[index]);
            return 0;
          });
          setValue("geo2", geoTemp[1]);

          axios
            .get("/rest-api/getClientCategory", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then(function (res) {
              //console.log("info: ", info);
              if (res.status === 200) {
                if (info.category[0].name !== "매니징") {
                  let tempCate = res.data.category;
                  info.category.map((item) => {
                    tempCate.map((cate) => {
                      if (cate.idx === item.idx) cate.value = true;
                      return 0;
                    });
                    return 0;
                  });
                  setProCateItems([...tempCate]);
                } else setProCateItems([...res.data.category]);
              } else console.log(res.data.error);
            });
        } else console.log(result.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const geoChange = (e) => {
    const selectGeo = geoInfo.filter((item) => item.name === e.target.value);
    //console.log(e, selectGeo);
    setGeoSelected(selectGeo[0]);
  };
  // const geo2Change = (e) => {
  //   // 에러 메세지 지우기
  // };
  const projectSubmit = (data) => {
    //console.log("data: ", data);
    const selectCate = proCateItems.reduce((acc, item) => {
      if (item.value === true) acc.push(item.idx);
      return acc;
    }, []);

    axios
      .post(
        "/rest-api/modifyProject",
        {
          id: projectInfo.id,
          title: data.title,
          companyName: data.name,
          cost: data.price,
          region: data.geo1 + " " + data.geo2,
          applyDueDate: recruitEndDate,
          dueDate: dueDate,
          category: [...selectCate],
          content: data.contents,
          file: files,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        if (response.data.code === 200) {
          alert("프로젝트가 성공적으로 등록(수정)되었습니다.");
          axios
            .get("/rest-api/getUser", {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((response) => {
              dispatch(baseActions.setUserInfo(response.data.user));
            });
          //setIsCom(true);
          history.push("/admin/recruitList");
        }
      });
  };
  const proCateChange = (item) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        return cate.name === item.name ? { ...item, value: !item.value } : cate;
      })
    );
  };
  return (
    <div className="contents_wrap">
      <div className="div_page">
        <form onSubmit={handleSubmit(projectSubmit)}>
          <div className="page_title">
            프로젝트 {modifyInfo.modify ? "수정 등록" : "신규 등록"}
          </div>
          <div className="page_contents project_register">
            <div className="input_item">
              <div className="input_title">요청제목</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify
                  ? modifyInfo.content.title
                  : projectInfo.title}
              </div>
            </div>
            <div className="input_item wide_item">
              <div className="input_title">등록제목</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="요청 내용을 간략히 입력해주세요."
                  name={"title"}
                  defaultValue={
                    modifyInfo.modify
                      ? modifyInfo.content.title
                      : projectInfo.title
                  }
                  {...register("title", {
                    required: "프로젝트 제목을 입력해주세요",
                  })}
                />
                {errors?.title && (
                  <div className={"error_msg"}>{errors?.title?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 회사이름</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify
                  ? modifyInfo.content.companyName
                  : projectInfo.companyName}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">등록 회사이름</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="회사명을 입력해주세요"
                  name={"name"}
                  {...register("name", {
                    required: "회사명을 입력해주세요",
                  })}
                  defaultValue={
                    modifyInfo.modify
                      ? modifyInfo.content.companyName
                      : projectInfo.companyName
                  }
                />
                {errors?.name && (
                  <div className={"error_msg"}>{errors?.name?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 예산</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify ? modifyInfo.content.cost : projectInfo.cost}
                만원
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">등록 예산</div>
              <div className="input_contents">
                <input
                  type="text"
                  placeholder="희망예산"
                  name={"price"}
                  {...register("price", {
                    required: "희망예산을 입력해주세요",
                  })}
                  defaultValue={
                    modifyInfo.modify
                      ? modifyInfo.content.cost
                      : projectInfo.cost
                  }
                />
                만원
                {errors?.price && (
                  <div className={"error_msg"}>{errors?.price?.message}</div>
                )}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 납품지역</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify
                  ? modifyInfo.content.region
                  : projectInfo.region}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">등록 납품지역</div>
              <div className="input_contents">
                <select
                  name="geo1"
                  //value={geo.geo1}
                  {...register("geo1")}
                  onChange={geoChange}
                >
                  {geoInfo.map((item, index) => (
                    <option key={index}>{item.name}</option>
                  ))}
                </select>
                <select
                  name="geo2"
                  //defaultValue={geo.geo2}
                  {...register("geo2")}
                >
                  {geoSelected.gugun.map((item, index2) => (
                    <option key={index2}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 모집마감일</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify
                  ? modifyInfo.content.applyDueDate
                  : projectInfo.applyDueDate}
              </div>
            </div>
            <div className="input_item date_item">
              <div className="input_title">등록 모집마감일</div>
              <div className="input_contents">
                <DatePicker
                  locale="ko"
                  dateFormat="yyyy/MM/dd(eee)"
                  selected={recruitEndDate}
                  onChange={(date) => {
                    // console.log(date);
                    setRecruitEndDate(date);
                  }}
                  // defaultValue={
                  //   modifyInfo.modify
                  //     ? modifyInfo.content.applyDueDate
                  //     : projectInfo.applyDueDate
                  // }
                />
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 납기일</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify
                  ? modifyInfo.content.dueDate
                  : projectInfo.dueDate}
              </div>
            </div>
            <div className="input_item date_item">
              <div className="input_title">등록 납기일</div>
              <div className="input_contents">
                <DatePicker
                  locale="ko"
                  dateFormat="yyyy/MM/dd(eee)"
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                  // defaultValue={
                  //   modifyInfo.modify
                  //     ? modifyInfo.content.dueDate
                  //     : projectInfo.dueDate
                  // }
                />
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 업무분야</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {originCate.map((cat, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && ", "}
                      {cat.name}
                    </span>
                  );
                })}
                {/* {
                  modifyInfo.modify === false && 
                  projectInfo.category.map((item, index) => {
                    return (
                      <span key={index}>
                        {index !== 0 && ", "}
                        {item.name}
                      </span>
                    );
                  })
                }
                {modifyInfo.modify !== false &&
                  modifyInfo.content.category.map((item, index) => {
                    return (
                      <span key={index}>
                        {index !== 0 && ", "}
                        {item.name}
                      </span>
                    );
                  })} */}
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">업무분야</div>
              <div className="input_contents">
                {
                  <ProCate
                    proItems={proCateItems}
                    proChange={proCateChange}
                  ></ProCate>
                }
              </div>
            </div>
            <div className="input_item">
              <div className="input_title">요청 세부내용</div>
              <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
                {modifyInfo.modify
                  ? modifyInfo.content.content
                  : projectInfo.content}
              </div>
            </div>
            <div className="input_item full_item">
              <div className="input_title">세부내용</div>
              <div className="input_contents">
                <textarea
                  name={"contents"}
                  {...register("contents", {
                    required: "세부내용을 작성해주세요",
                  })}
                  defaultValue={
                    modifyInfo.modify
                      ? modifyInfo.content.content
                      : projectInfo.content
                  }
                  placeholder="요청 내용을 자유롭게 작성해주세요. 목적, 의뢰범위, 사양, 수량 등 자세히 적을수록 매칭될 확률이 높습니다. 작성이 어려울 경우 '상담요청'이라고 기입해주세요. 저희 담당매니저가 전화로 자세히 안내해드리겠습니다."
                ></textarea>
                {errors?.contents && (
                  <div className={"error_msg"}>{errors?.contents?.message}</div>
                )}
              </div>
            </div>
          </div>
          <div className="input_item">
            <div className="input_title" style={{ paddingLeft: 40 + "px" }}>
              첨부파일
            </div>
            <div className="input_contents" style={{ paddingTop: 10 + "px" }}>
              {files.map((item, index) => {
                return (
                  <span key={index}>
                    {index !== 0 && <br />}
                    <a href={item.url} download>
                      {item.originalFileName}
                    </a>
                  </span>
                );
              })}
              {files.length === 0 && <span>"첨부파일이 없습니다."</span>}
            </div>
          </div>
          <div className="page_bottom">
            <button className="default_bt submit_bt" type={"submit"}>
              등록하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default ProjectWriteV2;
