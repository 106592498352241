import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

function SendPWEmail(props) {
  const { handleSubmit, register, errors, watch } = useForm();

  const sendPWEmailSubmit = async (data) => {
    axios
      .post("/rest-api/PWChangeEmailRequest", {
        email: watch("email"),
      })
      .then((res) => {
        if (res.status === 200) alert(res.data.message);
        else alert(res.data.error);
      });
  };

  return (
    <div className="login_page">
      <div className="login_section">
        <div className="logo_section">
          <a href={"/"}>
            <img src="/img/logo.svg" alt="logo.svg" />
          </a>
        </div>
        <div className="lg_title">비밀번호 변경 이메일 보내기</div>
        <div className="lg_input_section">
          <form onSubmit={handleSubmit(sendPWEmailSubmit)}>
            <div className="input_item">
              <input
                type="text"
                placeholder="이메일"
                name={"email"}
                {...register("email", {
                  required: "이메일을 입력해주세요",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "이메일형식으로 입력해주세요",
                  },
                })}
              />
              {errors?.email && (
                <div className={"error_msg"}>{errors?.email?.message}</div>
              )}
            </div>
            <div className="input_item" style={{ marginTop: 20 + "px" }}>
              <button className="default_bt join_bt" type={"submit"}>
                메일 요청
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default SendPWEmail;
