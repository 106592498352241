import React, { useState, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; //connect,
import * as baseActions from "../store/base";
import useBus from "use-bus";
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
import { useForm } from "react-hook-form";
import axios from "axios";
import Moment from "react-moment";

function Header(props) {
  const [popAlertView, setPopAlertView] = useState(false);
  const [popSubmenuView, setPopSubmenuView] = useState(false);
  let token = localStorage.getItem("token");
  const [stickyMode, setStickyMode] = useState(false);
  const [mobileMeneView, setMobileMenuView] = useState(false);
  const [mobilePopAlertView, setMobilePopAlertView] = useState(false);
  const { handleSubmit, register } = useForm();
  const history = useHistory();

  const dispatch = useDispatch();
  let current_page = useLocation().pathname;
  const userInfo = useSelector((state) => state.userInfo);
  const [alarmList, setAlarmList] = useState([]);
  const [alarmCount, setAlarmCount] = useState(0);
  const [buttons, setButtons] = useState({
    adminMenu: false,
    requestList: false,
    expertEnrollList: false,
    expertEnroll: false,
    applyList: false,
    expertInfo: false,
  });
  const [helpActive, setHelpActive] = useState(false);

  useEffect(() => {
    //https://velog.io/@eunjin/React-useEffect-%EB%AC%B4%ED%95%9C%EB%A3%A8%ED%94%84-%ED%83%88%EC%B6%9C%ED%95%98%EB%8A%94-%EB%B0%A9%EB%B2%95
    const userFetch = () => {
      if (token)
        axios
          .get("/rest-api/getUser", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            const user = response.data.user;
            if (user) {
              dispatch(baseActions.setUserInfo(user));
              switch (user.userLevel) {
                case "manager":
                  setButtons({
                    adminMenu: true,
                    requestList: true,
                    expertEnrollList: true,
                    expertEnroll: false,
                    applyList: true,
                    expertInfo: true,
                  });
                  break;
                case "expert":
                case "expert(modify)":
                  setButtons({
                    adminMenu: false,
                    requestList: false,
                    expertEnrollList: false,
                    expertEnroll: false,
                    applyList: true,
                    expertInfo: true,
                  });
                  break;
                case "client":
                case "expert(pre)":
                  setButtons({
                    adminMenu: false,
                    requestList: false,
                    expertEnrollList: false,
                    expertEnroll: true,
                    applyList: false,
                    expertInfo: false,
                  });
                  break;
                default:
                  setButtons({
                    adminMenu: false,
                    requestList: false,
                    expertEnrollList: false,
                    expertEnroll: false,
                    applyList: false,
                    expertInfo: false,
                  });
              }
            } else {
              logout();
              window.location.href = "/login";
            }
          });
    };

    const alarmCountGet = setInterval(() => {
      axios
        .get("/rest-api/getAlerts", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            status: "new",
            amount: 100,
            index: 1,
          },
        })
        .then((response) => {
          setAlarmCount(response.data.newAlertCount);
        });
      return 0;
    }, 10000);

    // if (!token) {
    //   token = localStorage.getItem("token");
    // } else {
    userFetch();
    return () => clearInterval(alarmCountGet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setMobileMenuView(false);
  }, [current_page]);

  useBus(
    "popup_clear",
    () => {
      setPopAlertView(false);
      setMobilePopAlertView(false);
      setPopSubmenuView(false);
    },
    []
  );

  const logout = async (e) => {
    const pushid = localStorage.getItem("pushid");
    const deviceid = localStorage.getItem("deviceid");
    const os_version = localStorage.getItem("os_version");
    //console.log(pushid, deviceid, os_version);
    try {
      if (pushid) {
        const response = await axios.post(
          "/rest-api/updateAppInfo",
          {
            pushid,
            user_name: "",
            user_email: "",
            user_id: "",
            token: "",
            deviceid: deviceid,
            os_version: os_version,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response.data.code === 200) {
          console.log("app 로그인 정보 갱신 완료");
        } else window.alert(response.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(baseActions.logout());
    }
  };

  const alarmView = (e, t) => {
    const type = t === undefined ? "PC" : t;
    axios
      .get("/rest-api/getAlerts", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          status: "all",
          amount: 10,
          index: 1,
        },
      })
      .then((response) => {
        setAlarmList(response.data.alerts);
      });
    if (type === "mobile") {
      setMobilePopAlertView(!mobilePopAlertView);
      setMobileMenuView(false);
    } else {
      setPopSubmenuView(false);
      setPopAlertView(!popAlertView);
    }
    e.stopPropagation();
  };
  const alarmClick = (alarm) => {
    if (alarm === "all")
      axios
        .get("/rest-api/setReadAlert", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            isAll: true,
          },
        })
        .then((response) => {
          alarmList.map((item) => {
            if (item.id === alarm.id) item.isRead = true;
            return 0;
          });
          setAlarmList([...alarmList]);
        });
    else
      axios
        .get("/rest-api/setReadAlert", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            alertIds: [alarm.id],
          },
        })
        .then((response) => {
          if (alarm.guideLink !== "/") history.push(alarm.guideLink);
          else {
            alarmList.map((item) => {
              if (item.id === alarm.id) item.isRead = true;
              return 0;
            });
            setAlarmList([...alarmList]);
          }
        });
  };

  const searchSubmit = (data) => {
    const keyword = mobileMeneView ? data.searchKeyMobile : data.searchKey;
    history.push({
      pathname: "/recruit",
      search: "?search=" + keyword,
    });
  };

  return (
    <StickyHeader
      stickyOffset={-200}
      onSticky={(mode) => {
        setStickyMode(mode);
      }}
      header={
        <div
          className={
            "header " +
            (current_page === "/signup" ||
            current_page === "/login" ||
            current_page === "/passwordChange" ||
            current_page === "/idSearch" ||
            current_page === "/join"
              ? "hide_header"
              : "") +
            (current_page === "/intro" //|| current_page === "/"
              ? "intro_header "
              : "") +
            (current_page === "/intro" && !stickyMode ? "white" : "")
          }
        >
          <div className={"help_section " + (helpActive ? "active" : "")}>
            <div
              className="default_img"
              onClick={() => {
                setHelpActive(true);
              }}
            >
              ?
            </div>
            <ul className="help_items">
              <li className="help_item">
                <a href="/request/register">
                  간편
                  <br />
                  문의
                </a>
              </li>
              <li className="help_item kakao_item">
                <a href="https://pf.kakao.com/_wbxcnxb/chat">
                  카카오톡
                  <br />
                  문의
                </a>
              </li>
              <li className="help_item phone_item">
                <a href="tel:02-6964-6479">
                  전화
                  <br />
                  문의
                </a>
              </li>
              <li
                className="help_item close_item"
                onClick={() => {
                  setHelpActive(false);
                }}
              >
                <img src="/img/ic_x_bt.svg" alt="ic_x_bt.svg" />
              </li>
            </ul>
          </div>
          <div className="logo">
            <a href={"/"}>
              <img
                src={
                  "/img/logo" +
                  (current_page === "/intro" && !stickyMode && !mobileMeneView
                    ? "_w"
                    : "") +
                  ".svg"
                }
                alt="logo"
              />
            </a>
          </div>
          {/* <div className="search_section">
            <form onSubmit={handleSubmit(searchSubmit)}>
              <input
                type="text"
                placeholder="원하는 프로젝트를 입력해주세요"
                name={"searchKey"}
                maxLength={20}
                {...register("searchKey")}
              />
              <img src="/img/ic_search.svg" alt="ic_search.svg" />
            </form>
          </div> //23.10.11 임시삭제 */}
          <div className="main_menu">
            <ul>
              <li className={current_page === "/recruit" ? "active" : ""}>
                {/* <a href={"/recruit"}>모집프로젝트</a> */}
                <Link to={"/recruit"}>모집프로젝트</Link>
              </li>
              {token && (
                <li
                  className={
                    current_page === "/recruit/register" ? "active" : ""
                  }
                >
                  <Link to={"/recruit/register"}>프로젝트등록</Link>
                </li>
              )}
              <li className={current_page === "/intro" ? "active" : ""}>
                <Link to={"/intro"}>서비스소개</Link>
              </li>
              {!token && (
                <li className={current_page === "/login" ? "active" : ""}>
                  <Link to={"/login"}>로그인/회원가입</Link>
                </li>
              )}
              {token && (
                <li>
                  <span
                    onClick={(e) => {
                      alarmView(e);
                    }}
                  >
                    알림
                    {alarmCount !== 0 && (
                      <span className={"alert_count"}>
                        {alarmCount >= 100 ? "99+" : alarmCount}
                      </span>
                    )}
                  </span>
                  {popAlertView && (
                    <div className={"alert_pop sub_pop"}>
                      <div className={"sub_pop_header"}>
                        알림{" "}
                        <span
                          className={"header_right_bt"}
                          onClick={(event) => {
                            event.preventDefault();
                            alarmClick("all");
                          }}
                        >
                          모두 읽은상태표시
                        </span>
                      </div>
                      <div className={"sub_pop_contents"}>
                        <div className={"alert_items"}>
                          {alarmCount !== 0 &&
                            alarmList.map((alarm, index) => (
                              <div
                                onClick={(event) => {
                                  event.preventDefault();
                                  alarmClick(alarm);
                                }}
                                key={index}
                                className={
                                  "alert_item" + (alarm.isRead ? " readed" : "")
                                }
                              >
                                {alarm.content.length > 70
                                  ? alarm.content.slice(0, 70) + "..."
                                  : alarm.content}
                                <span className={"alert_time"}>
                                  <Moment fromNow local="ko">
                                    {alarm.createdAt}
                                  </Moment>
                                </span>
                              </div>
                            ))}
                          {alarmCount === 0 && (
                            <div
                              className={"no_recruit_contents"}
                              style={{ margin: 10 + "px", color: "gray" }}
                            >
                              새로운 알림이 없습니다.
                            </div>
                          )}
                        </div>
                      </div>
                      <Link to={"/alarmHistory"}>
                        <div className={"sub_pop_bottom_bt"}>더보기</div>
                      </Link>
                    </div>
                  )}
                </li>
              )}
              {token && (
                <li
                  className={
                    current_page.indexOf("/mypage/") >= 0 ? "active" : ""
                  }
                >
                  <span
                    onClick={(e) => {
                      setPopAlertView(false);
                      setPopSubmenuView(!popSubmenuView);
                      e.stopPropagation();
                    }}
                  >
                    마이페이지
                  </span>
                  {popSubmenuView && (
                    <div
                      className={
                        "sub_pop mymenu_pop" +
                        (userInfo.userLevel !== "client" ? " wide" : "")
                      }
                    >
                      <ul>
                        <Link to={"/mypage/account"}>
                          <li>회원정보수정</li>
                        </Link>
                        <Link to={"/mypage/project"}>
                          <li>프로젝트관리</li>
                        </Link>
                        <Link to={"/mypage/project/apply"}>
                          <li>업체지원내역</li>
                        </Link>
                        {buttons.expertEnroll && (
                          <Link to={"/mypage/pro/Register/"}>
                            <li>전문가등록</li>
                          </Link>
                        )}
                        {buttons.expertInfo && (
                          <Link to={"/mypage/pro"}>
                            <li>전문가정보관리</li>
                          </Link>
                        )}
                        {buttons.applyList && (
                          <Link to={"/mypage/pro/apply"}>
                            <li>프로젝트지원내역</li>
                          </Link>
                        )}
                        {buttons.adminMenu && (
                          <Link to={"/admin/main"}>
                            <li>관리자메인</li>
                          </Link>
                        )}
                        {/* {buttons.requestList && (
                          <Link to={"/admin/requestList"}>
                            <li>업무요청리스트</li>
                          </Link>
                        )}
                        {buttons.expertEnrollList && (
                          <Link to={"/admin/expertEnrollList"}>
                            <li>전문가신청내역</li>
                          </Link>
                        )} */}
                        <li onClick={logout}>로그아웃</li>
                      </ul>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </div>
          {token && (
            <div
              className="mobile_menu_bt mobile_view alarm_bt"
              onClick={(e) => {
                alarmView(e, "mobile");
              }}
            >
              <img
                src={
                  "/img/ic_alarm" +
                  (current_page === "/intro" && !stickyMode && !mobileMeneView
                    ? "_w"
                    : "") +
                  ".svg"
                }
                alt="ic_alarm"
              />
              {alarmCount !== 0 && (
                <span className={"alert_count"}>
                  {alarmCount >= 100 ? "99+" : alarmCount}
                </span>
              )}
            </div>
          )}
          {mobilePopAlertView && (
            <div className={"m_alert_pop sub_pop"}>
              <div className={"sub_pop_header"}>
                알림{" "}
                <span
                  className={"header_right_bt"}
                  onClick={(event) => {
                    event.preventDefault();
                    alarmClick("all");
                  }}
                >
                  모두 읽은상태표시
                </span>
              </div>
              <div className={"sub_pop_contents"}>
                <div className={"alert_items"}>
                  {alarmCount !== 0 &&
                    alarmList.map((alarm, index) => (
                      <div
                        onClick={(event) => {
                          event.preventDefault();
                          alarmClick(alarm);
                        }}
                        key={index}
                        className={
                          "alert_item" + (alarm.isRead ? " readed" : "")
                        }
                      >
                        {alarm.content.length > 70
                          ? alarm.content.slice(0, 70) + "..."
                          : alarm.content}
                        <span className={"alert_time"}>
                          <Moment fromNow local="ko">
                            {alarm.createdAt}
                          </Moment>
                        </span>
                      </div>
                    ))}
                  {alarmCount === 0 && (
                    <div
                      className={"no_recruit_contents"}
                      style={{ margin: 10 + "px", color: "gray" }}
                    >
                      새로운 알림이 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <Link to={"/alarmHistory"}>
                <div className={"sub_pop_bottom_bt"}>더보기</div>
              </Link>
            </div>
          )}
          <div
            className={
              "mobile_menu_bt mobile_view" + (mobileMeneView ? " active" : "")
            }
            onClick={() => {
              setMobileMenuView(!mobileMeneView);
            }}
          >
            <div className="mm_m mm1"></div>
            <div className="mm_m mm2"></div>
            <div className="mm_m mm3"></div>
          </div>
          {mobileMeneView && (
            <div className="mobile_menu mobile_view">
              <div className="search_section">
                <form onSubmit={handleSubmit(searchSubmit)}>
                  <input
                    type="text"
                    placeholder="원하는 프로젝트를 입력해주세요"
                    name={"searchKeyMobile"}
                    maxLength={20}
                    {...register("searchKeyMobile")}
                  />
                  <img src="/img/ic_search.svg" alt="ic_search.svg" />
                </form>
              </div>
              <div className="main_menu">
                <ul>
                  <li className={current_page === "/recruit" ? "active" : ""}>
                    <a href={"/recruit"}>모집프로젝트</a>
                  </li>
                  <li
                    className={
                      current_page === "/recruit/register" ? "active" : ""
                    }
                  >
                    <Link to={"/recruit/register"}>프로젝트등록</Link>
                  </li>
                  <li className={current_page === "/intro" ? "active" : ""}>
                    <Link to={"/intro"}>서비스소개</Link>
                  </li>
                  {!token && (
                    <Link to={"login"}>
                      <li>로그인/회원가입</li>
                    </Link>
                  )}
                </ul>
              </div>
              {token && (
                <div className="sm_contents">
                  <div className="sm_items full_items">
                    <div className="sm_item active">
                      <Link to={"/mypage/account"}>회원정보수정</Link>
                    </div>
                    <div className="sm_item" onClick={logout}>
                      로그아웃
                    </div>
                  </div>
                  <div className="sm_items">
                    <div className="sm_cate_title">클라이언트</div>
                    <Link to={"/mypage/project"}>
                      <div className="sm_item">프로젝트 관리</div>
                    </Link>
                    <Link to={"/mypage/project/apply"}>
                      <div className="sm_item">업체 지원내역</div>
                    </Link>
                  </div>
                  <div className="sm_items">
                    <div className="sm_cate_title">전문가</div>
                    {userInfo.userLevel === "client" && (
                      <Link to={"/mypage/pro/Register/"}>
                        <div className="sm_item">전문가지원</div>
                      </Link>
                    )}
                    {userInfo.userLevel !== "client" && (
                      <Link to={"/mypage/pro"}>
                        <div className="sm_item">전문가정보관리</div>
                      </Link>
                    )}
                    {userInfo.userLevel !== "client" && (
                      <Link to={"/mypage/pro/apply"}>
                        <div className="sm_item">프로젝트지원내역</div>
                      </Link>
                    )}
                  </div>
                  {buttons.adminMenu && (
                    <div className="sm_items">
                      <Link to={"/admin/main"}>
                        <div className="sm_cate_title">관리자메인</div>
                      </Link>
                      {buttons.requestList && (
                        <Link to={"/admin/requestList"}>
                          <div className="sm_item">업무요청리스트</div>
                        </Link>
                      )}
                      {buttons.expertEnrollList && (
                        <Link to={"/admin/expertEnrollList"}>
                          <div className="sm_item">전문가신청내역</div>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      }
    ></StickyHeader>
  );
}
export default Header;
