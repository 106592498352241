import React, { useState, useEffect, useRef } from "react";
import * as baseActions from "../../store/base";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import axios from "axios";
import geoInfo from "../../geoInfo/sigungu";
import ProCate from "../../component/proCate";
import { useHistory, useParams } from "react-router-dom";
import FileInput from "../../component/fileInput";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject, moneyFormat } from "../../utils/commonApi";

function RecruitRegisterAdmin(props) {
  const [recruitEndDate, setRecruitEndDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [subDueDate, setSubDueDate] = useState([]);
  const [files, setFiles] = useState([]);
  const [subFiles, setSubFiles] = useState([]);
  const [processItems, setProcessItems] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm();
  const [geoSelected, setGeoSelected] = useState(geoInfo[0]);
  const [subGeoSelected, setSubGeoSelected] = useState([]);
  const token = localStorage.getItem("token");
  const [proCateItems, setProCateItems] = useState([]);
  const [isRegist, setIsRegist] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isModify, setIsModify] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [modiProject, setModiProject] = useState({});
  const [subProjectInfo, setSubProjectInfo] = useState({}); //
  const [projectType, setProjectType] = useState("sub");

  const getCateFunc = async () => {
    try {
      const res = await axios.get("/rest-api/getClientCategory", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setProCateItems(res.data.category);
      //console.log(res.data.category);
      //return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const getProjectFunc = async () => {
    try {
      const res = await axios.get("/rest-api/getProject", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: {
          id: Number(id),
        },
      });
      if (res.status === 200) {
        const project = res.data.project;
        const subProjects = res.data.subProjects;
        let cateTemp = [];

        setProjectInfo(project);
        setSubProjectInfo(subProjects);
        if (project.modifyRequest.modify) {
          setIsModify(project.modifyRequest.modify);
          setModiProject(project.modifyRequest.content);
          /*
            title,
            companyName,
            cost,
            region,
            applyDueDate: String(applyDueDate).split('T')[0],
            dueDate: String(dueDate).split('T')[0],
            category: getCategory(setCategory(category, 'client'), 'client'),
            content,
            file,
          */
          cateTemp = [...project.modifyRequest.content.category];
        } else cateTemp = [...project.category];

        for (const cate of cateTemp) {
          proCateChange(cate, true);
        }

        const info = project.modifyRequest.modify
          ? project.modifyRequest.content
          : project;

        setFiles(info.file);
        setRecruitEndDate(new Date(info.applyDueDate));
        setDueDate(new Date(info.dueDate));
        const geoTemp = String(info.region).split(" ");
        geoInfo.map((item, index) => {
          if (item.name === geoTemp[0]) setGeoSelected(geoInfo[index]);
          return 0;
        });
        // set defult values
        [
          { title: info.title },
          { company: info.companyName },
          { price: info.cost },
          { contents: info.content },
          { geo1: geoTemp[0] },
          { geo2: geoTemp[1] },
        ].map((item) => setValue(Object.keys(item)[0], Object.values(item)[0]));
      }
      //return res;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    if (!token) {
      alert("로그인이 필요한 페이지 입니다.");
      history.push({
        pathname: "/login",
      });
    }
    getCateFunc();
    getProjectFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //
  const fileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setFiles((files) => [...files, result.data]);
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const fileX = (index) => {
    setFiles(files.filter((file, i) => i !== index));
    document.getElementById("fileInput").value = null;
  };
  const subFileChange = (e, index) => {
    [...e.target.files].map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/rest-api/uploadFile", formData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (result) {
          setSubFiles(
            subFiles.map((subFile, idx) =>
              idx === index ? [...subFile, result.data] : subFile
            )
          );
        })
        .catch((error) => {
          alert(
            `파일 [${file.name}] 업로드 중 오류가 발생하였습니다. 확인하시고 다시 업로드 바랍니다.(파일 용량 등 체크)`
          );
        });
      return 0;
    });
  };
  const subFileX = (index, targetIndex) => {
    setSubFiles(
      subFiles.map((subFile, idx) =>
        idx === index
          ? subFiles[index].filter((file, i) => i !== targetIndex)
          : subFile
      )
    );
    document.getElementById("fileInput").value = null;
  };
  const geoChange = (e) => {
    // console.log("target.value: ", e.target.value);
    const selectGeo = geoInfo.filter((item) => item.name === e.target.value);
    // console.log("selectGeo: ", selectGeo[0]);
    setGeoSelected(selectGeo[0]);
  };

  const subGeoChange = (e, index) => {
    // console.log("target.value: ", e.target.value);
    const selectGeo = geoInfo.filter((item) => item.name === e.target.value);
    // console.log("selectGeo: ", selectGeo[0]);
    setSubGeoSelected(
      subGeoSelected.map((geoSelect, idx) =>
        idx === index ? selectGeo[0] : geoSelect
      )
    );
  };

  const projectSubmit = async (data) => {
    try {
      const selectCate = proCateItems.reduce((acc, item) => {
        if (item.value === true) acc.push(item.idx);
        return acc;
      }, []);
      const subProjects =
        projectType === "sub" && data.processAr.length > 0
          ? data.processAr.map((sub, index) => {
              if (processItems.length <= index) return 0;
              else {
                let returnValue = sub;
                returnValue.offerCost = returnValue.cost;
                delete returnValue["cost"];
                returnValue.preDueDate = subDueDate[index].preDueDate;
                returnValue.nextDueDate = subDueDate[index].nextDueDate;
                returnValue.applyDueDate = subDueDate[index].dueDate;
                returnValue.region = returnValue.geo1 + " " + returnValue.geo2;
                returnValue.jobIndex = index + 1;
                returnValue.isJobEndPoint = index === data.processAr.length - 1;
                returnValue.file = subFiles[index];
                return returnValue;
              }
            })
          : [];
      let resultForm = {
        main: { result: "fail", message: "No Text" },
        sub: { result: "none", message: "No Text" },
      };

      const resMain = await axios.post(
        "/rest-api/modifyProject",
        {
          id: projectInfo.id,
          title: data.title,
          companyName: data.name,
          cost: data.price,
          region: data.geo1 + " " + data.geo2,
          applyDueDate: recruitEndDate,
          dueDate: dueDate,
          category: [...selectCate],
          content: data.contents,
          file: files,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const resSub =
        projectType === "sub"
          ? await axios.post(
              "/rest-api/postSubProject",
              {
                mainProjectId: projectInfo.id,
                requestAmount: subProjects.length,
                subProjects,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            )
          : { data: { code: 200, message: "No Text" } };
      resultForm = {
        main: {
          result: resMain.data.code,
          message: resMain.data.message,
        },
        sub: {
          result: resSub.data.code,
          message: resSub.data.message,
        },
      };

      if (resultForm.main.result === 200 && resultForm.sub.result === 200) {
        alert(
          `메인프로젝트와 서브프로젝트 ${subProjects.length}개가 등록되었습니다.`
        );
        history.push("/admin/recruitList");
      } else console.log(resultForm);
    } catch (error) {
      console.log(error);
    }
  };
  const proCateChange = (item, init = false) => {
    setProCateItems((proCate) =>
      proCate.map((cate) => {
        const reValue =
          cate.name === item.name
            ? { ...item, value: init ? true : !item.value }
            : cate;
        return reValue;
      })
    );
  };

  return (
    <div className="contents_wrap">
      {isRegist === true && (
        <div className={"div_page"}>
          <div className="page_title">프로젝트 요청완료</div>
          <div className={"page_contents"}>
            <div className={"regi_ing"}>
              프로젝트 등록 요청이 완료되었습니다.
              <div className={"info_desc"}>
                프로젝트 업무 검토는 영업일기준 1~3일정도 소요됩니다.
                <br />
                프로젝트는 매니저 검토 후 자동으로 게시됩니다.
              </div>
            </div>
          </div>
        </div>
      )}
      {isRegist === false && (
        <div className="div_page">
          <form onSubmit={handleSubmit(projectSubmit)}>
            <div className="page_title">프로젝트 관리자 등록</div>
            <div className="page_contents project_register">
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 제목
                </div>
                <div className="info_contents">{projectInfo.title}</div>
              </div>
              <div className="input_item wide_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 제목
                </div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="등록할 프로젝트 제목작성(기본은 요청 프로젝트 제목표시)"
                    name={"title"}
                    {...register("title", {
                      required: "프로젝트 제목을 입력해주세요",
                    })}
                  />
                  {errors?.title && (
                    <div className={"error_msg"}>{errors?.title?.message}</div>
                  )}
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 회사
                </div>
                <div className="info_contents">
                  {isModify ? modiProject.companyName : projectInfo.companyName}
                </div>
              </div>
              <div className="input_item wide_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 회사
                </div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="회사명을 입력해주세요(기본은 요청 회사명으로 표시)"
                    name="company"
                    {...register("company")}
                  />
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 예산
                </div>
                <div className="info_contents">
                  {isModify ? modiProject.cost : projectInfo.cost} 만원
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 예산
                </div>
                <div className="input_contents">
                  <input
                    type="text"
                    placeholder="등록 예산(기본 요청예산)"
                    name={"price"}
                    {...register("price", {
                      required: "희망예산을 입력해주세요",
                    })}
                  />
                  만원
                  {errors?.price && (
                    <div className={"error_msg"}>{errors?.price?.message}</div>
                  )}
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 납품지역
                </div>
                <div className="info_contents">
                  {isModify ? modiProject.region : projectInfo.region}
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 납품지역
                </div>
                <div className="input_contents">
                  <select
                    name="geo1"
                    //value={geo.geo1}
                    {...register("geo1")}
                    onChange={geoChange}
                  >
                    {geoInfo.map((item, index) => (
                      <option key={index}>{item.name}</option>
                    ))}
                  </select>
                  <select
                    name="geo2"
                    //defaultValue={geo.geo2}
                    {...register("geo2")}
                  >
                    {geoSelected.gugun.map((item, index2) => (
                      <option key={index2}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 모집마감일
                </div>
                <div className="info_contents">
                  {isModify
                    ? modiProject.applyDueDate
                    : projectInfo.applyDueDate}
                </div>
              </div>
              <div className="input_item date_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 모집마감일
                </div>
                <div className="input_contents">
                  <DatePicker
                    locale="ko"
                    dateFormat="yyyy/MM/dd(eee)"
                    selected={recruitEndDate}
                    onChange={(date) => {
                      setRecruitEndDate(date);
                    }}
                  />
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 납기일
                </div>
                <div className="info_contents">
                  {isModify ? modiProject.dueDate : projectInfo.dueDate}
                </div>
              </div>
              <div className="input_item date_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 납기일
                </div>
                <div className="input_contents">
                  <DatePicker
                    locale="ko"
                    dateFormat="yyyy/MM/dd(eee)"
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                  />
                </div>
              </div>
              <div className="info_item">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 업무분야
                </div>
                <div className="info_contents">
                  {!isEmptyObject(projectInfo) &&
                    projectInfo.category.map((cat, index) => {
                      if (index === 0) return cat.name;
                      else return ", " + cat.name;
                    })}
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 업무분야
                </div>
                <div className="input_contents">
                  <ProCate
                    proItems={proCateItems}
                    proChange={proCateChange}
                    // initCate={
                    //   isModify ? modiProject.category : projectInfo.category
                    // }
                  ></ProCate>
                </div>
              </div>
              <div className="recruit_summary">
                <div className="info_title">
                  {isModify ? "기존" : "요청"} 내용
                </div>
                <div className="rcs_contents">{projectInfo.content}</div>
              </div>
              <div className="input_item full_item">
                <div className="input_title">
                  {isModify ? "수정" : "등록"} 내용
                </div>
                <div className="input_contents">
                  <textarea
                    name={"contents"}
                    {...register("contents", {
                      required: "세부내용을 작성해주세요",
                    })}
                    placeholder="요청 내용을 자유롭게 작성해주세요. 목적, 의뢰범위, 사양, 수량 등 자세히 적을수록 매칭될 확률이 높습니다. 작성이 어려울 경우 '상담요청'이라고 기입해주세요. 저희 담당매니저가 전화로 자세히 안내해드리겠습니다."
                  ></textarea>
                  {errors?.contents && (
                    <div className={"error_msg"}>
                      {errors?.contents?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="rc_option_items attachFiles">
                <div className="info_item">
                  <div className="info_title">첨부파일</div>
                  <div className="info_contents">
                    {files.length === 0 && (
                      <span className="file_item">첨부 파일이 없습니다.</span>
                    )}
                    {files.length > 0 &&
                      files.map((item, index) => {
                        return (
                          <span className="file_item" key={index}>
                            {index !== 0 && <br />}
                            <a href={item.url} download>
                              {item.originalFileName}
                            </a>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="input_item">
                <div className="input_title">프로젝트 형태</div>
                <div className="input_contents">
                  <span className="on_off_value">통합수행모집 </span>
                  <span className="on_off_bt">
                    <label>
                      <input
                        type="checkbox"
                        className="check_toggle"
                        checked={projectType === "sub"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setProjectType("sub");
                          }
                          // else if (processItems.length > 0) {
                          //   if (
                          //     window.confirm(
                          //       "모든 서브 프로젝트 데이터를 삭제하고 통합수행으로 변경하시겠습니까?"
                          //     )
                          //   ) {
                          //     setProcessItems([]);
                          //     setProjectType("main");
                          //   } else e.target.checked = true;
                          // }
                          else if (
                            window.confirm(
                              "공정 분할은 필수사항입니다. 통합 프로젝트로 변경하시겠습니까?"
                            )
                          ) {
                            setProcessItems([]);
                            setProjectType("main");
                          } else {
                            e.target.checked = true;
                            setProjectType("sub");
                          }
                        }}
                      />
                      <span className="toggle_bt"></span>
                    </label>
                  </span>
                  <span className="on_off_value">분할공정모집</span>
                </div>
              </div>
              <FileInput
                titleYN={true}
                files={files}
                fileChange={fileChange}
                fileX={fileX}
              />
              <div className="input_item">
                <div className="input_title">분할 공정 등록</div>
                <div className="input_contents">
                  {projectType === "main" && (
                    <span className="on_off_value">
                      통합 모집 프로젝트입니다. 세부 프로젝트를 추가하시려면
                      프로젝트 타입을 먼저 변경해주세요.
                    </span>
                  )}
                  {projectType === "sub" && (
                    <button
                      className="default_bt add_process_bt"
                      onClick={() => {
                        const subIndex = processItems.length;
                        setProcessItems([...processItems, {}]);
                        setSubDueDate([
                          ...subDueDate,
                          {
                            preDueDate: subIndex
                              ? subDueDate[subIndex - 1].nextDueDate
                              : recruitEndDate,
                            dueDate: recruitEndDate,
                            nextDueDate: dueDate,
                          },
                        ]);
                        setSubGeoSelected([...subGeoSelected, geoInfo[0]]);
                        setSubFiles([...subFiles, []]);
                      }}
                    >
                      공정추가
                      <img src="/img/ic_plus_bt.svg" alt="ic_plus_bt.svg" />
                    </button>
                  )}
                </div>
              </div>
              <div className="add_process_items">
                {projectType === "sub" &&
                  processItems.map((pr, index) => (
                    <div key={"pr" + index} className="ap_item">
                      <div className="ap_title">
                        공장 #{index + 1}. 내용작성
                      </div>
                      <div
                        className="ap_close_bt"
                        onClick={() => {
                          setProcessItems(
                            processItems.filter(
                              (prfItem, fIndex) => fIndex !== index
                            )
                          );
                          // setSubDueDate(
                          //   setSubDueDate.filter(
                          //     (prfItem, fIndex) => fIndex !== index
                          //   )
                          // );
                          // setSubGeoSelected(
                          //   setSubGeoSelected.filter(
                          //     (prfItem, fIndex) => fIndex !== index
                          //   )
                          // );
                          // setSubFiles(
                          //   setSubFiles.filter(
                          //     (prfItem, fIndex) => fIndex !== index
                          //   )
                          // );
                        }}
                      >
                        <img src="/img/ic_x_bt.svg" alt="ic_x_bt.svg" />
                      </div>
                      <div className="input_item wide_item">
                        <div className="input_title">공정명</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder="ex.옵셋인쇄(공정선택명)"
                            {...register("processAr[" + index + "].process", {
                              required: "공정명을 입력해주세요.",
                            })}
                          />
                        </div>
                        {/* {errors?.processAr[index] && (
                          <div className={"error_msg"}>
                            {errors?.processAr[index].title?.message}
                          </div>
                        )} */}
                      </div>
                      <div className="input_item wide_item">
                        <div className="input_title">카테고리</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder="인쇄, 톰슨, 코팅 등 단순 스트링 작성(','로 구분)"
                            {...register("processAr[" + index + "].category", {
                              required: "카테고리를 입력해주세요.",
                            })}
                          />
                          {/* {errors?.processAr[index].category && (
                            <div className={"error_msg"}>
                              {errors?.processAr[index].category?.message}
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div className="input_item wide_item">
                        <div className="input_title">원단</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder={"300g 스노우화이트 등 작업원단작성"}
                            defaultValue={
                              index > 0
                                ? watch(`processAr[${index - 1}].meterial`)
                                : null
                            }
                            {...register("processAr[" + index + "].meterial", {
                              required: "원단명을 입력해주세요.",
                            })}
                          />
                        </div>
                      </div>
                      <div className="input_item wide_item">
                        <div className="input_title">규격</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder={
                              "394x272mm(8절) 등 작업 원단 사이즈 입력"
                            }
                            defaultValue={
                              index > 0
                                ? watch(`processAr[${index - 1}].size`)
                                : null
                            }
                            {...register("processAr[" + index + "].size", {
                              required: "원단 사이즈를 입력해주세요.",
                            })}
                          />
                        </div>
                      </div>

                      <div className="input_item short_item">
                        <div className="input_title">작업량/여분</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder={"2,300 정매"}
                            defaultValue={
                              index > 0
                                ? watch(`processAr[${index - 1}].fixed`)
                                : null
                            }
                            {...register("processAr[" + index + "].fixed", {
                              required: "정매 수량을 입력해주세요.",
                            })}
                          />
                          <input
                            type="text"
                            placeholder={"200여분"}
                            defaultValue={
                              index > 0
                                ? watch(`processAr[${index - 1}].extra`)
                                : null
                            }
                            {...register("processAr[" + index + "].extra", {
                              required: "여분 수량 입력해주세요.",
                            })}
                          />
                        </div>
                      </div>
                      <div className="input_item date_item date_hidden">
                        <div className="input_title">입고예정일</div>
                        <div className="input_contents">
                          <DatePicker
                            locale="ko"
                            dateFormat="yyyy/MM/dd(eee)"
                            selected={subDueDate[index].preDueDate}
                            onChange={(date) =>
                              setSubDueDate(
                                subDueDate.map((item, idx) =>
                                  idx === index
                                    ? {
                                        preDueDate: date,
                                        dueDate: item.dueDate,
                                        nextDueDate: item.nextDueDate,
                                      }
                                    : item
                                )
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="input_item date_item date_hidden">
                        <div className="input_title">출고예정일</div>
                        <div className="input_contents">
                          <DatePicker
                            locale="ko"
                            dateFormat="yyyy/MM/dd(eee)"
                            selected={subDueDate[index].nextDueDate}
                            onChange={(date) =>
                              setSubDueDate(
                                subDueDate.map((item, idx) =>
                                  idx === index
                                    ? {
                                        preDueDate: item.preDueDate,
                                        dueDate: item.dueDate,
                                        nextDueDate: date,
                                      }
                                    : item
                                )
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="input_item">
                        <div className="input_title">등록 납품지역</div>
                        <div className="input_contents">
                          <select
                            name={"processAr[" + index + "].geo1"}
                            {...register("processAr[" + index + "].geo1")}
                            onChange={(e) => subGeoChange(e, index)}
                          >
                            {geoInfo.map((item, idx) => (
                              <option key={idx}>{item.name}</option>
                            ))}
                          </select>
                          <select
                            name={"processAr[" + index + "].geo2"}
                            {...register("processAr[" + index + "].geo2")}
                          >
                            {subGeoSelected[index].gugun.map((item, idx) => (
                              <option key={idx}>{item.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="input_item wide_item">
                        <div className="input_title">제목(요약)</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder="옵셋/300스노우/300통/21.12.05입고/21.12.07출고(서울 중구)"
                            {...register("processAr[" + index + "].title", {
                              required: "제목(요약)을 입력해주세요.",
                            })}
                          />
                        </div>
                        {/* {errors?.processAr[index] && (
                          <div className={"error_msg"}>
                            {errors?.processAr[index].title?.message}
                          </div>
                        )} */}
                      </div>
                      <div className="input_item date_item">
                        <div className="input_title">공정 모집마감일</div>
                        <div className="input_contents">
                          <DatePicker
                            locale="ko"
                            dateFormat="yyyy/MM/dd(eee)"
                            selected={subDueDate[index].dueDate}
                            onChange={(date) =>
                              setSubDueDate(
                                subDueDate.map((item, idx) =>
                                  idx === index
                                    ? {
                                        preDueDate: item.preDueDate,
                                        dueDate: date,
                                        nextDueDate: item.nextDueDate,
                                      }
                                    : item
                                )
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="input_item short_item">
                        <div className="input_title">기준예산</div>
                        <div className="input_contents">
                          <input
                            type="text"
                            placeholder="매니저작성"
                            {...register("processAr[" + index + "].cost", {
                              required: "견적 기준 금액을 작성해주세요.",
                            })}
                          />
                          만원
                        </div>
                      </div>
                      <div className="input_item">
                        <div className="input_title">작업감리</div>
                        <div className="input_contents">
                          <select
                            name={"processAr[" + index + "].supervising"}
                            {...register(
                              "processAr[" + index + "].supervising"
                            )}
                          >
                            <option>없음</option>
                            <option>대행사</option>
                            <option>광고주</option>
                          </select>
                          {/* <span className="on_off_bt">
                            <label>
                              <input type="checkbox" className="check_toggle" />
                              <span className="toggle_bt"></span>
                            </label>
                          </span>
                          <span className="on_off_value">감리 있음</span> */}
                        </div>
                      </div>
                      <div className="input_item full_item">
                        <div className="input_title">세부내용</div>
                        <div className="input_contents">
                          <textarea
                            placeholder="작업 참조 내용 작성"
                            name={"processAr[" + index + "].description"}
                            {...register(
                              "processAr[" + index + "].description"
                            )}
                          ></textarea>
                        </div>
                      </div>
                      <FileInput
                        titleYN={true}
                        files={subFiles[index]}
                        fileChange={subFileChange}
                        fileX={subFileX}
                        subProInfo={{
                          type: "sub",
                          index,
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div className="page_bottom">
              <button className="default_bt submit_bt" type={"submit"}>
                등록하기
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default RecruitRegisterAdmin;
