import React from "react";
import { useForm } from "react-hook-form";

function FileInput(props) {
  const {
    register,
    formState: { errors },
  } = useForm();
  const { files, fileChange, fileX, titleYN, singleMode, subProInfo } = props;
  const multiple =
    singleMode === undefined || singleMode === null ? true : !singleMode;
  return (
    <div className="input_item file_item">
      {titleYN && <div className={"input_title"}>파일첨부</div>}
      <div className="input_contents">
        <div className="file_input">
          <label>
            {errors?.file && (
              <div className={"error_msg"}>{errors?.file?.message}</div>
            )}
            <input
              type={"file"}
              onChange={(e) =>
                fileChange(
                  e,
                  subProInfo !== undefined ? subProInfo.index : null
                )
              }
              multiple={multiple}
              accept={
                ".jpg,.jpeg,.png,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.hwp,.pdf,.zip"
              }
              id={"fileInput"}
              //{...register("fileInputError")}
            />
            <div className="file_text">
              jpg/jpeg/png/ppt/doc/docx/hwp/pdf/zip
            </div>
            <div className="file_bt">파일찾기</div>
          </label>
        </div>
        <div className={"file_upload_notice"}>
          5M 이하 파일만 업로드 가능합니다.{" "}
          {singleMode === true && <span>(포트폴리오는 1개만 가능)</span>}
        </div>
        <div className={"file_uploaded_items"}>
          {files.map((file, index) => (
            <div className={"fu_item"} key={index}>
              <span className={"fu_title"}>{file.originalFileName}</span>
              <span
                className={"fu_x_bt"}
                onClick={(e) => {
                  fileX(
                    subProInfo !== undefined ? subProInfo.index : index,
                    index
                  );
                }}
              >
                <img src={"/img/ic_x_bt.svg"} alt="ic_x_bt.svg" />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default FileInput;
