import React from "react";

function Privacy(props) {
  return (
    <div className="contents_wrap">
      <div className="div_page">
        <div className="page_title">개인정보취급방침</div>
        <div className={"page_contents terms_contents"}>
          ㈜와이낫엠앤씨(이하 “회사”라 합니다.)는 정보통신망 이용촉진 및
          정보보호 등에 관한 법률, 개인정보 보호법, 통신비밀보호법,
          전기통신사업법 등 개인정보와 관련된 법령을 준수합니다.
          <br />
          <br />
          1. 수집하는 개인정보 항목
          <br />
          회사는 이름, 이메일, 생년월일, 성별, 유무선 연락처, 서비스 이용 기록,
          결제 및 환불 기록 등에 관한 정보를 수집합니다.
          <br />
          서비스 이용과정이나 사업처리 과정에서 방문일시, IP주소, 쿠키,
          사용이력, 기기정보, 접속로그 등이 자동으로 생성되어 수집 될 수
          있습니다.
          <br />
          서비스 이용 문의/ 상담 등 민원처리 과정, 이벤트 과정 등에서 이용자가
          직접 입력하거나 기타 이용자에게 사전 별도의 동의를 받은 개인정보가
          추가로 수집될 수 있습니다.
          <br />
          서비스 이용 과정에서 현금 입금, 환불처리 등을 위하여 이용자로부터
          금융기관명, 계좌번호, 예금주 성명 등의 정보가 수집될 수 있습니다.
          <br />
          <br />
          2. 개인정보 수집 및 이용 목적
          <br />
          회사는 이용자의 동의가 있거나 법령의 규정에 의한 경우 개인정보를
          수집합니다.
          <br />
          회사는 회원 식별, 인증, 회원 자격 유지 관리, 본인확인, 서비스의
          부정적이용 방지, 만 14세 미만 여부 확인, 법정대리인의 동의 처리, 계약
          체결 및 이행 관리, 결제 및 환불, 거래기록의 보존, 맞춤화 서비스 제안,
          고충처리, 신규 서비스 안내 및 각종 이벤트, 회사 및 제휴사 상품/ 서비스
          안내 및 권유의 목적으로 개인정보를 이용합니다.
          <br />
          <br />
          3. 개인정보 보유 및 이용 기간
          <br />
          회사는 이용자가 회원자격을 유지하고 있는 동안 수집된 개인정보를 보유,
          이용할 수 있으며 이용자가 회원탈퇴를 할 경우에는 수집된 회원정보를
          삭제 및 파기합니다.
          <br />
          단, 회사는 관련 법령의 규정에 의하여 보존해야 하는 기록은 보존기간
          이후 파기합니다.
          <br />
          1) 계약 또는 청약철회 등에 관한 기록: 5년/ 전자상거래 등에서의
          소비자보호에 관한 법률
          <br />
          2) 대금결제 및 재화 등의 공급에 관한 기록: 5년/ 전자상거래 등에서의
          소비자보호에 관한 법률
          <br />
          3) 소비자의 불만 또는 분쟁처리에 관한 기록: 3년/ 전자상거래 등에서의
          소비자보호에 관한 법률
          <br />
          4) 표시/ 광고에 관한 기록: 6개월/ 전자상거래 등에서의 소비자보호에
          관한 법률
          <br />
          5) 홈페이지 방문 등 서비스 접근에 관한 기록: 3개월/ 통신비밀보호법
          <br />
          6) 전자금융 거래에 관한 기록: 5년/ 전자금융거래법
          <br />
          7) 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류: 5년/ 국세기본법
          <br />
          <br />
          4. 개인정보 3자 제공
          <br />
          회사는 이용자의 개인정보를 제3자에게 제공하는 경우 사전에 동의를 얻은
          범위 내에서 사용하며, 이용자가 동의하지 않는 경우 제공하지 않습니다.
          다만 이용자가 해당 제휴서비스를 이용함에 있어 개인정보의 제3자 제공을
          하지 않으면 제휴서비스를 이용할 수 없거나 제휴서비스 혜택을 적용 받을
          수 없는 경우에는 취지 및 동의 거부에 따른 불이익에 대하여 안내합니다.
          <br />
          다음의 경우에는 이용자의 동의 없이 제3자에게 개인정보를 제공할 수
          있습니다.
          <br />
          1) 서비스 제공에 따른 요금정산을 위하여 필요한 경우
          <br />
          2) 서비스 제공에 관한 계약의 이행을 위하여 필요한 개인정보로 경제적/
          기술적인 사유로 통상의 동의를 받는 것이 곤란한 경우
          <br />
          3) 법령의 규정에 의한 경우
          <br />
          4) 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가
          있는 경우
          <br />
          <br />
          5. 개인정보처리의 위탁
          <br />
          회사는 원활한 서비스 운영을 위하여 다음과 같이 개인정보를 위탁하고
          있으며, 관계 법령에 따라 계약 시 개인정보가 안전하게 관리될 수 있도록
          필요한 사항을 규정하고 있습니다.
          <br />
          1) 서비스 결제를 위한 수탁자: 없음
          <br />
          2) 본인확인을 위한 수탁자: 없음
          <br />
          <br />
          6. 이용자의 권리 및 행사방법
          <br />
          이용자(만 14세 미만인 경우에는 법정 대리인)는 회사에 대해 언제든지
          개인정보 열람 요구/ 수정할 수 있으며 수집/ 이용에 대한 동의 철회 또는
          가입 해지를 요청할 수 있습니다.
          <br />
          서비스 내 ‘회원 탈퇴’ 및 고객센터를 통해 서면, 전화 또는 이메일 등으로
          개인정보 동의 철회가 가능하며 고객센터를 통해 개인정보 수정을 요청하는
          경우 회사는 수정이 완료될 때까지 이용자의 개인정보를 이용하거나
          제공하지 않습니다.
          <br />
          <br />
          7. 개인정보의 파기
          <br />
          회사는 개인정보 보유기간의 경과, 이용목적이 달성된 후에는 보관기간 및
          이용기간에 따라 해당 정보를 지체없이 파기합니다.
          <br />
          내부 방침 및 관계 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는
          해당 개인정보를 별도의 DB로 옮기거나 보관장소를 달리하여 관리하며
          관계법령에 따라 일정 기간 저장 후 파기됩니다.
          <br />
          출력물 형태의 개인정보는 분쇄로 파기하고, 전자파일 형태의 개인정보는
          기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
          <br />
          <br />
          8. 개인정보의 안정성 확보
          <br />
          회사는 개인정보의 안정성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
          <br />
          1) 관련 법률규정 또는 내부정책에 따른 개인정보 암호화
          <br />
          2) 개인정보 훼손에 대비한 주기적인 자료 백업, 최신 보안패치와 방화벽을
          통한 해킹 등에 대한 대비
          <br />
          3) 취급 직원의 최소화 및 교육을 통한 개인정보취급방침의 준수
          <br />
          <br />
          9. 개인정보 자동수집 장치의 설치/ 운영 및 거부에 관한 사항
          <br />
          회사는 개인 맞춤 서비스 제안을 위해 이용자의 정보를 저장하고 수시로
          불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 이용자의 웹사이트 방문
          기록, 관심 분야 등을 토대로 이용자의 웹사이트 접속과 편리한 서비스
          검색을 돕습니다.
          <br />
          이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저의 옵션을
          설정함으로 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 하거나,
          모든 쿠키의 저장을 거부할 수 있습니다.
          <br />
          <br />
          10. 개인정보관리 책임자
          <br />
          회사는 이용자의 개인정보 보호를 위해 최선의 노력을 다하겠습니다.
          회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
          불만처리, 피해구제 등에 관한 사항을 개인정보관리 책임자에게 문의하실
          수 있습니다. 회사는 지체없이 답변 및 처리해드리겠습니다.
          <br />
          성명: 조성주
          <br />
          연락처: 02-6964-6479
          <br />
          <br />
          개인정보침해에 대한 신고나 상담은 다음 기관에 문의하시기 바랍니다.
          <br />
          1) 개인정보 침해신고센터: (국번없이) 118 (https://privacy.kisa.or.kr)
          <br />
          - 개인정보 침해 사실 신고, 개인정보 분쟁조정신청, 집단분쟁조정, 상담
          신청
          <br />
          2) 대검찰청 사이버수사과: 02-3280-3570 (https://www.spo.go.kr)
          <br />
          3) 경찰청 사이버안적국: (국번없이) 182
          (http://cyberbureau.police.go.kr)
          <br />
          <br />
          11. 기타
          <br />
          회사의 서비스페이지를 통하여 회사는 이용자에게 다른 회사의 웹사이트
          또는 자료에 대한 링크를 제공할 수 있습니다. 링크를 클릭하여 타
          사이트로 옮겨갈 경우 해당 사이트의 개인정보처리방침을 확인하시기
          바랍니다. 이와 관련하여 회사의 개인정보취급방침이 적용되지 않습니다.
          <br />
          <br />
          (시행일) 본 개인정보취급방침은 2019년 12월 1일부터 시행합니다.
        </div>
      </div>
    </div>
  );
}
export default Privacy;
