import React, { useState, useEffect } from "react"; //, useRef
//import {bindActionCreators} from "redux";
import { useDispatch } from "react-redux"; //connect,
import * as baseActions from "../../store/base";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

function Join(props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    setValue,
  } = useForm();
  const [phoneCheckYn, setPhoneCheckYn] = useState(false);
  const [phoneCheckToken, setPhoneCheckToken] = useState(null);
  const [phoneCheckError, setPhoneCheckError] = useState(null);
  const dispatch = useDispatch();
  const isLocal = props.location.state === undefined;
  const [socialUser] = useState({
    //, setSocialUser
    isSocialUser: isLocal ? false : props.location.state.isSocialUser,
    name: isLocal ? "" : props.location.state.name,
    email: isLocal ? "" : props.location.state.email,
    socialId: isLocal ? "" : props.location.state.socialId,
    provider: isLocal ? "local" : props.location.state.provider,
  });
  //const history = useHistory();

  useEffect(() => {
    if (socialUser.isSocialUser) {
      setValue("email", socialUser.email);
      setValue("name", socialUser.name);
      setValue("password", "#socialUser");
      setValue("passwordCheck", "#socialUser");
    }
  });

  const phoneCheck = () => {
    if (!watch("phone")) {
      setError("phone", {
        type: "required",
        message: "휴대폰번호를 입력해주세요",
      });
      return 0;
    }
    axios
      .post("/rest-api/auth/CPauthRequest", {
        phone: watch("phone"),
      })
      .then((response) => {
        if (response.status === 200) {
          setPhoneCheckToken(response.data.hashToken);
          setError("phone", {
            type: "required",
            message: null,
          });
        } else {
          alert(response.data.error);
        }
      });
  };
  const phoneNumberCheck = () => {
    axios
      .post("/rest-api/auth/CPauthVerify", {
        code: watch("phone_check"),
        hashToken: phoneCheckToken,
      })
      .then(function (response) {
        if (response.data.result === "일치") {
          alert("인증되었습니다");
          setPhoneCheckToken(null);
          setPhoneCheckYn(true);
          setPhoneCheckError(null);
        } else {
          setPhoneCheckError(response.data.error);
        }
      });
  };
  const joinSubmit = (data) => {
    //console.log("click");
    if (!phoneCheckYn) {
      alert(
        "핸드폰인증이 안되어 있습니다. 인증을 했는데 계속 이 메시지가 보이면, 고객센터에 문의해주세요."
      );
      return 0;
    }
    let password = "";
    if (data.password === undefined && !isLocal) password = "#socialUser";
    else if (data.password === undefined) {
      alert(
        "비밀번호 입력창을 다시 확인해주세요. 문제가 지속되면 관리자에게 문의바랍니다."
      );
      return 0;
    } else if (isLocal) password = watch("password");
    axios
      .post("/rest-api/auth/join", {
        email: data.email,
        password,
        name: data.name,
        phone: data.phone,
        provider: isLocal ? "local" : socialUser.provider,
        socialId: isLocal ? "" : socialUser.socialId,
      })
      .then(function (response) {
        if (response.status === 200) {
          alert("회원가입이 완료되었습니다");
          dispatch(baseActions.login(response.data.token));
          window.location.href = "/";
        } else if (response.status === 202) {
          alert(response.data.error);
        }
      });
  };
  return (
    <div className="login_page">
      <div className="login_section">
        <div className="logo_section">
          <a href={"/"}>
            <img src="/img/logo.svg" alt="logo.svg" />
          </a>
        </div>
        <div className="join_title">회원가입</div>
        <div className="lg_input_section join_input_section">
          <form onSubmit={handleSubmit(joinSubmit)}>
            <div className="input_item">
              <input
                type="text"
                placeholder="이메일"
                name={"email"}
                {...register("email", {
                  required: "이메일을 입력해주세요",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "이메일형식으로 입력해주세요",
                  },
                })}
              />
              {errors?.email && (
                <div className={"error_msg"}>{errors?.email?.message}</div>
              )}
            </div>
            {isLocal === true && (
              <div className="input_item">
                <input
                  type="password"
                  name={"password"}
                  placeholder={"비밀번호"}
                  {...register("password", {
                    required: "비밀번호를 입력해주세요",
                    pattern: {
                      value: /(?=.*\d{1,50})(?=.*[~`!@#$%\\^&*()-+=]{0,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/,
                      message:
                        "비밀번호는 8자이상(숫자,문자포함) 입력하셔야합니다",
                    },
                  })}
                />
                {errors?.password && (
                  <div className={"error_msg"}>{errors?.password?.message}</div>
                )}
              </div>
            )}
            {isLocal === true && (
              <div className="input_item">
                <input
                  type="password"
                  name={"passwordCheck"}
                  placeholder="비밀번호 확인"
                  {...register("passwordCheck", {
                    validate: (value) =>
                      value === watch("password") ||
                      "비밀번호가 일치하지 않습니다",
                  })}
                />
                {errors?.passwordCheck && (
                  <div className={"error_msg"}>
                    {errors?.passwordCheck?.message}
                  </div>
                )}
              </div>
            )}
            <div className="input_item">
              <input
                type="text"
                name={"name"}
                placeholder="이름"
                maxLength={16}
                {...register("name", {
                  required: "이름을 입력해주세요",
                })}
              />
              {errors?.name && (
                <div className={"error_msg"}>{errors?.name?.message}</div>
              )}
            </div>
            <div className="input_item phone_item">
              <div>
                <input
                  type="tel"
                  name={"phone"}
                  placeholder="휴대폰번호"
                  {...register("phone", {
                    required: "휴대폰번호를 입력해주세요",
                    pattern: {
                      value: /^[0-9]{11}$/,
                      message: "숫자 11자리로 입력해주세요",
                    },
                  })}
                />
                <input
                  type={"button"}
                  className={
                    "default_bt phone_check_bt" +
                    (phoneCheckYn ? " disable" : "")
                  }
                  onClick={phoneCheck}
                  value={
                    phoneCheckYn
                      ? "인증완료"
                      : phoneCheckToken
                      ? "다시요청"
                      : "인증요청"
                  }
                />
              </div>
              {errors?.phone && (
                <div className={"error_msg"}>{errors?.phone?.message}</div>
              )}
              {phoneCheckToken && (
                <div>
                  <input
                    type="tel"
                    name={"phone_check"}
                    placeholder="인증번호"
                    {...register("phone_check")}
                  />
                  <input
                    type={"button"}
                    className="default_bt phone_check_bt"
                    onClick={(e) => {
                      phoneNumberCheck();
                      e.stopPropagation();
                    }}
                    value={"인증확인"}
                  />
                </div>
              )}
              {phoneCheckError && (
                <div className={"error_msg"}>{phoneCheckError}</div>
              )}
            </div>
            <div className="input_item">
              <button className="default_bt join_bt" type={"submit"}>
                회원가입
              </button>
            </div>
            <div className="join_input_desc">
              회원가입 버튼을 클릭하면, 와이낫링크{" "}
              <Link to={"/terms"}>이용약관</Link>에 동의하며 쿠키 사용을 포함한{" "}
              <Link to={"/privacy"}>개인정보취급방침</Link>을 읽었음을 인정하게
              됩니다.
            </div>
          </form>
        </div>
        <div className="join_bottom_section">
          이미 회원가입이 되어있으신가요? <Link to={"/login"}>로그인하기</Link>
        </div>
      </div>
    </div>
  );
}
export default Join;
