import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";

function Main() {
  //props
  const [recruits, setRecruits] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isv2, setIsv2] = useState(false); // 리뷰 미리보기 온/오프
  const token = localStorage.getItem("token");
  const history = useHistory();
  const agent = navigator.userAgent.toLowerCase();
  const dueHours = 21;

  const setSampleReviews = () => {
    // 테스트용
    const sCate = ["디자인", "인쇄제작", "실사제작", "기타제작", "기타"];
    let sampleReview = [];
    for (let i = 1; i <= 6; i++) {
      const obj = {
        id: i,
        user: { name: `user${i}` },
        projectId: i,
        categories: [sCate[i % 5]],
        contents: `샘플 후기입니다. 프로젝트 ${i}번 수행 잘하였습니다. 감사합니다.`,
      };
      sampleReview.push(obj);
    }
    //console.log("sample reviews: ", sampleReview);
    return sampleReview;
  };
  const recruitRegiGo = () => {
    if (!token) {
      alert("로그인이 필요합니다");
      history.push({
        pathname: "/login",
      });
    } else {
      history.push({
        pathname: "/recruit/register",
      });
    }
  };
  const scrollMove = function () {
    const decoImg = document.querySelector(".intro1 .deco_img img");
    const customStyle = `filter:grayscale(${Math.floor(
      100 - window.pageYOffset / 8
    )}%);opacity:${(window.pageYOffset / 10 + 50) / 100};`;
    //console.log(decoImg);
    if (decoImg !== null) decoImg.style = customStyle;
    // "filter:grayscale(" +
    // Math.floor(100 - window.pageYOffset / 8) +
    // "%);" +
    // "opacity:" +
    // (window.pageYOffset / 10 + 50) / 100;
  };
  useEffect(() => {
    axios
      .get("/rest-api/getProjects", {
        params: {
          amount: 6,
          status: ["recruit", "processing", "end"],
        },
      })
      .then(function (result) {
        setRecruits(result.data.projects);
        axios
          .get("/rest-api/getRecentReviews", {
            params: {
              amount: 6,
            },
          })
          .then(function (resReview) {
            // console.log(resReview.data);
            if (resReview.status === 200) setReviews(resReview.data.reviews);
          });
        //setReviews([...setSampleReviews()]);
      });

    if (
      (navigator.appName === "Netscape" &&
        navigator.userAgent.search("Trident") !== -1) ||
      agent.indexOf("msie") !== -1
    ) {
      //익스플로러감지
    } else {
      const decoImg = document.querySelector(".intro1 .deco_img img");
      decoImg.style = "filter:grayscale(100%);opacity:0.5";
      window.addEventListener("scroll", scrollMove);
      return () => {
        window.removeEventListener("scroll", scrollMove);
      };
    }
  }, [agent]);

  return (
    <div className="contents_wrap intro_contents">
      <div className="intro intro1">
        <div className="main_title">
          모든 인쇄물제작
          <br />
          인쇄, 후가공 찾지말고
          <br />
          와이낫링크에서 한번에
        </div>
        <div className="sub_title">
          쉽고 빠르게 해결하는 인쇄플랫폼
          {/* <br />충무로, 을지로 인쇄업체 등 <br />
          1,000여 개 상생파트너와 함께 합니다. */}
        </div>
        <div className="deco_img">
          <img src="/img/intro1.jpg" alt="intro1.jpg" />
        </div>
      </div>
      {/* <div className="intro intro2">
        <div className="section_title">service process</div>
        <div className="section_desc">
          와이낫링크는 판촉, 인쇄, 홍보물에 대한 구인을 한번에 해결해주는
          솔루션입니다.
        </div>
        <div className="service_process">
          <ul>
            <li>
              <div className="sp_title">1. 프로젝트 의뢰</div>
              <div className="sp_desc">
                기획, 디자인, 제작/ 시공에 이르기까지 필요한 업무범위+예산+일정
                입력으로 다양한 프로젝트를 쉽게 의뢰할 수 있습니다. 작성이
                어려울 경우 원하는 결과물을 작성만하면 적정 예산 및 일정, 기타
                이슈 사항 등은 와이낫링크 전담팀이 직접 검토하여 수정의견을
                드립니다.
              </div>
            </li>
            <li>
              <div className="sp_title">2. 프로젝트 수행사 선정</div>
              <div className="sp_desc">
                수행 희망 업체의 견적서 및 진행사례를 통해 전문성을 확인, 최적의
                전문가를 선정하실 수 있읍니다. 인쇄소, 후가공 업체 등으로 구성된
                와이낫링크의 제안서도 함께 검토해 주세요. 매칭이 되지 않은
                업무는 협의 후 와이낫링크 전담팀이 직접 해결해드립니다.
              </div>
            </li>
            <li>
              <div className="sp_title">3. 프로젝트 진행</div>
              <div className="sp_desc">
                협의 없이 전문가가 임의로 프로젝트 내용을 변경할 수 없습니다.
                프로젝트가 종료 될 때까지 전문가가 업무에 집중할 수 있도록
                와이낫링크에서 안전하게 대금을 보호하며, 기타 이슈사항은
                와이낫링크 매니저를 통해 쉽게 해결할 수 있습니다.
              </div>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="intro intro3">
        <div className="section_title">Recent project</div>
        <div className="recent_items">
          {recruits.map((rc, index) => {
            const now = moment().tz("Asia/Seoul");
            const applyDueDate = moment(rc.applyDueDate)
              .tz("Asia/Seoul")
              .add(dueHours, "hours");
            const dueDate = moment(rc.dueDate)
              .tz("Asia/Seoul")
              .add(dueHours, "hours");
            return (
              <div
                className="project_item"
                key={index}
                // onClick={() => {
                //    history.push("/recruit/detail/" + rc.id);
                //   // if (token)else if (
                //   //   window.confirm(
                //   //     "로그인이 필요한 메뉴입니다. 로그인 페이지로 이동하시겠습니까?"
                //   //   )
                //   // )
                //   //   history.push("/login");
                // }}
              >
                <Link to={"/recruit/detail/" + rc.id}>
                  <div className="pr_title">{rc.title}</div>
                  <div className="pr_category">
                    <ul>
                      {rc.mainCategory.map((cate_item, index) => {
                        return <li key={index}>{cate_item}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="pr_desc">
                    {rc.content &&
                      (rc.content.length > 134
                        ? rc.content.substr(0, 75) + "..."
                        : rc.content)}
                  </div>
                  <div className="pr_options">
                    <div className="pr_option">
                      프로젝트금액{" "}
                      {rc.cost !== "제안금액" ? rc.cost + "만원" : "제안금액"}
                    </div>
                    <div className="pr_option">
                      {rc.status}
                      {applyDueDate.diff(now, "minutes") > 0 && (
                        <span>
                          (
                          <Moment
                            diff={moment().tz("Asia/Seoul").format()}
                            unit={"days"}
                            tz="Asia/Seoul"
                          >
                            {applyDueDate}
                          </Moment>
                          일 남음)
                        </span>
                      )}
                    </div>
                    <div className="pr_option mobile_hidden">
                      납기일 <Moment format="MM/DD">{rc.dueDate}</Moment>
                      {dueDate.diff(now, "minutes") > 0 && (
                        <span>
                          (
                          <Moment
                            diff={new Date()}
                            unit={"days"}
                            tz="Asia/Seoul"
                          >
                            {dueDate}
                          </Moment>
                          일 남음)
                        </span>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {isv2 && (
        <div className="intro intro4">
          <div className="section_title">Recent reviews</div>
          <div className="review_items">
            {reviews.length === 0 && (
              <div className={"no_review_contents"}>최근 리뷰가 없습니다.</div>
            )}
            {reviews.length > 0 &&
              reviews.map((rev, index) => {
                const stars = [];
                for (let i = 1; i <= 5; i++) stars.push(i <= rev.rank);
                return (
                  <div
                    className="review_item"
                    key={index}
                    onClick={() => {
                      if (token)
                        history.push(`/recruit/detail/${rev.project.id}`);
                      else if (
                        window.confirm(
                          "로그인이 필요한 메뉴입니다. 로그인 페이지로 이동하시겠습니까?"
                        )
                      )
                        history.push("/login");
                    }}
                  >
                    <div className="rv_writer">
                      {rev.project.user.name.slice(0, 2) + "***"}
                    </div>
                    <div className="rv_category">
                      <ul>
                        {rev.project.category
                          .split(",")
                          .map((cate_item, index) => {
                            return <li key={index}>{cate_item}</li>;
                          })}
                      </ul>
                    </div>
                    <div className="rv_star_items">
                      {stars.map((item) =>
                        item ? (
                          <span className="star_item">
                            <img
                              src="/img/ic_star_active.svg"
                              alt="ic_star_active.svg"
                            />
                          </span>
                        ) : (
                          <span className="star_item">
                            <img src="/img/ic_star.svg" alt="ic_star.svg" />
                          </span>
                        )
                      )}
                    </div>
                    <div className="rv_contents">
                      {rev.contents &&
                        (rev.contents.length > 134
                          ? rev.contents.substr(0, 75) + "..."
                          : rev.contents)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className="project_add_section">
        <div className="main_title">
          소재 구분 없이 모든 인쇄 제작물,
          <br />
          지금 바로 와이낫링크를 통해 해결하세요.
        </div>
        <div className="pr_add_go_bt" onClick={recruitRegiGo}>
          프로젝트 등록하러가기
        </div>
        <div className="pr_add_go_desc">
          등록된 내용은 프로젝트 진행을 위한 용도 외 어떠한 다른 목적으로
          활용되지 않습니다.
        </div>
      </div>
    </div>
  );
}
export default Main;
